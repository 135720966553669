import { useMemo } from "react";
import { DropdownFilled16Icon, Search16Icon } from "@/consts/icons-v2";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import styles from "./styles.module.scss";

type TypeOption = {
  value: string | number;
  text: string | number;
};

interface ISearchByQueryTypeProps {
  query: string;
  type: TypeOption["value"] | null;
  typeOptions: TypeOption[];
  onQueryChange: (value: string) => void;
  onTypeChange: (value: TypeOption["value"]) => void;
}

const SearchByQueryType: React.FC<ISearchByQueryTypeProps> = ({
  query,
  type,
  typeOptions,
  onQueryChange,
  onTypeChange,
}) => {
  const typeText = useMemo(() => {
    const option = typeOptions.find((it) => it.value === type);
    return option?.text ?? "Фильтр";
  }, [type, typeOptions]);

  function handleQueryChange(event: React.SyntheticEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (value !== query) {
      onQueryChange(value);
    }
  }

  function handleQueryTypeChange(value: TypeOption["value"]) {
    if (value !== type) {
      onTypeChange(value);
    }
  }

  return (
    <div className={styles["b-search-by-type"]}>
      <div>
        <BaseDropdown
          value={type}
          customToggler={
            <div className={styles["b-search-by-type__select"]}>
              <span className="text--primary color--text-primary sf-text-regular">{typeText}</span>
              {DropdownFilled16Icon()}
            </div>
          }
          isSelectable={true}
          onSelect={handleQueryTypeChange}
        >
          {typeOptions.map((it) => (
            <BaseDropdownMenuItem value={it.value} key={it.value}>
              <span className="sf-text-regular">{it.text}</span>
            </BaseDropdownMenuItem>
          ))}
        </BaseDropdown>
      </div>

      <span></span>

      <div className={styles["b-search-by-type__query"]}>
        <span className={styles["b-search-by-type__query_icon"]}>{Search16Icon()}</span>
        <input
          className="text--primary color--text-primary sf-text-regular"
          type="text"
          value={query}
          onChange={handleQueryChange}
          placeholder="Поиск"
        />
      </div>
    </div>
  );
};

export default SearchByQueryType;
