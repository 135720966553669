import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SntPassportBoard, SntPassportBoardForms, SntPassportEnums } from "@/types";
import { SntPassportService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { SnackbarType, showSnackbar } from "@/app/store/slices/snackbar";
import { activeAddress } from "@/app/store/slices/user";
import { getSntDetails } from "@/app/store/slices/snt/registry/actions";
import { fetchSntPassportDictionaries } from "@/app/store/slices/snt/passport";
import SntPassportContent from "@/components/SntPassport/Content";
import SntPassportBlockWrapper from "@/components/SntPassport/BlockWrapper";
import SntPassportBlockContentForm from "@/components/SntPassport/BlockContent/Form";
import SntPassportBottomBar from "@/components/SntPassport/BottomBar";
import BaseInput from "@/components/base-input";
import PhoneInput from "@/components/base-input/phoneInput";
import NumberInput from "@/components/base-input/NumberInput";
import MaskedInput from "@/components/base-input/MaskedInput";
import DatepickerInput from "@/components/base-input/datepicker";
import MultipleSelect, { MultipleSelectMenuItem } from "@/components/MultipleSelect";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import styles from "./styles.module.scss";

const SntPassportBoardPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const company = useSelector((state: RootState) => state.user.company);
  const address = useSelector(activeAddress);
  const { associationsList, electricitySupplyTypesList, waterSupplyTypesList, roadSurfaceTypesList } = useSelector(
    (state: RootState) => state.sntPassport
  );

  const [boardData, setBoardData] = useState<SntPassportBoard.BoardDataResponse>();
  const [detailsForm, setDetailsForm] = useState<SntPassportBoardForms.DetailsForm>(
    new SntPassportBoardForms.DetailsForm()
  );
  const [quantitativeDataForm, setQuantitativeDataForm] = useState<SntPassportBoardForms.QuantitativeDataForm>(
    new SntPassportBoardForms.QuantitativeDataForm()
  );
  const [infrastructureForm, setInfrastructureForm] = useState<SntPassportBoardForms.InfrastructureForm>(
    new SntPassportBoardForms.InfrastructureForm()
  );
  const [isSaveLoading, toggleSaveLoading] = useState<boolean>(false);
  const [isPublishLoading, togglePublishLoading] = useState<boolean>(false);

  const formOccupancy: number = useMemo(() => {
    let chunk = 0;

    if (boardData?.Form) {
      if (SntPassportBoardForms.isAllRequiredDetailsFilled(boardData?.Form.Details)) {
        chunk++;
      }
      if (
        boardData?.Form.QuantitativeData &&
        SntPassportBoardForms.isAllRequiredQuantitativeDataFilled(boardData?.Form.QuantitativeData)
      ) {
        chunk++;
      }
      if (
        boardData?.Form.Infrastructure &&
        SntPassportBoardForms.isAllRequiredInfrastructureFilled(boardData?.Form.Infrastructure)
      ) {
        chunk++;
      }
    }

    return chunk;
  }, [boardData?.Form]);

  function onValidationError() {
    dispatch(
      showSnackbar({
        key: "snt-passport-error",
        title: "Ошибка",
        body: "Некорректный формат полей или не заполнены обязательные поля",
        duration: 10000,
        type: SnackbarType.Block,
        closeBtn: true,
      })
    );

    setTimeout(() => {
      const element = document.querySelector("[data-error*=' ']");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 0);
  }

  function updateDetailsFormFieldValue(key: keyof SntPassportBoardForms.DetailsForm, value: unknown) {
    const field = detailsForm[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setDetailsForm((prevState) => ({
        ...prevState,
        [key]: { ...field, value },
      }));
    }
  }

  function updateQuantitativeDataFormFieldValue(key: keyof SntPassportBoardForms.QuantitativeDataForm, value: unknown) {
    const field = quantitativeDataForm[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setQuantitativeDataForm((prevState) => ({
        ...prevState,
        [key]: { ...field, value },
      }));
    }
  }

  function updateElectricitySupplyFieldValue(
    key: keyof SntPassportBoardForms.InfrastructureForm["ElectricitySupply"],
    value: unknown
  ) {
    const field = infrastructureForm.ElectricitySupply[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setInfrastructureForm((prevState) => ({
        ...prevState,
        ElectricitySupply: {
          ...prevState.ElectricitySupply,
          [key]: { ...field, value },
        },
      }));
    }
  }

  function updateWaterSupplyFieldValue(
    key: keyof SntPassportBoardForms.InfrastructureForm["WaterSupply"],
    value: unknown
  ) {
    const field = infrastructureForm.WaterSupply[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setInfrastructureForm((prevState) => ({
        ...prevState,
        WaterSupply: {
          ...prevState.WaterSupply,
          [key]: { ...field, value },
        },
      }));
    }
  }

  function updateGasSupplyFieldValue(key: keyof SntPassportBoardForms.InfrastructureForm["GasSupply"], value: unknown) {
    const field = infrastructureForm.GasSupply[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setInfrastructureForm((prevState) => ({
        ...prevState,
        GasSupply: {
          ...prevState.GasSupply,
          [key]: { ...field, value },
        },
      }));
    }
  }

  function updateTKOFieldValue(key: keyof SntPassportBoardForms.InfrastructureForm["TKO"], value: unknown) {
    const field = infrastructureForm.TKO[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setInfrastructureForm((prevState) => ({
        ...prevState,
        TKO: {
          ...prevState.TKO,
          [key]: { ...field, value },
        },
      }));
    }
  }

  function updateSectionOuterRoadFieldValue(
    key: keyof SntPassportBoardForms.InfrastructureForm["SectionOuterRoad"],
    value: unknown
  ) {
    const field = infrastructureForm.SectionOuterRoad[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setInfrastructureForm((prevState) => ({
        ...prevState,
        SectionOuterRoad: {
          ...prevState.SectionOuterRoad,
          [key]: { ...field, value },
        },
      }));
    }
  }

  function updateSectionInnerRoadFieldValue(
    key: keyof SntPassportBoardForms.InfrastructureForm["SectionInnerRoad"],
    value: unknown
  ) {
    const field = infrastructureForm.SectionInnerRoad[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setInfrastructureForm((prevState) => ({
        ...prevState,
        SectionInnerRoad: {
          ...prevState.SectionInnerRoad,
          [key]: { ...field, value },
        },
      }));
    }
  }

  function updateAdditionalFieldValue(
    key: keyof SntPassportBoardForms.InfrastructureForm["Additional"],
    value: unknown
  ) {
    const field = infrastructureForm.Additional[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setInfrastructureForm((prevState) => ({
        ...prevState,
        Additional: {
          ...prevState.Additional,
          [key]: { ...field, value },
        },
      }));
    }
  }

  function handleError(payload: any) {
    if (payload.Errors) {
      Object.keys(payload.Errors).forEach((key: any) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        const [base, form, field, subfield] = key.split(".");
        switch (form) {
          case "Details": {
            const formfield = detailsForm[field as keyof SntPassportBoardForms.DetailsForm];
            if (
              (typeof formfield === "object" || typeof formfield === "function") &&
              formfield !== null &&
              "error" in formfield
            ) {
              setDetailsForm((prevState) => ({
                ...prevState,
                [field]: { ...formfield, error: message },
              }));
            }
            break;
          }

          case "QuantitativeData": {
            const formfield = quantitativeDataForm[field as keyof SntPassportBoardForms.QuantitativeDataForm];
            if (
              (typeof formfield === "object" || typeof formfield === "function") &&
              formfield !== null &&
              "error" in formfield
            ) {
              setQuantitativeDataForm((prevState) => ({
                ...prevState,
                [field]: { ...formfield, error: message },
              }));
            }
            break;
          }

          case "Infrastructure": {
            const group = infrastructureForm[field as keyof SntPassportBoardForms.InfrastructureForm];
            if (Object.keys(group).includes(subfield)) {
              const formfield = (group as any)[subfield];
              if (
                (typeof formfield === "object" || typeof formfield === "function") &&
                formfield !== null &&
                "error" in formfield
              ) {
                setInfrastructureForm((prevState) => ({
                  ...prevState,
                  [field]: {
                    ...(prevState as any)[field],
                    [subfield]: { ...formfield, error: message },
                  },
                }));
              }
            }
            break;
          }

          default: {
            dispatch(showSnackbar({ key: "snt-passport-error", body: message, type: SnackbarType.ERROR }));
            break;
          }
        }
      });

      onValidationError();
    } else {
      dispatch(showSnackbar({ key: "snt-passport-error", body: payload.Message, type: SnackbarType.ERROR }));
    }
  }

  function isDetailsFormValid(needCheckRequired: boolean) {
    const validator = SntPassportBoardForms.DetailsFormValidator.isInvalid(detailsForm, needCheckRequired);

    setDetailsForm((prevState) => ({
      ...prevState,
      FoundationYear: {
        ...prevState.FoundationYear,
        error: typeof validator === "object" ? validator.FoundationYear ?? "" : "",
      },
      RegistrationDate: {
        ...prevState.RegistrationDate,
        error: typeof validator === "object" ? validator.RegistrationDate ?? "" : "",
      },
      Association: {
        ...prevState.Association,
        error: typeof validator === "object" ? validator.Association ?? "" : "",
      },
      AssociationMembership: {
        ...prevState.AssociationMembership,
        error: typeof validator === "object" ? validator.AssociationMembership ?? "" : "",
      },
      CadastralNumber: {
        ...prevState.CadastralNumber,
        error: typeof validator === "object" ? validator.CadastralNumber ?? "" : "",
      },
      ChairmanPhone: {
        ...prevState.ChairmanPhone,
        error: typeof validator === "object" ? validator.ChairmanPhone ?? "" : "",
      },
      ChairmanEmail: {
        ...prevState.ChairmanEmail,
        error: typeof validator === "object" ? validator.ChairmanEmail ?? "" : "",
      },
    }));

    return !validator;
  }

  function isQuantitativeDataFormValid(needCheckRequired: boolean) {
    const validator = SntPassportBoardForms.QuantitativeDataFormValidator.isInvalid(
      quantitativeDataForm,
      needCheckRequired
    );

    setQuantitativeDataForm((prevState) => ({
      ...prevState,
      TotalArea: {
        ...prevState.TotalArea,
        error: typeof validator === "object" ? validator.TotalArea ?? "" ?? "" : "",
      },
      PublicArea: {
        ...prevState.PublicArea,
        error: typeof validator === "object" ? validator.PublicArea ?? "" : "",
      },
      SectionsArea: {
        ...prevState.SectionsArea,
        error: typeof validator === "object" ? validator.SectionsArea ?? "" : "",
      },
      TotalSectionsCount: {
        ...prevState.TotalSectionsCount,
        error: typeof validator === "object" ? validator.TotalSectionsCount ?? "" : "",
      },
      UsedSectionsCount: {
        ...prevState.UsedSectionsCount,
        error: typeof validator === "object" ? validator.UsedSectionsCount ?? "" : "",
      },
      UnUsedSectionsCount: {
        ...prevState.UnUsedSectionsCount,
        error: typeof validator === "object" ? validator.UnUsedSectionsCount ?? "" : "",
      },
      RegisteredSectionsCount: {
        ...prevState.RegisteredSectionsCount,
        error: typeof validator === "object" ? validator.RegisteredSectionsCount ?? "" : "",
      },
      ResidentialBuildingsCount: {
        ...prevState.ResidentialBuildingsCount,
        error: typeof validator === "object" ? validator.ResidentialBuildingsCount ?? "" : "",
      },
      RegisteredCitizensCount: {
        ...prevState.RegisteredCitizensCount,
        error: typeof validator === "object" ? validator.RegisteredCitizensCount ?? "" : "",
      },
      ContributionsAggregateAmount: {
        ...prevState.ContributionsAggregateAmount,
        error: typeof validator === "object" ? validator.ContributionsAggregateAmount ?? "" : "",
      },
    }));

    return !validator;
  }

  function isInfrastructureFormValid(needCheckRequired: boolean) {
    const validator = SntPassportBoardForms.InfrastructureFormValidator.isInvalid(
      infrastructureForm,
      needCheckRequired,
      quantitativeDataForm.TotalSectionsCount
    );

    setInfrastructureForm((prevState) => ({
      ...prevState,
      ElectricitySupply: {
        IsAvailable: {
          ...prevState.ElectricitySupply.IsAvailable,
          error: typeof validator === "object" ? validator.ElectricitySupply.IsAvailable ?? "" : "",
        },
        Type: {
          ...prevState.ElectricitySupply.Type,
          error: typeof validator === "object" ? validator.ElectricitySupply.Type ?? "" : "",
        },
        CTS: {
          ...prevState.ElectricitySupply.CTS,
          error: typeof validator === "object" ? validator.ElectricitySupply.CTS ?? "" : "",
        },
        PowerLineLength: {
          ...prevState.ElectricitySupply.PowerLineLength,
          error: typeof validator === "object" ? validator.ElectricitySupply.PowerLineLength ?? "" : "",
        },
      },

      WaterSupply: {
        IsAvailable: {
          ...prevState.WaterSupply.IsAvailable,
          error: typeof validator === "object" ? validator.WaterSupply.IsAvailable ?? "" : "",
        },
        Type: {
          ...prevState.WaterSupply.Type,
          error: typeof validator === "object" ? validator.WaterSupply.Type ?? "" : "",
        },
        License: {
          ...prevState.WaterSupply.License,
          error: typeof validator === "object" ? validator.WaterSupply.License ?? "" : "",
        },
        WaterWellCount: {
          ...prevState.WaterSupply.WaterWellCount,
          error: typeof validator === "object" ? validator.WaterSupply.WaterWellCount ?? "" : "",
        },
        SeasonalFireWaterSupply: {
          ...prevState.WaterSupply.SeasonalFireWaterSupply,
          error: typeof validator === "object" ? validator.WaterSupply.SeasonalFireWaterSupply ?? "" : "",
        },
        YearRoundFireWaterSupply: {
          ...prevState.WaterSupply.YearRoundFireWaterSupply,
          error: typeof validator === "object" ? validator.WaterSupply.YearRoundFireWaterSupply ?? "" : "",
        },
      },

      GasSupply: {
        IsAvailable: {
          ...prevState.GasSupply.IsAvailable,
          error: typeof validator === "object" ? validator.GasSupply.IsAvailable ?? "" : "",
        },
        CommissioningYear: {
          ...prevState.GasSupply.CommissioningYear,
          error: typeof validator === "object" ? validator.GasSupply.CommissioningYear ?? "" : "",
        },
        ConnectedBuildingsCount: {
          ...prevState.GasSupply.ConnectedBuildingsCount,
          error: typeof validator === "object" ? validator.GasSupply.ConnectedBuildingsCount ?? "" : "",
        },
      },

      TKO: {
        WasteCollectionSitesCount: {
          ...prevState.TKO.WasteCollectionSitesCount,
          error: typeof validator === "object" ? validator.TKO.WasteCollectionSitesCount ?? "" : "",
        },
        HoppersCount: {
          ...prevState.TKO.HoppersCount,
          error: typeof validator === "object" ? validator.TKO.HoppersCount ?? "" : "",
        },
        ContainersCount: {
          ...prevState.TKO.ContainersCount,
          error: typeof validator === "object" ? validator.TKO.ContainersCount ?? "" : "",
        },
      },

      SectionOuterRoad: {
        CommissioningYear: {
          ...prevState.SectionOuterRoad.CommissioningYear,
          error: typeof validator === "object" ? validator.SectionOuterRoad.CommissioningYear ?? "" : "",
        },
        SurfaceWorn: {
          ...prevState.SectionOuterRoad.SurfaceWorn,
          error: typeof validator === "object" ? validator.SectionOuterRoad.SurfaceWorn ?? "" : "",
        },
        PublicTransportStopsDistance: {
          ...prevState.SectionOuterRoad.PublicTransportStopsDistance,
          error: typeof validator === "object" ? validator.SectionOuterRoad.PublicTransportStopsDistance ?? "" : "",
        },
      },

      SectionInnerRoad: {
        Length: {
          ...prevState.SectionInnerRoad.Length,
          error: typeof validator === "object" ? validator.SectionInnerRoad.Length ?? "" : "",
        },
        AlleyWidth: {
          ...prevState.SectionInnerRoad.AlleyWidth,
          error: typeof validator === "object" ? validator.SectionInnerRoad.AlleyWidth ?? "" : "",
        },
        SurfaceType: {
          ...prevState.SectionInnerRoad.SurfaceType,
          error: typeof validator === "object" ? validator.SectionInnerRoad.SurfaceType ?? "" : "",
        },
        SurfaceTypeKeyOther: {
          ...prevState.SectionInnerRoad.SurfaceTypeKeyOther,
          error: typeof validator === "object" ? validator.SectionInnerRoad.SurfaceTypeKeyOther ?? "" : "",
        },
      },

      Additional: {
        FiberOptic: {
          ...prevState.Additional.FiberOptic,
          error: typeof validator === "object" ? validator.Additional.FiberOptic ?? "" : "",
        },
        SecurityRoom: {
          ...prevState.Additional.SecurityRoom,
          error: typeof validator === "object" ? validator.Additional.SecurityRoom ?? "" : "",
        },
        AccessControlSystems: {
          ...prevState.Additional.AccessControlSystems,
          error: typeof validator === "object" ? validator.Additional.AccessControlSystems ?? "" : "",
        },
        VideoSurveillanceSystems: {
          ...prevState.Additional.VideoSurveillanceSystems,
          error: typeof validator === "object" ? validator.Additional.VideoSurveillanceSystems ?? "" : "",
        },
        Fence: {
          ...prevState.Additional.Fence,
          error: typeof validator === "object" ? validator.Additional.Fence ?? "" : "",
        },
      },
    }));

    return !validator;
  }

  async function saveData() {
    const detailsValidation = isDetailsFormValid(false);
    const quantitativeDataValidation = isQuantitativeDataFormValid(false);
    const infrastructureValidation = isInfrastructureFormValid(false);

    if (!detailsValidation || !quantitativeDataValidation || !infrastructureValidation) {
      onValidationError();
      return;
    }

    toggleSaveLoading(true);
    try {
      const body = SntPassportBoardForms.saveRequestBody(detailsForm, quantitativeDataForm, infrastructureForm);
      await SntPassportService.saveBoardData(body);
      await fetchBoardData();
      dispatch(
        showSnackbar({
          key: "snt-passport-save",
          title: "Успешно сохранено",
          duration: 10000,
          type: SnackbarType.SUCCESS,
          closeBtn: true,
        })
      );
    } catch (error: any) {
      handleError(error?.response?.data?.Data);
    } finally {
      toggleSaveLoading(false);
    }
  }

  async function publishData() {
    const detailsValidation = isDetailsFormValid(true);
    const quantitativeDataValidation = isQuantitativeDataFormValid(true);
    const infrastructureValidation = isInfrastructureFormValid(true);

    if (!detailsValidation || !quantitativeDataValidation || !infrastructureValidation) {
      onValidationError();
      return;
    }

    togglePublishLoading(true);
    try {
      const body = SntPassportBoardForms.saveRequestBody(detailsForm, quantitativeDataForm, infrastructureForm);
      await SntPassportService.saveBoardData(body);
      await SntPassportService.publishBoardData();
      await fetchBoardData();
      dispatch(
        showSnackbar({
          key: "snt-passport-publish",
          title: "Успешно опубликовано",
          duration: 10000,
          type: SnackbarType.SUCCESS,
          closeBtn: true,
        })
      );
    } catch (error: any) {
      handleError(error?.response?.data?.Data);
    } finally {
      togglePublishLoading(false);
    }
  }

  async function fetchBoardData() {
    if (!address?.Id) {
      return;
    }

    try {
      await dispatch(fetchSntPassportDictionaries()).unwrap();
      const { data } = await SntPassportService.getBoardData(address?.Id);
      setBoardData(data.Data);
      dispatch(getSntDetails()).unwrap();
      return data.Data.Form;
    } catch (error: any) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchBoardData();
  }, []);

  useEffect(() => {
    setDetailsForm(new SntPassportBoardForms.DetailsForm(boardData?.Form.Details, associationsList));
  }, [boardData?.Form.Details, associationsList]);

  useEffect(() => {
    setQuantitativeDataForm(new SntPassportBoardForms.QuantitativeDataForm(boardData?.Form.QuantitativeData));
  }, [boardData?.Form.QuantitativeData]);

  useEffect(() => {
    setInfrastructureForm(
      new SntPassportBoardForms.InfrastructureForm(
        boardData?.Form.Infrastructure,
        electricitySupplyTypesList,
        waterSupplyTypesList,
        roadSurfaceTypesList
      )
    );
  }, [boardData?.Form.Infrastructure, electricitySupplyTypesList, waterSupplyTypesList, roadSurfaceTypesList]);

  return (
    <div style={{ padding: "12px 0 88px" }}>
      <SntPassportContent>
        <div className={styles["p-snt-passport-main__header"]}>
          <h4 className="headline-h4 sf-text-bold color--text-primary">Заполненность паспорта:</h4>
          <div className={styles["b-occupancy-chart"]}>
            <svg width="40" height="40" fill="none">
              <g transform="translate(20, 20)">
                <circle r="18" className={styles["b-occupancy-chart__back"]} />
                <circle
                  r="18"
                  className={styles["b-occupancy-chart__front"]}
                  style={{ strokeDashoffset: 126 - (126 / 3) * formOccupancy }}
                />
              </g>
              <text className="sf-text-semibold" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
                {formOccupancy}/3
              </text>
            </svg>
          </div>
        </div>

        <SntPassportBlockWrapper title={company?.Name ?? ""}>
          <div>
            <SntPassportBlockContentForm>
              <NumberInput
                value={detailsForm.FoundationYear.value}
                label="Год основания"
                required={detailsForm.FoundationYear.isRequired}
                placeholder="Введите год"
                errorMessage={detailsForm.FoundationYear.error}
                max={9999}
                digitsAfterPoint={0}
                onChange={(value) => updateDetailsFormFieldValue("FoundationYear", value)}
              />

              <DatepickerInput
                value={detailsForm.RegistrationDate.value}
                label="Дата государственной регистрации"
                required={detailsForm.RegistrationDate.isRequired}
                errorMessage={detailsForm.RegistrationDate.error}
                onChange={(value) => updateDetailsFormFieldValue("RegistrationDate", value)}
                placeholder="ДД.ММ.ГГГГ"
              />

              <BaseInput
                value={detailsForm.INN.value}
                label="ИНН"
                required={detailsForm.INN.isRequired}
                errorMessage={detailsForm.INN.error}
                maxlength={detailsForm.INN.maxlength}
                locked={true}
              />

              <BaseInput
                value={detailsForm.MunicipalDistrict.value}
                label="Район"
                required={detailsForm.MunicipalDistrict.isRequired}
                errorMessage={detailsForm.MunicipalDistrict.error}
                locked={true}
              />

              <BaseDropdown
                value={detailsForm.AssociationMembership.value}
                display={
                  typeof detailsForm.AssociationMembership.value === "boolean"
                    ? detailsForm.AssociationMembership.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Наличие членства в ассоциации"
                placeholder="Не выбрано"
                variant="formfield"
                required={detailsForm.AssociationMembership.isRequired}
                errorMessage={detailsForm.AssociationMembership.error}
                onSelect={(value) => updateDetailsFormFieldValue("AssociationMembership", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>

              <BaseDropdown
                value={detailsForm.AssociationMembership.value ? detailsForm.Association?.value?.Id : null}
                display={detailsForm.AssociationMembership.value ? detailsForm.Association?.value?.Name : ""}
                isSelectable={true}
                disabled={!detailsForm.AssociationMembership.value}
                transparentOnDisable={true}
                label="Ассоциация"
                placeholder="Не выбрано"
                variant="formfield"
                required={!!detailsForm.AssociationMembership.value}
                errorMessage={detailsForm.Association.error}
                onSelect={(value) => {
                  const association = associationsList.find((it) => it.Id === value);
                  if (association) updateDetailsFormFieldValue("Association", association);
                }}
              >
                {associationsList.map((association) => (
                  <BaseDropdownMenuItem key={association.Id} value={association.Id}>
                    <span className="sf-text-medium">{association.Name}</span>
                  </BaseDropdownMenuItem>
                ))}
              </BaseDropdown>

              <MaskedInput
                mask={/^[0-9;:]+$/}
                value={detailsForm.CadastralNumber.value}
                label="Кадастровый номер земель общего пользования"
                required={detailsForm.CadastralNumber.isRequired}
                placeholder="Введите номер"
                errorMessage={detailsForm.CadastralNumber.error}
                tooltipMessage="Если кадастровых номеров несколько, то укажите их через точку с запятой"
                onChange={(value) => updateDetailsFormFieldValue("CadastralNumber", value)}
              />

              <BaseInput
                value={detailsForm.ChairmanName.value}
                label="ФИО председателя"
                required={detailsForm.ChairmanName.isRequired}
                placeholder="Введите ФИО"
                errorMessage={detailsForm.ChairmanName.error}
                maxlength={detailsForm.ChairmanName.maxlength}
                locked={true}
              />

              <PhoneInput
                value={detailsForm.ChairmanPhone.value}
                label="Контактный телефон председателя"
                required={detailsForm.ChairmanPhone.isRequired}
                placeholder="Введите телефон"
                errorMessage={detailsForm.ChairmanPhone.error}
                onChange={(value) => updateDetailsFormFieldValue("ChairmanPhone", value)}
              />

              <BaseInput
                value={detailsForm.ChairmanEmail.value}
                label="Электронная почта председателя"
                required={detailsForm.ChairmanEmail.isRequired}
                placeholder="Введите электронную почту"
                errorMessage={detailsForm.ChairmanEmail.error}
                maxlength={detailsForm.ChairmanEmail.maxlength}
                onChange={(value) => updateDetailsFormFieldValue("ChairmanEmail", value)}
              />
            </SntPassportBlockContentForm>
          </div>
        </SntPassportBlockWrapper>

        <SntPassportBlockWrapper title="Количественные показатели">
          <div>
            <SntPassportBlockContentForm>
              <NumberInput
                value={quantitativeDataForm.TotalArea.value}
                label="Общая площадь территории садоводства (м2)"
                required={quantitativeDataForm.TotalArea.isRequired}
                placeholder="Укажите"
                errorMessage={quantitativeDataForm.TotalArea.error}
                max={99999999}
                digitsAfterPoint={2}
                onChange={(value) => updateQuantitativeDataFormFieldValue("TotalArea", value)}
              />

              <NumberInput
                value={quantitativeDataForm.PublicArea.value}
                label="Площадь земель общего пользования (м2)"
                required={quantitativeDataForm.PublicArea.isRequired}
                placeholder="Укажите"
                errorMessage={quantitativeDataForm.PublicArea.error}
                max={99999999}
                digitsAfterPoint={2}
                onChange={(value) => updateQuantitativeDataFormFieldValue("PublicArea", value)}
              />

              <NumberInput
                value={quantitativeDataForm.SectionsArea.value}
                label="Площадь участков садоводов (м2)"
                required={quantitativeDataForm.SectionsArea.isRequired}
                placeholder="Укажите"
                errorMessage={quantitativeDataForm.SectionsArea.error}
                max={99999999}
                digitsAfterPoint={2}
                onChange={(value) => updateQuantitativeDataFormFieldValue("SectionsArea", value)}
              />

              <NumberInput
                value={quantitativeDataForm.TotalSectionsCount.value}
                label="Общее количество участков"
                required={quantitativeDataForm.TotalSectionsCount.isRequired}
                placeholder="Укажите"
                errorMessage={quantitativeDataForm.TotalSectionsCount.error}
                max={99999}
                digitsAfterPoint={0}
                onChange={(value) => updateQuantitativeDataFormFieldValue("TotalSectionsCount", value)}
              />

              <NumberInput
                value={quantitativeDataForm.UsedSectionsCount.value}
                label="Количество используемых участков"
                required={quantitativeDataForm.UsedSectionsCount.isRequired}
                placeholder="Укажите"
                errorMessage={quantitativeDataForm.UsedSectionsCount.error}
                max={99999}
                digitsAfterPoint={0}
                onChange={(value) => updateQuantitativeDataFormFieldValue("UsedSectionsCount", value)}
              />

              <NumberInput
                value={quantitativeDataForm.UnUsedSectionsCount.value}
                label="Количество неиспользуемых участков"
                required={quantitativeDataForm.UnUsedSectionsCount.isRequired}
                placeholder="Укажите"
                tooltipMessage="Неиспользуемым считается визуально заброшенный участок без признаков проживания. При этом у неиспользуемого участка может быть собственник"
                errorMessage={quantitativeDataForm.UnUsedSectionsCount.error}
                max={99999}
                digitsAfterPoint={0}
                onChange={(value) => updateQuantitativeDataFormFieldValue("UnUsedSectionsCount", value)}
              />

              <NumberInput
                value={quantitativeDataForm.RegisteredSectionsCount.value}
                label="Количество участков зарегистрированных в собственность"
                required={quantitativeDataForm.RegisteredSectionsCount.isRequired}
                placeholder="Укажите"
                errorMessage={quantitativeDataForm.RegisteredSectionsCount.error}
                max={99999}
                digitsAfterPoint={0}
                onChange={(value) => updateQuantitativeDataFormFieldValue("RegisteredSectionsCount", value)}
              />

              <NumberInput
                value={quantitativeDataForm.ResidentialBuildingsCount.value}
                label="Количество домов признанных жилыми"
                required={quantitativeDataForm.ResidentialBuildingsCount.isRequired}
                placeholder="Укажите"
                errorMessage={quantitativeDataForm.ResidentialBuildingsCount.error}
                max={99999}
                digitsAfterPoint={0}
                onChange={(value) => updateQuantitativeDataFormFieldValue("ResidentialBuildingsCount", value)}
              />

              <NumberInput
                value={quantitativeDataForm.RegisteredCitizensCount.value}
                label="Количество зарегистрированных в садоводстве граждан"
                required={quantitativeDataForm.RegisteredCitizensCount.isRequired}
                placeholder="Укажите"
                errorMessage={quantitativeDataForm.RegisteredCitizensCount.error}
                max={9999}
                digitsAfterPoint={0}
                onChange={(value) => updateQuantitativeDataFormFieldValue("RegisteredCitizensCount", value)}
              />

              <NumberInput
                value={quantitativeDataForm.ContributionsAggregateAmount.value}
                label="Совокупный размер всех видов взносов за 1 сотку "
                required={quantitativeDataForm.ContributionsAggregateAmount.isRequired}
                placeholder="Укажите"
                errorMessage={quantitativeDataForm.ContributionsAggregateAmount.error}
                max={999999999999}
                digitsAfterPoint={2}
                radix=","
                thousandsSeparator=" "
                onChange={(value) => updateQuantitativeDataFormFieldValue("ContributionsAggregateAmount", value)}
              />
            </SntPassportBlockContentForm>
          </div>
        </SntPassportBlockWrapper>

        <SntPassportBlockWrapper title="Инфраструктура">
          <div>
            <h4 className="headline-h4 sf-text-bold color--text-primary">Электроснабжение</h4>
            <SntPassportBlockContentForm>
              <BaseDropdown
                value={infrastructureForm.ElectricitySupply.IsAvailable.value}
                display={
                  typeof infrastructureForm.ElectricitySupply.IsAvailable.value === "boolean"
                    ? infrastructureForm.ElectricitySupply.IsAvailable.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Наличие электроснабжения"
                placeholder="Не выбрано"
                variant="formfield"
                required={infrastructureForm.ElectricitySupply.IsAvailable.isRequired}
                errorMessage={infrastructureForm.ElectricitySupply.IsAvailable.error}
                onSelect={(value) => updateElectricitySupplyFieldValue("IsAvailable", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>

              <BaseDropdown
                value={infrastructureForm.ElectricitySupply.Type?.value?.Key ?? null}
                display={infrastructureForm.ElectricitySupply.Type?.value?.Value}
                isSelectable={true}
                label="Электросети"
                placeholder="Не выбрано"
                variant="formfield"
                required={!!infrastructureForm.ElectricitySupply.IsAvailable.value}
                errorMessage={infrastructureForm.ElectricitySupply.Type.error}
                onSelect={(value) => {
                  const electricity = electricitySupplyTypesList.find((it) => it.Key === value);
                  if (electricity) updateElectricitySupplyFieldValue("Type", electricity);
                }}
              >
                {electricitySupplyTypesList.map((type) => (
                  <BaseDropdownMenuItem key={type.Key} value={type.Key}>
                    <span className="sf-text-medium">{type.Value}</span>
                  </BaseDropdownMenuItem>
                ))}
              </BaseDropdown>

              <BaseDropdown
                value={infrastructureForm.ElectricitySupply.CTS.value}
                display={
                  typeof infrastructureForm.ElectricitySupply.CTS.value === "boolean"
                    ? infrastructureForm.ElectricitySupply.CTS.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Наличие КТП"
                placeholder="Не выбрано"
                variant="formfield"
                required={!!infrastructureForm.ElectricitySupply.IsAvailable.value}
                errorMessage={infrastructureForm.ElectricitySupply.CTS.error}
                onSelect={(value) => updateElectricitySupplyFieldValue("CTS", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>

              <NumberInput
                value={infrastructureForm.ElectricitySupply.PowerLineLength.value}
                label="Длина ЛЭП"
                required={!!infrastructureForm.ElectricitySupply.IsAvailable.value}
                placeholder="Укажите"
                errorMessage={infrastructureForm.ElectricitySupply.PowerLineLength.error}
                max={99999}
                digitsAfterPoint={0}
                onChange={(value) => updateElectricitySupplyFieldValue("PowerLineLength", value)}
              />
            </SntPassportBlockContentForm>
          </div>

          <div>
            <h4 className="headline-h4 sf-text-bold color--text-primary">Водоснабжение</h4>
            <SntPassportBlockContentForm>
              <BaseDropdown
                value={infrastructureForm.WaterSupply.IsAvailable.value}
                display={
                  typeof infrastructureForm.WaterSupply.IsAvailable.value === "boolean"
                    ? infrastructureForm.WaterSupply.IsAvailable.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Наличие водоснабжения"
                placeholder="Не выбрано"
                variant="formfield"
                required={infrastructureForm.WaterSupply.IsAvailable.isRequired}
                errorMessage={infrastructureForm.WaterSupply.IsAvailable.error}
                onSelect={(value) => updateWaterSupplyFieldValue("IsAvailable", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>

              <MultipleSelect
                value={infrastructureForm.WaterSupply.Type?.value.map((it) => it?.Key) ?? []}
                display={waterSupplyTypesList
                  .filter((it) => infrastructureForm.WaterSupply.Type?.value.includes(it))
                  .map((it) => it.Value)}
                isSelectable={true}
                label="Источник водоснабжения"
                placeholder="Не выбрано"
                variant="formfield"
                required={!!infrastructureForm.WaterSupply.IsAvailable.value}
                errorMessage={infrastructureForm.WaterSupply.Type.error}
                onSelect={(value) => {
                  const list = waterSupplyTypesList.filter((it) => value.includes(it.Key));
                  updateWaterSupplyFieldValue("Type", list ?? []);
                }}
              >
                {waterSupplyTypesList.map((type) => (
                  <MultipleSelectMenuItem key={type.Key} value={type.Key}>
                    <span className="sf-text-medium">{type.Value}</span>
                  </MultipleSelectMenuItem>
                ))}
              </MultipleSelect>

              <BaseInput
                value={infrastructureForm.WaterSupply.License.value}
                label="Номер лицензии водоснабжения, год выдачи"
                required={infrastructureForm.WaterSupply.License.isRequired}
                placeholder="Укажите"
                errorMessage={infrastructureForm.WaterSupply.License.error}
                maxlength={infrastructureForm.WaterSupply.License.maxlength}
                onChange={(value) => updateWaterSupplyFieldValue("License", value)}
              />

              <NumberInput
                value={infrastructureForm.WaterSupply.WaterWellCount.value}
                label="Количество скважин"
                required={infrastructureForm.WaterSupply.WaterWellCount.isRequired}
                placeholder="Введите количество"
                errorMessage={infrastructureForm.WaterSupply.WaterWellCount.error}
                max={99}
                digitsAfterPoint={0}
                onChange={(value) => updateWaterSupplyFieldValue("WaterWellCount", value)}
              />

              <BaseDropdown
                value={infrastructureForm.WaterSupply.SeasonalFireWaterSupply.value}
                display={
                  typeof infrastructureForm.WaterSupply.SeasonalFireWaterSupply.value === "boolean"
                    ? infrastructureForm.WaterSupply.SeasonalFireWaterSupply.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Наличие сезонного пожарного водоснабжения"
                placeholder="Не выбрано"
                variant="formfield"
                required={infrastructureForm.WaterSupply.SeasonalFireWaterSupply.isRequired}
                errorMessage={infrastructureForm.WaterSupply.SeasonalFireWaterSupply.error}
                onSelect={(value) => updateWaterSupplyFieldValue("SeasonalFireWaterSupply", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>

              <BaseDropdown
                value={infrastructureForm.WaterSupply.YearRoundFireWaterSupply.value}
                display={
                  typeof infrastructureForm.WaterSupply.YearRoundFireWaterSupply.value === "boolean"
                    ? infrastructureForm.WaterSupply.YearRoundFireWaterSupply.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Наличие круглогодичного пожарного водоснабжения"
                placeholder="Не выбрано"
                variant="formfield"
                required={infrastructureForm.WaterSupply.YearRoundFireWaterSupply.isRequired}
                errorMessage={infrastructureForm.WaterSupply.YearRoundFireWaterSupply.error}
                onSelect={(value) => updateWaterSupplyFieldValue("YearRoundFireWaterSupply", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>
            </SntPassportBlockContentForm>
          </div>

          <div>
            <h4 className="headline-h4 sf-text-bold color--text-primary">Газоснабжение</h4>
            <SntPassportBlockContentForm>
              <BaseDropdown
                className="col-3-field"
                value={infrastructureForm.GasSupply.IsAvailable.value}
                display={
                  typeof infrastructureForm.GasSupply.IsAvailable.value === "boolean"
                    ? infrastructureForm.GasSupply.IsAvailable.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Газификация садоводства"
                placeholder="Не выбрано"
                variant="formfield"
                required={infrastructureForm.GasSupply.IsAvailable.isRequired}
                errorMessage={infrastructureForm.GasSupply.IsAvailable.error}
                onSelect={(value) => updateGasSupplyFieldValue("IsAvailable", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>

              <div className="col-3-field">
                <NumberInput
                  value={infrastructureForm.GasSupply.CommissioningYear.value}
                  label="Год ввода в эксплуатацию"
                  required={!!infrastructureForm.GasSupply.IsAvailable.value}
                  placeholder="Введите год"
                  errorMessage={infrastructureForm.GasSupply.CommissioningYear.error}
                  max={9999}
                  digitsAfterPoint={0}
                  onChange={(value) => updateGasSupplyFieldValue("CommissioningYear", value)}
                />
              </div>

              <div className="col-3-field">
                <NumberInput
                  value={infrastructureForm.GasSupply.ConnectedBuildingsCount.value}
                  label="Количество подкл.домов к сетевому газу"
                  required={!!infrastructureForm.GasSupply.IsAvailable.value}
                  placeholder="Укажите"
                  errorMessage={infrastructureForm.GasSupply.ConnectedBuildingsCount.error}
                  max={999999999999}
                  digitsAfterPoint={0}
                  onChange={(value) => updateGasSupplyFieldValue("ConnectedBuildingsCount", value)}
                />
              </div>
            </SntPassportBlockContentForm>
          </div>

          <div>
            <h4 className="headline-h4 sf-text-bold color--text-primary">ТКО</h4>
            <SntPassportBlockContentForm>
              <div className="col-3-field">
                <NumberInput
                  value={infrastructureForm.TKO.WasteCollectionSitesCount.value}
                  label="Площадки для сбора ТКО (кол-во)"
                  required={infrastructureForm.TKO.WasteCollectionSitesCount.isRequired}
                  placeholder="Укажите"
                  errorMessage={infrastructureForm.TKO.WasteCollectionSitesCount.error}
                  max={99}
                  digitsAfterPoint={0}
                  onChange={(value) => updateTKOFieldValue("WasteCollectionSitesCount", value)}
                />
              </div>

              <div className="col-3-field">
                <NumberInput
                  value={infrastructureForm.TKO.HoppersCount.value}
                  label="Бункер (кол-во)"
                  required={infrastructureForm.TKO.HoppersCount.isRequired}
                  placeholder="Укажите"
                  errorMessage={infrastructureForm.TKO.HoppersCount.error}
                  max={99}
                  digitsAfterPoint={0}
                  onChange={(value) => updateTKOFieldValue("HoppersCount", value)}
                />
              </div>

              <div className="col-3-field">
                <NumberInput
                  value={infrastructureForm.TKO.ContainersCount.value}
                  label="Контейнер (кол-во)"
                  required={infrastructureForm.TKO.ContainersCount.isRequired}
                  placeholder="Укажите"
                  errorMessage={infrastructureForm.TKO.ContainersCount.error}
                  max={99}
                  digitsAfterPoint={0}
                  onChange={(value) => updateTKOFieldValue("ContainersCount", value)}
                />
              </div>
            </SntPassportBlockContentForm>
          </div>

          <div>
            <h4 className="headline-h4 sf-text-bold color--text-primary">Внешняя дорога к садоводству</h4>
            <SntPassportBlockContentForm>
              <div className="col-3-field">
                <NumberInput
                  value={infrastructureForm.SectionOuterRoad.CommissioningYear.value}
                  label="Год постройки"
                  required={infrastructureForm.SectionOuterRoad.CommissioningYear.isRequired}
                  placeholder="Введите год"
                  errorMessage={infrastructureForm.SectionOuterRoad.CommissioningYear.error}
                  max={9999}
                  digitsAfterPoint={0}
                  onChange={(value) => updateSectionOuterRoadFieldValue("CommissioningYear", value)}
                />
              </div>

              <div className="col-3-field">
                <NumberInput
                  value={infrastructureForm.SectionOuterRoad.SurfaceWorn.value}
                  label="Изношенность покрытия, %"
                  required={infrastructureForm.SectionOuterRoad.SurfaceWorn.isRequired}
                  placeholder="Введите число от 1 до 100"
                  errorMessage={infrastructureForm.SectionOuterRoad.SurfaceWorn.error}
                  max={100}
                  digitsAfterPoint={0}
                  onChange={(value) => updateSectionOuterRoadFieldValue("SurfaceWorn", value)}
                />
              </div>

              <div className="col-3-field">
                <NumberInput
                  value={infrastructureForm.SectionOuterRoad.PublicTransportStopsDistance.value}
                  label="Расстояние до ост. общ-го транспорта (км)"
                  required={infrastructureForm.SectionOuterRoad.PublicTransportStopsDistance.isRequired}
                  placeholder="Укажите"
                  errorMessage={infrastructureForm.SectionOuterRoad.PublicTransportStopsDistance.error}
                  max={999999}
                  digitsAfterPoint={0}
                  onChange={(value) => updateSectionOuterRoadFieldValue("PublicTransportStopsDistance", value)}
                />
              </div>
            </SntPassportBlockContentForm>
          </div>

          <div>
            <h4 className="headline-h4 sf-text-bold color--text-primary">Внутренние дороги</h4>
            <SntPassportBlockContentForm>
              <div className="col-3-field">
                <NumberInput
                  value={infrastructureForm.SectionInnerRoad.Length.value}
                  label="Протяженность (м, км)"
                  required={infrastructureForm.SectionInnerRoad.Length.isRequired}
                  placeholder="Укажите"
                  errorMessage={infrastructureForm.SectionInnerRoad.Length.error}
                  max={999999}
                  digitsAfterPoint={0}
                  onChange={(value) => updateSectionInnerRoadFieldValue("Length", value)}
                />
              </div>

              <div className="col-3-field">
                <NumberInput
                  value={infrastructureForm.SectionInnerRoad.AlleyWidth.value}
                  label="Ширина аллей"
                  required={infrastructureForm.SectionInnerRoad.AlleyWidth.isRequired}
                  placeholder="Укажите"
                  errorMessage={infrastructureForm.SectionInnerRoad.AlleyWidth.error}
                  max={99}
                  digitsAfterPoint={0}
                  onChange={(value) => updateSectionInnerRoadFieldValue("AlleyWidth", value)}
                />
              </div>

              <BaseDropdown
                className="col-3-field"
                value={infrastructureForm.SectionInnerRoad.SurfaceType?.value?.Key ?? null}
                display={infrastructureForm.SectionInnerRoad.SurfaceType?.value?.Value}
                isSelectable={true}
                label="Покрытие"
                placeholder="Выберите"
                variant="formfield"
                required={infrastructureForm.SectionInnerRoad.SurfaceType.isRequired}
                errorMessage={infrastructureForm.SectionInnerRoad.SurfaceType.error}
                onSelect={(value) => {
                  const roadSurface = roadSurfaceTypesList.find((it) => it.Key === value);
                  if (roadSurface) updateSectionInnerRoadFieldValue("SurfaceType", roadSurface);
                }}
              >
                {roadSurfaceTypesList.map((type) => (
                  <BaseDropdownMenuItem key={type.Key} value={type.Key}>
                    <span className="sf-text-medium">{type.Value}</span>
                  </BaseDropdownMenuItem>
                ))}
              </BaseDropdown>

              {infrastructureForm.SectionInnerRoad.SurfaceType?.value?.Key ===
                SntPassportEnums.RoadSurfaceType.Other && (
                <div className="full-field">
                  <BaseInput
                    value={infrastructureForm.SectionInnerRoad.SurfaceTypeKeyOther.value}
                    label="Покрытие (Другое)"
                    required={true}
                    placeholder="Укажите"
                    errorMessage={infrastructureForm.SectionInnerRoad.SurfaceTypeKeyOther.error}
                    maxlength={infrastructureForm.SectionInnerRoad.SurfaceTypeKeyOther.maxlength}
                    onChange={(value) => updateSectionInnerRoadFieldValue("SurfaceTypeKeyOther", value)}
                  />
                </div>
              )}
            </SntPassportBlockContentForm>
          </div>

          <div>
            <h4 className="headline-h4 sf-text-bold color--text-primary">Дополнительная информация</h4>
            <SntPassportBlockContentForm>
              <BaseDropdown
                value={infrastructureForm.Additional.FiberOptic.value}
                display={
                  typeof infrastructureForm.Additional.FiberOptic.value === "boolean"
                    ? infrastructureForm.Additional.FiberOptic.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Оптоволоконная сеть (Интернет)"
                placeholder="Выберите"
                variant="formfield"
                required={infrastructureForm.Additional.FiberOptic.isRequired}
                errorMessage={infrastructureForm.Additional.FiberOptic.error}
                onSelect={(value) => updateAdditionalFieldValue("FiberOptic", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>

              <BaseDropdown
                value={infrastructureForm.Additional.SecurityRoom.value}
                display={
                  typeof infrastructureForm.Additional.SecurityRoom.value === "boolean"
                    ? infrastructureForm.Additional.SecurityRoom.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Наличие помещения охраны"
                placeholder="Выберите"
                variant="formfield"
                required={infrastructureForm.Additional.SecurityRoom.isRequired}
                errorMessage={infrastructureForm.Additional.SecurityRoom.error}
                onSelect={(value) => updateAdditionalFieldValue("SecurityRoom", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>

              <BaseDropdown
                value={infrastructureForm.Additional.AccessControlSystems.value}
                display={
                  typeof infrastructureForm.Additional.AccessControlSystems.value === "boolean"
                    ? infrastructureForm.Additional.AccessControlSystems.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Наличие систем контроля доступа"
                placeholder="Выберите"
                variant="formfield"
                required={infrastructureForm.Additional.AccessControlSystems.isRequired}
                errorMessage={infrastructureForm.Additional.AccessControlSystems.error}
                onSelect={(value) => updateAdditionalFieldValue("AccessControlSystems", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>

              <BaseDropdown
                value={infrastructureForm.Additional.VideoSurveillanceSystems.value}
                display={
                  typeof infrastructureForm.Additional.VideoSurveillanceSystems.value === "boolean"
                    ? infrastructureForm.Additional.VideoSurveillanceSystems.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Наличие систем видеонаблюдения"
                placeholder="Выберите"
                variant="formfield"
                required={infrastructureForm.Additional.VideoSurveillanceSystems.isRequired}
                errorMessage={infrastructureForm.Additional.VideoSurveillanceSystems.error}
                onSelect={(value) => updateAdditionalFieldValue("VideoSurveillanceSystems", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>

              <BaseDropdown
                className="full-field"
                value={infrastructureForm.Additional.Fence.value}
                display={
                  typeof infrastructureForm.Additional.Fence.value === "boolean"
                    ? infrastructureForm.Additional.Fence.value
                      ? "Да"
                      : "Нет"
                    : ""
                }
                isSelectable={true}
                label="Наличие ограждения"
                placeholder="Выберите"
                variant="formfield"
                required={infrastructureForm.Additional.Fence.isRequired}
                errorMessage={infrastructureForm.Additional.Fence.error}
                onSelect={(value) => updateAdditionalFieldValue("Fence", value)}
              >
                <BaseDropdownMenuItem value={true}>
                  <span className="sf-text-medium">Да</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false}>
                  <span className="sf-text-medium">Нет</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>
            </SntPassportBlockContentForm>
          </div>
        </SntPassportBlockWrapper>
      </SntPassportContent>

      <SntPassportBottomBar
        publicationText={
          boardData?.PublicationDatetime
            ? `Ваш паспорт опубликован ${boardData?.PublicationDatetime.parseFromEpochSeconds().formatDate()}`
            : "Ваш паспорт пока не опубликован"
        }
        isSaveLoading={isSaveLoading}
        isPublishLoading={isPublishLoading}
        onPublish={publishData}
        onSave={saveData}
        publishBtnText="Опубликовать для Минсельхоза"
      />
    </div>
  );
};

export default SntPassportBoardPage;
