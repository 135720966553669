import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface IBaseTableBodyProps {
  children: React.ReactNode;
  className?: string;
  refProp?: React.RefObject<any>;
  defaultDivider?: boolean;
  empty?: boolean;
}

const BaseTableBody: React.FC<IBaseTableBodyProps> = ({ children, className, refProp, defaultDivider, empty }) => {
  return (
    <tbody
      ref={refProp}
      className={cx(className, "sf-text-regular", { body: true, "default-divider": defaultDivider, empty })}
    >
      {children}
    </tbody>
  );
};

export default BaseTableBody;
