import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { errorInfoIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleGisZhkhErrorModal } from "@/app/store/slices/snt/myOcc";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";

const SyncErrorModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isOpen, type } = useSelector((state: RootState) => state.sntMyOcc.gisZhkh.errorModal);

  const content: { title: string; text: string } = useMemo(() => {
    switch (type) {
      case "onget":
        return {
          title: "Ошибка получения статусов отправки итогов собрания в ГИС ЖКХ",
          text: "Попробуйте войти позднее",
        };

      case "onsend":
        return {
          title: "Ошибка отправки итогов собрания в ГИС ЖКХ",
          text: "Попробуйте войти позднее",
        };

      default:
        return {
          title: "",
          text: "",
        };
    }
  }, [type]);

  function onClose() {
    dispatch(toggleGisZhkhErrorModal({ isOpen: false }));
  }

  return (
    <BaseModal isOpen={isOpen} minWidth="471px" maxWidth="471px">
      <BaseModalHeader variant="info-iconed" icon={errorInfoIcon} title={content.title} onClose={onClose} />

      <BaseModalContent>
        <div className="center">
          <p className="text--primary sf-text-regular color--text-primary">{content.text}</p>
        </div>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton onClick={onClose}>Понятно</BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default SyncErrorModal;
