import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { RootState, useAppDispatch } from "@/app/store";
import { getOccDetailsById, getOccOwnerReestrByOccId } from "@/app/store/slices/snt/myOcc/actions";
import { checkedIcon, checkDoubledIcon, subtractIcon, chevronRight24Icon } from "@/consts/icons";
import { tooltipClasses } from "@mui/material";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseContainer,
} from "@/components/base-table";
import { OCC, Profile } from "@/types";
import { paths } from "@/consts/routes";
import { Spinner } from "@/components/spinner";
import PageHeader from "@/components/PageHeader";
import BaseTooltip from "@/components/base-tooltip";
import styles from "./styles.module.scss";

const BACK_LINK_STORAGE_NAME = "make-decision-from-path";

const SntMyOCCItemDecision: React.FC = () => {
  const { occId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const company = useSelector((state: RootState) => state.user.company);
  const occ = useSelector((state: RootState) => state.sntMyOcc.occ);
  const owners = useSelector((state: RootState) => state.sntMyOcc.owners);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleOwnerSelect = (ownerId: OCC.RegistryOwner["Id"]) => {
    navigate(paths.myOccById(occ?.Order.Id, ownerId).decisionByOwner);
  };

  function handleBackClick() {
    const path = localStorage.getItem(BACK_LINK_STORAGE_NAME);
    if (path) {
      localStorage.removeItem(BACK_LINK_STORAGE_NAME);
      navigate(path);
      return;
    }
    navigate(paths.myOccById(occ?.Order.Id).index);
  }

  const fetch = async () => {
    setIsLoading(true);
    try {
      if (occId) {
        await Promise.allSettled([
          dispatch(getOccDetailsById(parseInt(occId))).unwrap(),
          dispatch(getOccOwnerReestrByOccId(parseInt(occId))).unwrap(),
        ]);
      }
    } catch (error: any) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();

    if (location.state && location.state.startsWith(paths.myOcc)) {
      localStorage.setItem(BACK_LINK_STORAGE_NAME, location.state);
    }
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <>
            Собрание №{occ?.Order.Number}{" "}
            <span className="color--text-secondary">
              от{" "}
              {occ?.Order?.CreatedAtTimestamp
                ? new Date(occ?.Order?.CreatedAtTimestamp?.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy")
                : occ?.Order?.CreatedAt}
            </span>
          </>
        }
        defaultLink={paths.myOccById(occ?.Order.Id).index}
        onLinkClick={handleBackClick}
      />

      <div className={styles.content}>
        <BaseContainer className={styles.table}>
          <div className={styles.table_container}>
            <BaseTable>
              <BaseTableHead>
                <BaseTableRow className={styles.table_row}>
                  <BaseTableHeadCell className={styles.table_cell_number}>
                    {company?.Type === Profile.CompanyType.SNT ? "№ участка" : "№ Помещения"}
                  </BaseTableHeadCell>

                  <BaseTableHeadCell className={styles.table_cell_name}>Данные собственника</BaseTableHeadCell>

                  {company?.Type === Profile.CompanyType.SNT && (
                    <BaseTableHeadCell className={styles.table_cell_member}>
                      Является членом садоводства
                    </BaseTableHeadCell>
                  )}

                  {company?.Type === Profile.CompanyType.UK && (
                    <>
                      <BaseTableHeadCell>Площадь (м²)</BaseTableHeadCell>
                      <BaseTableHeadCell>Тип собственности</BaseTableHeadCell>
                      <BaseTableHeadCell className={styles.table_cell_number}>Доля</BaseTableHeadCell>
                      <BaseTableHeadCell>Документ о праве собственности</BaseTableHeadCell>
                    </>
                  )}

                  <BaseTableHeadCell className={styles.table_cell_decision}>
                    <span className="flex middle" style={{ gap: "4px" }}>
                      Решение
                      <BaseTooltip
                        title={
                          <div className={styles.decision_tooltip}>
                            <div className="flex middle">
                              {checkedIcon("#12B212")}
                              <span className="sf-text-regular color--text-primary caption--middle">
                                Проголосовал онлайн
                              </span>
                            </div>
                            <div className="flex middle">
                              {checkDoubledIcon}
                              <span className="sf-text-regular color--text-primary caption--middle">
                                Проголосовал офлайн
                              </span>
                            </div>
                            <div className="flex middle">
                              {subtractIcon}
                              <span className="sf-text-regular color--text-primary caption--middle">Не голосовал</span>
                            </div>
                          </div>
                        }
                        sx={{
                          [`& .${tooltipClasses.tooltip}`]: {
                            boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.16), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);",
                            backgroundColor: "white",
                          },
                          [`& .${tooltipClasses.arrow}`]: {
                            color: "white!important",
                          },
                        }}
                      >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 0C12.415 0 16 3.585 16 8C16 12.415 12.415 16 8 16C3.585 16 0 12.415 0 8C0 3.585 3.585 0 8 0ZM6.964 11.478C6.964 12.041 7.422 12.499 7.985 12.499C8.548 12.499 9.006 12.041 9.006 11.478C9.006 10.915 8.548 10.457 7.985 10.457C7.422 10.457 6.964 10.915 6.964 11.478ZM8.116 3.521C6.388 3.521 5.392 4.974 5.392 5.681C5.392 5.995 5.563 6.493 6.126 6.493C6.558 6.493 6.846 6.179 6.859 5.904C6.898 5.629 7.186 5 8.064 5C8.692 5 9.268 5.445 9.268 5.995C9.268 7.095 7.121 7.183 7.121 8.807C7.121 9.357 7.501 9.75 7.972 9.75C8.469 9.75 8.849 9.357 8.849 8.859C8.849 7.979 10.918 7.841 10.918 5.956C10.918 4.581 9.648 3.521 8.116 3.521Z"
                            fill="#99A2AD"
                          />
                        </svg>
                      </BaseTooltip>
                    </span>
                  </BaseTableHeadCell>

                  <BaseTableHeadCell></BaseTableHeadCell>
                </BaseTableRow>
              </BaseTableHead>

              {isLoading ? (
                <BaseTableBody className={styles.empty}>
                  <BaseTableRow>
                    <BaseTableCell colspan={8}>
                      <div className="flex center">
                        <Spinner color="#226dff" size={36} />
                      </div>
                    </BaseTableCell>
                  </BaseTableRow>
                </BaseTableBody>
              ) : (
                <BaseTableBody className="striped-rows">
                  {owners.map((owner) => (
                    <BaseTableRow
                      className={styles.table_row}
                      key={owner.Id}
                      onClick={() => handleOwnerSelect(owner.Id)}
                    >
                      <BaseTableHeadCell className={styles.table_cell_number}>
                        <span className="sf-text-semibold">{owner.ApartmentNumber}</span>
                      </BaseTableHeadCell>

                      <BaseTableCell className={styles.table_cell_name}>
                        <span className="sf-text-semibold">{owner.FullName}</span>
                      </BaseTableCell>

                      {company?.Type === Profile.CompanyType.SNT && (
                        <BaseTableCell className={styles.table_cell_member}>
                          <span className="sf-text-semibold">{owner.IsMember ? "Да" : " Нет"}</span>
                        </BaseTableCell>
                      )}

                      {company?.Type === Profile.CompanyType.UK && (
                        <>
                          <BaseTableCell>
                            <span className="sf-text-semibold">{owner.Area}</span>
                          </BaseTableCell>
                          <BaseTableCell>
                            <span className="sf-text-semibold">{owner.TypeOwnership}</span>
                          </BaseTableCell>
                          <BaseTableCell className={styles.table_cell_number}>
                            <span className="sf-text-semibold">{owner.SizeShares}</span>
                          </BaseTableCell>
                          <BaseTableCell>
                            <span className="sf-text-semibold">{owner.DateRecordNumber}</span>
                          </BaseTableCell>
                        </>
                      )}

                      <BaseTableCell className={styles.table_cell_decision}>
                        <span className="flex middle">
                          {owner.DecisionIsMade === OCC.DecisionIsMade.OnlineViaLokolo
                            ? checkedIcon("#12B212")
                            : owner.DecisionIsMade === OCC.DecisionIsMade.Offline
                            ? checkDoubledIcon
                            : subtractIcon}
                        </span>
                      </BaseTableCell>

                      <BaseTableCell>{chevronRight24Icon("#818C99")}</BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
              )}
            </BaseTable>
          </div>
        </BaseContainer>
      </div>
    </div>
  );
};

export default SntMyOCCItemDecision;
