import styles from "../styles.module.scss";

interface IAccordionFiltersRowItemProps {
  title: string;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
}

const AccordionFiltersRowItem: React.FC<IAccordionFiltersRowItemProps> = ({ title, children }) => {
  return (
    <div className={styles["b-accordion-filters-item"]}>
      <div className={styles["b-accordion-filters-item__head"]}>
        <p className="text--primary color--text-primary sf-text-regular">{title}:</p>
      </div>
      <div>
        <div className={styles["b-accordion-filters-item__body"]}>{children}</div>
      </div>
    </div>
  );
};

export default AccordionFiltersRowItem;
