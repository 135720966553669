import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IBaseUserAvatarProps {
  children?: React.ReactNode;
  avatar?: string;
  size?: React.CSSProperties["width"];
  bgStyle?: React.CSSProperties["background"];
  gap?: React.CSSProperties["gap"];
  onClick?: () => void;
}

const BaseUserAvatar: React.FC<IBaseUserAvatarProps> = ({
  children,
  avatar,
  size = "40px",
  bgStyle,
  gap = "8px",
  onClick,
}) => {
  function handleOnClick(event: React.SyntheticEvent<HTMLSpanElement>) {
    if (onClick) {
      event.preventDefault();
      onClick();
    }
  }

  return (
    <div className={cx("base")} style={{ gap }}>
      <span
        className={cx("base_avatar", { clickable: !!onClick })}
        style={{ width: size, height: size, minWidth: size, background: bgStyle }}
        onClick={handleOnClick}
      >
        {avatar ? <img src={avatar} alt="" /> : <span className="headline-h4 sf-text-bold color--text-white">♡</span>}
      </span>
      {children}
    </div>
  );
};

export default BaseUserAvatar;
