import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import { chevronRight24Icon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import {
  applySearchFilters,
  fetchUsers,
  loadMoreUsers,
  resetSearchFilters,
  setSearchQuery,
  setSearchQueryType,
  toggleUserDetailsModal,
} from "@/app/store/slices/houseUsers";
import BaseHeadlineAlert from "@/components/BaseHeadlineAlert";
import BaseTooltip from "@/components/base-tooltip";
import BaseRadio from "@/components/BaseRadio";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import AccordionFilters, { AccordionFiltersRowItem } from "@/components/AccordionFilters";
import { Spinner } from "@/components/spinner";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseContainer,
} from "@/components/base-table";
import LokoloUserDetailsModal from "./modals/UserDetailsModal";
import { HouseUsers } from "@/types/house-users";
import { activeAddress, isCompanyTypeUK } from "@/app/store/slices/user";
import UserOwnershipStatus from "@/components/UserDetails/OwnershipStatus";
import { Lightbulb24Icon, QuestionTooltip16Icon } from "@/consts/icons-v2";
import PageHeader from "@/components/PageHeader";
import SearchByQueryType from "@/components/SearchByQueryType";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const LokoloUsers: React.FC = () => {
  const dispatch = useAppDispatch();

  const address = useSelector(activeAddress);
  const isUK = useSelector(isCompanyTypeUK);
  const { isLoading, filters, query, queryType, users, statuses, pagination } = useSelector(
    (state: RootState) => state.houseUsers
  );

  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();
  const { measureRef, isIntersecting, observer, hasMore } = useInfiniteScroll({ pagination: pagination ?? undefined });

  const [hoveredRow, setHoveredRow] = useState<{
    key: HouseUsers.User["Key"];
    userId: HouseUsers.User["UserId"];
  } | null>(null);
  const [clickedRow, setClickedRow] = useState<{
    key: HouseUsers.User["Key"];
    userId: HouseUsers.User["UserId"];
  } | null>(null);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [filtersLocal, setFiltersLocal] = useState<typeof filters>(filters);

  function onRowHoverIn(user: HouseUsers.User) {
    setHoveredRow({ key: user.Key, userId: user.UserId });
  }
  function onRowHoverOut() {
    setHoveredRow(null);
  }
  function onRowMouseDown(user: HouseUsers.User) {
    setClickedRow({ key: user.Key, userId: user.UserId });
  }
  function onRowMouseUp() {
    setClickedRow(null);
  }

  function handleQueryChange(value: string) {
    if (value !== query) {
      dispatch(setSearchQuery(value));
    }
  }

  function handleQueryTypeChange(type: HouseUsers.QueryType) {
    if (queryType !== type) {
      dispatch(setSearchQueryType(type));
    }
  }

  async function syncFilters() {
    return await setFiltersLocal(filters);
  }

  async function applyFilters() {
    await dispatch(applySearchFilters(filtersLocal)).unwrap();
  }

  async function resetFilters() {
    setFiltersLocal({
      Esia: null,
      CanOccVote: null,
      Registry: null,
      Ownership: null,
    });
    await dispatch(resetSearchFilters()).unwrap();
  }

  function handleUserSelect(user: HouseUsers.User) {
    dispatch(toggleUserDetailsModal({ isOpen: true, user }));
  }

  function onScroll(e: React.SyntheticEvent) {
    const scrollLeft = (e.target as HTMLElement)?.scrollLeft;
    setScrollLeft(scrollLeft);
  }

  async function fetch() {
    if (!address) {
      return;
    }

    try {
      await dispatch(fetchUsers()).unwrap();
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchMoreData() {
    if (users.length === 0) {
      return;
    }

    try {
      const { Index, Count } = await dispatch(loadMoreUsers()).unwrap();
      if (observer && isIntersecting && Index === Count) {
        observer.disconnect();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
  }, [isIntersecting]);

  useEffect(() => {
    fetch();
  }, [address]);

  return (
    <>
      <PageHeader title="Пользователи Локоло">
        <SearchByQueryType
          query={query}
          type={queryType}
          typeOptions={[
            { value: HouseUsers.QueryType.Fio, text: "По пользователям" },
            { value: HouseUsers.QueryType.Number, text: !isUK ? "По участкам" : "По помещениям" },
          ]}
          onQueryChange={handleQueryChange}
          onTypeChange={(value) => handleQueryTypeChange(value as HouseUsers.QueryType)}
        />
      </PageHeader>

      <div>
        <BaseHeadlineAlert name="access-settings" title="Что это такое?" verticalMargin="0" icon={Lightbulb24Icon()}>
          {isUK ? (
            <p className="text--primary">
              Чтобы у собственника была возможность голосовать в мобильном приложении «Локоло», у него должна быть
              подтверждена собственность через ваш Реестр собственников. Также в системе реализовано упрощенное
              подтверждение собственности, но при таком подтверждении пользователь не сможет участвовать в общих
              собраниях. Здесь вы можете отслеживать статусы пользователей мобильного приложения
            </p>
          ) : (
            <p className="text--primary">
              Чтобы у собственника была возможность голосовать в мобильном приложении «Локоло», у него должна быть
              подтверждена собственность. Здесь вы можете отслеживать статусы пользователей мобильного приложения
            </p>
          )}
        </BaseHeadlineAlert>
      </div>

      <AccordionFilters onSync={syncFilters} onReset={resetFilters} onApply={applyFilters}>
        <AccordionFiltersRowItem title="Статус">
          <BaseDropdown
            value={filtersLocal.Ownership}
            display={
              filtersLocal.Ownership === null ? "Все" : statuses.find((it) => it.key === filtersLocal.Ownership)?.name
            }
            variant="formfield"
            isSelectable={true}
            onSelect={(value) => {
              setFiltersLocal((prevState) => ({ ...prevState, Ownership: value }));
            }}
          >
            <>
              <BaseDropdownMenuItem value={null}>
                <span className="sf-text-regular">Все</span>
              </BaseDropdownMenuItem>
              {statuses.map((status) => (
                <BaseDropdownMenuItem key={status.key} value={status.key}>
                  <span className="sf-text-regular">{status.name}</span>
                </BaseDropdownMenuItem>
              ))}
            </>
          </BaseDropdown>
        </AccordionFiltersRowItem>

        <AccordionFiltersRowItem title="Есть в моем реестре">
          <div className={styles.filters_radio}>
            <BaseRadio
              value={null}
              selected={filtersLocal?.Registry}
              onChange={(value: typeof filters.Registry) =>
                setFiltersLocal((prevState) => ({ ...prevState, Registry: value }))
              }
            >
              Все
            </BaseRadio>
            <BaseRadio
              value={true}
              selected={filtersLocal?.Registry}
              onChange={(value: typeof filters.Registry) =>
                setFiltersLocal((prevState) => ({ ...prevState, Registry: value }))
              }
            >
              Да
            </BaseRadio>
            <BaseRadio
              value={false}
              selected={filtersLocal?.Registry}
              onChange={(value: typeof filters.Registry) =>
                setFiltersLocal((prevState) => ({ ...prevState, Registry: value }))
              }
            >
              Нет
            </BaseRadio>
          </div>
        </AccordionFiltersRowItem>

        <AccordionFiltersRowItem title="Вход через ЕСИА">
          <div className={styles.filters_radio}>
            <BaseRadio
              value={null}
              selected={filtersLocal?.Esia}
              onChange={(value: typeof filters.Esia) => setFiltersLocal((prevState) => ({ ...prevState, Esia: value }))}
            >
              Все
            </BaseRadio>
            <BaseRadio
              value={true}
              selected={filtersLocal?.Esia}
              onChange={(value: typeof filters.Esia) => setFiltersLocal((prevState) => ({ ...prevState, Esia: value }))}
            >
              Да
            </BaseRadio>
            <BaseRadio
              value={false}
              selected={filtersLocal?.Esia}
              onChange={(value: typeof filters.Esia) => setFiltersLocal((prevState) => ({ ...prevState, Esia: value }))}
            >
              Нет
            </BaseRadio>
          </div>
        </AccordionFiltersRowItem>

        {isUK && (
          <AccordionFiltersRowItem title="Может участвовать в собраниях">
            <div className={styles.filters_radio}>
              <BaseRadio
                value={null}
                selected={filtersLocal?.CanOccVote}
                onChange={(value: typeof filters.CanOccVote) =>
                  setFiltersLocal((prevState) => ({ ...prevState, CanOccVote: value }))
                }
              >
                Все
              </BaseRadio>
              <BaseRadio
                value={true}
                selected={filtersLocal?.CanOccVote}
                onChange={(value: typeof filters.CanOccVote) =>
                  setFiltersLocal((prevState) => ({ ...prevState, CanOccVote: value }))
                }
              >
                Да
              </BaseRadio>
              <BaseRadio
                value={false}
                selected={filtersLocal?.CanOccVote}
                onChange={(value: typeof filters.CanOccVote) =>
                  setFiltersLocal((prevState) => ({ ...prevState, CanOccVote: value }))
                }
              >
                Нет
              </BaseRadio>
            </div>
          </AccordionFiltersRowItem>
        )}
      </AccordionFilters>

      <div>
        <BaseContainer className={styles.table}>
          <div
            className={cx("custom-vertical-scrollbar", { table_container: true, shadowed: scrollLeft > 10 })}
            onScroll={onScroll}
          >
            <BaseTable className={styles.sticky}>
              <BaseTableHead>
                <BaseTableRow className={styles.table_head_row}>
                  <BaseTableHeadCell>{!isUK ? "№ участка" : "№ помещения"}</BaseTableHeadCell>
                  <BaseTableHeadCell>Данные собственника</BaseTableHeadCell>
                </BaseTableRow>
              </BaseTableHead>

              {!isLoading && users.length > 0 && (
                <BaseTableBody className={styles.table_body}>
                  {users.map((it, index) => (
                    <BaseTableRow
                      className={cx({
                        table_row: true,
                        hovered: hoveredRow?.key === it.Key && hoveredRow?.userId === it.UserId,
                        active: clickedRow?.key === it.Key && clickedRow?.userId === it.UserId,
                      })}
                      key={`${it.ObjectNumber}-${it.UserId}-${index}`}
                      onClick={() => handleUserSelect(it)}
                      onMouseEnter={() => onRowHoverIn(it)}
                      onMouseLeave={() => onRowHoverOut()}
                      onMouseDown={() => onRowMouseDown(it)}
                      onMouseUp={() => onRowMouseUp()}
                    >
                      <BaseTableHeadCell>
                        <span>{it.ObjectNumber}</span>
                      </BaseTableHeadCell>
                      <BaseTableCell>
                        <span className="sf-text-semibold">{it.Fio}</span>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
              )}
            </BaseTable>

            <BaseTable>
              <BaseTableHead>
                <BaseTableRow className={styles.table_head_row}>
                  <BaseTableHeadCell>Вход через ЕСИА</BaseTableHeadCell>
                  <BaseTableHeadCell>Есть в моем реестре</BaseTableHeadCell>
                  {isUK && <BaseTableHeadCell>Может участвовать в собраниях</BaseTableHeadCell>}
                  <BaseTableHeadCell>
                    <span className="flex middle" style={{ gap: "4px" }}>
                      Статус в приложении
                      {!isUK && (
                        <BaseTooltip title="Для подтверждения собственности пользователь должен авторизоваться в приложении через ЕСИА, а также он должен быть в вашем реестре участков">
                          {QuestionTooltip16Icon("var(--icons-primary)")}
                        </BaseTooltip>
                      )}
                    </span>
                  </BaseTableHeadCell>
                  <BaseTableHeadCell></BaseTableHeadCell>
                </BaseTableRow>
              </BaseTableHead>

              {!isLoading && users.length > 0 && (
                <BaseTableBody className={styles.table_body}>
                  {users.map((it, index) => (
                    <BaseTableRow
                      className={cx({
                        table_row: true,
                        hovered: hoveredRow?.key === it.Key && hoveredRow?.userId === it.UserId,
                        active: clickedRow?.key === it.Key && clickedRow?.userId === it.UserId,
                      })}
                      key={`2-${it.ObjectNumber}-${it.UserId}-${index}`}
                      onClick={() => handleUserSelect(it)}
                      onMouseEnter={() => onRowHoverIn(it)}
                      onMouseLeave={() => onRowHoverOut()}
                      onMouseDown={() => onRowMouseDown(it)}
                      onMouseUp={() => onRowMouseUp()}
                    >
                      <BaseTableCell>
                        <span>{it.EsiaExists ? "Да" : "Нет"}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span>{it.InRegistry ? "Да" : "Нет"}</span>
                      </BaseTableCell>
                      {isUK && (
                        <BaseTableCell>
                          {it.CanOccVote ? (
                            <span>Да</span>
                          ) : (
                            <span className="flex middle" style={{ gap: "4px" }}>
                              Нет
                              <BaseTooltip title="Для участия в общих собраниях пользователь должен авторизоваться в приложении через ЕСИА, а также он должен быть в вашем Реестре собственников">
                                {QuestionTooltip16Icon()}
                              </BaseTooltip>
                            </span>
                          )}
                        </BaseTableCell>
                      )}
                      <BaseTableCell>
                        <span>
                          <UserOwnershipStatus status={it.Ownership} />
                        </span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className={styles.table_row_icon}>{chevronRight24Icon("var(--text-secondary)")}</span>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
              )}
            </BaseTable>
          </div>

          {isLoading ? (
            <div
              className="flex center middle"
              ref={emptyBlockRef}
              style={{ height: `${emptyBlockHeight}px`, minHeight: "350px" }}
            >
              <Spinner size={36} />
            </div>
          ) : users.length === 0 ? (
            <div
              className="flex center middle"
              ref={emptyBlockRef}
              style={{ height: `${emptyBlockHeight}px`, minHeight: "350px" }}
            >
              <div>
                {query.length > 0 ? (
                  <p className="text--primary sf-pro-regular color--text-primary">Поиск не дал результатов</p>
                ) : (
                  <p className="text--primary sf-pro-regular color--text-primary">Пользователей пока нет</p>
                )}
              </div>
            </div>
          ) : hasMore ? (
            <div className="flex center" ref={measureRef}>
              <div style={{ padding: "8px" }}>
                <Spinner size={36} />
              </div>
            </div>
          ) : null}
        </BaseContainer>
      </div>

      <LokoloUserDetailsModal />
    </>
  );
};

export default LokoloUsers;
