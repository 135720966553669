import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface IBaseTableHeadProps {
  children: React.ReactNode;
  className?: string;
  nowrap?: boolean;
}

const BaseTableHead: React.FC<IBaseTableHeadProps> = ({ children, className, nowrap }) => {
  return <thead className={cx(className, "sf-text-semibold", { head: true, nowrap })}>{children}</thead>;
};

export default BaseTableHead;
