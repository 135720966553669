import { Profile, House } from "@/types";
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../..";

export const TOKEN_STORAGE_KEY = "token";
const token = localStorage.getItem(TOKEN_STORAGE_KEY);

interface UserState {
  token: string | null;
  profile: Profile.User | null;
  company: Profile.CompanyData | null;
  institution: Profile.InstitutionData | null;
  activeAddressFiasId: House.Item["FiasId"];
  cadastralNumber: Profile.CadastralNumber | null;
  isGisZhkhDisabled: boolean;
}

const initialState: UserState = {
  token: token ? JSON.parse(token) : "",
  profile: null,
  company: null,
  institution: null,
  activeAddressFiasId: "",
  cadastralNumber: null,
  isGisZhkhDisabled: true,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      if (action.payload != null && action.payload.length) {
        localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(action.payload));
      } else {
        localStorage.removeItem(TOKEN_STORAGE_KEY);
      }
    },

    setUser: (state, action: PayloadAction<UserState["profile"]>) => {
      state.profile = action.payload;
    },

    setCompany: (state, action: PayloadAction<UserState["company"]>) => {
      state.company = action.payload;
    },

    setInstitution: (state, action: PayloadAction<UserState["institution"]>) => {
      state.institution = action.payload;
    },

    setActiveAddressFiasId: (state, action: PayloadAction<House.Item["FiasId"]>) => {
      state.activeAddressFiasId = action.payload;
    },

    setCadastralNumber: (state, action: PayloadAction<UserState["cadastralNumber"]>) => {
      state.cadastralNumber = action.payload;
    },

    setCadastralNumberValue: (state, action: PayloadAction<Profile.CadastralNumber["CadastralNumber"]>) => {
      if (state.cadastralNumber) {
        state.cadastralNumber.CadastralNumber = action.payload;
      }

      if (state.company) {
        const update: Profile.CompanyData = {
          ...state.company,
          Addresses: state.company.Addresses.map((it) => {
            if (it.FiasId === state.activeAddressFiasId) {
              return {
                ...it,
                CadastralNumber: action.payload,
              };
            }
            return it;
          }),
        };
        state.company = update;
      }
    },
  },
  extraReducers: {},
});

const userSelector = (state: RootState) => state.user;

export const activeAddress = createSelector(userSelector, (state) => {
  return state.company?.Addresses?.find((it) => it.FiasId === state.activeAddressFiasId) ?? null;
});

export const isCompanyTypeUK = createSelector(userSelector, (state) => {
  return state.profile?.role === Profile.UserRole.Company && state.profile.company_type === Profile.CompanyType.UK;
});

export const {
  setToken,
  setUser,
  setCompany,
  setInstitution,
  setActiveAddressFiasId,
  setCadastralNumber,
  setCadastralNumberValue,
} = userSlice.actions;

export default userSlice.reducer;

export * from "./actions";
