import CanSyncOccListModal from "./CanSyncOccList";
import SyncSuccessModal from "./SyncSuccess";
import SyncErrorModal from "./Error";

const GisZhkhModals: React.FC = () => {
  return (
    <>
      <CanSyncOccListModal />
      <SyncSuccessModal />
      <SyncErrorModal />
    </>
  );
};

export default GisZhkhModals;
