import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import { RootState, useAppDispatch } from "@/app/store";
import { Chats } from "@/types";
import { ChevronDownSmall16Icon, Lightbulb24Icon } from "@/consts/icons-v2";
import {
  fetchChats,
  loadChats,
  setFilterLastName,
  setFilterRead,
  setQuery,
  setReadFilter,
  showUserDetails,
} from "@/app/store/slices/chats";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import InteractiveEyes from "@/features/InteractiveEyes";
import BaseHeadlineAlert from "@/components/BaseHeadlineAlert";
import BaseDropdown, { BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseQuerySearch from "@/components/base-query-search";
import PageHeader from "@/components/PageHeader";
import Spinner from "@/components/spinner";
import AppealDialog from "./components/Dialog";
import ChatUserDetailsModal from "./components/UserDetailsModal";
import styles from "./styles.module.scss";

const Appeals: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isPageLoading, setPageLoading] = useState<boolean>(false);
  const [isHeadlineShown, setHeadlineShown] = useState<boolean>(true);

  const isUK = useSelector(isCompanyTypeUK);
  const {
    chats,
    pagination,
    filters,
    allChatsCount,
    isLoading: isChatsLoading,
  } = useSelector((state: RootState) => state.chats);

  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight([isHeadlineShown]);
  const { measureRef, isIntersecting, observer, hasMore } = useInfiniteScroll({ pagination: pagination ?? undefined });

  const chatsSorted = useMemo(() => {
    return chats?.length > 0 ? [...chats]?.sort((a, b) => b?.LastMessage?.Timestamp - a?.LastMessage?.Timestamp) : [];
  }, [chats]);

  function setLastName(value: string) {
    dispatch(setFilterLastName(value));
  }

  function setFilter(value: boolean | null) {
    dispatch(setFilterRead(value));
  }

  function selectUserId(userId: Chats.User["Id"]) {
    dispatch(showUserDetails(userId));
  }

  async function fetchAppeals() {
    if (chats.length === 0) {
      setPageLoading(true);
    }

    try {
      await dispatch(fetchChats()).unwrap();
    } catch (error) {
      console.log(error);
    } finally {
      setPageLoading(false);
    }
  }

  async function fetchMoreAppeals() {
    if (chats.length === 0) {
      return;
    }

    try {
      const { Index, Count } = await dispatch(loadChats()).unwrap();
      if (observer && isIntersecting && Index === Count) {
        observer.disconnect();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreAppeals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  useEffect(() => {
    dispatch(setQuery(""));
    dispatch(setReadFilter(null));
    fetchAppeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles["b-appeals"]}>
      <PageHeader title="Обращения" />

      <div>
        <BaseHeadlineAlert
          name="appeals"
          title="Что это такое?"
          verticalMargin="0 0 12px"
          icon={Lightbulb24Icon()}
          onHide={() => setHeadlineShown(false)}
        >
          {isUK ? (
            <p className="text--primary">
              Раздел предназначен для ответов на обращения жителей домов в вашем управлении, которые они оставляют в
              мобильном приложении «Локоло». Обращения отображаются по всем вашим адресам. Вы можете отвечать на
              обращения здесь или в мобильном приложении, как вам удобнее
            </p>
          ) : (
            <p className="text--primary">
              Раздел предназначен для ответов на обращения садоводов, которые они оставляют в мобильном приложении
              “Локоло”. Вы можете отвечать на обращения здесь или в мобильном приложении, как вам удобнее
            </p>
          )}
        </BaseHeadlineAlert>
      </div>

      <div
        ref={emptyBlockRef}
        className={styles["b-appeals__content"]}
        style={{
          height: `${emptyBlockHeight + 24}px`,
        }}
      >
        {emptyBlockHeight !== 0 && (
          <>
            {isPageLoading ? (
              <div className="flex middle">
                <Spinner size={36} />
              </div>
            ) : allChatsCount > 0 ? (
              <div className={styles["b-appeals__chat"]}>
                <div className={styles["b-appeals__chat_dialogs"]}>
                  <div className={styles["b-appeals__chat_dialogs_head"]}>
                    <div style={{ width: "100%" }}>
                      <BaseQuerySearch placeholder="Поиск по фамилии" value={filters.query} onChange={setLastName} />
                    </div>
                    <div>
                      <BaseDropdown
                        className={styles.filters}
                        placeholder="Фильтр"
                        value={filters.read}
                        isSelectable={true}
                        display={filters.read ? "Прочитанные" : filters.read === false ? "Непрочитанные" : undefined}
                        onSelect={(value) => setFilter(value)}
                        customIcon={ChevronDownSmall16Icon()}
                      >
                        <div className={styles.filters_options}>
                          <BaseDropdownMenuItem value={null} defaultHover>
                            <span className="sf-text-medium">Все обращения</span>
                          </BaseDropdownMenuItem>
                          <BaseDropdownMenuItem value={true} defaultHover>
                            <span className="sf-text-medium">Прочитанные</span>
                          </BaseDropdownMenuItem>
                          <BaseDropdownMenuItem value={false} defaultHover>
                            <span className="sf-text-medium">Непрочитанные</span>
                          </BaseDropdownMenuItem>
                        </div>
                      </BaseDropdown>
                    </div>
                  </div>

                  {isChatsLoading ? (
                    <div className={styles["b-appeals__chat_dialogs_loading"]}>
                      <Spinner size={36} />
                    </div>
                  ) : chats.length > 0 ? (
                    <div className={styles["b-appeals__chat_dialogs_list"]}>
                      {chatsSorted.map((chat) => (
                        <AppealDialog chat={chat} key={chat.Id} openUserDetails={() => selectUserId(chat.User.Id)} />
                      ))}

                      {hasMore && (
                        <div ref={measureRef} className="flex center" style={{ padding: "12px 0" }}>
                          <Spinner color="#226dff" size={36} />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={styles["b-appeals__chat_dialogs_loading"]}>
                      <p className="sf-text-regular color--text-secondary text--primary">Ничего не нашлось</p>
                    </div>
                  )}
                </div>

                <div className={styles["b-appeals__chat_messages"]}>
                  <Outlet />
                </div>
              </div>
            ) : (
              <div className="flex middle center">
                <div className={styles["b-appeals__empty"]}>
                  <InteractiveEyes />
                  <div className="center">
                    <h4 className="headline-h4 sf-text-bold color--text-primary">Обращений пока нет</h4>
                    <div className="sf-text-regular text--primary color--text-primary">Можно немного отдохнуть :)</div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <ChatUserDetailsModal />
    </div>
  );
};

export default Appeals;
