import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Dictionary, OCCV2Order } from "@/types";
import { RootState } from "../../";

interface DictionaryState {
  statuses: {
    list: Dictionary.DictionaryItem<Dictionary.OCCStatus>[];
    isLoading: boolean;
  };
  types: {
    list: Dictionary.DictionaryItem<Dictionary.OCCType>[];
    isLoading: boolean;
  };
  forms: {
    list: Dictionary.DictionaryItem<Dictionary.OCCForm>[];
    isLoading: boolean;
  };
  locations: {
    list: Dictionary.DictionaryItem<Dictionary.OCCLocation>[];
    isLoading: boolean;
  };
  quorums: {
    list: Dictionary.DictionaryItem<Dictionary.Quorum>[];
    isLoading: boolean;
  };
  apartmentType: {
    list: Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentType>[];
    isLoading: boolean;
  };
  ownership: {
    list: Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentOwnership>[];
    isLoading: boolean;
  };
  gisZhkhSentStatuses: {
    list: Dictionary.DictionaryItem<OCCV2Order.GisZhkhStatus>[];
    isLoading: boolean;
  };
}

const initialState: DictionaryState = {
  statuses: {
    list: [],
    isLoading: false,
  },
  types: {
    list: [],
    isLoading: false,
  },
  forms: {
    list: [],
    isLoading: false,
  },
  locations: {
    list: [],
    isLoading: false,
  },
  quorums: {
    list: [],
    isLoading: false,
  },
  apartmentType: {
    list: [],
    isLoading: false,
  },
  ownership: {
    list: [],
    isLoading: false,
  },
  gisZhkhSentStatuses: {
    list: [],
    isLoading: false,
  },
};

const dictionarySlice = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    setStatusesLoading: (state, action: PayloadAction<boolean>) => {
      state.statuses.isLoading = action.payload;
    },
    setStatusesList: (state, action: PayloadAction<DictionaryState["statuses"]["list"]>) => {
      state.statuses.list = action.payload;
    },

    setTypesLoading: (state, action: PayloadAction<boolean>) => {
      state.types.isLoading = action.payload;
    },
    setTypesList: (state, action: PayloadAction<DictionaryState["types"]["list"]>) => {
      state.types.list = action.payload;
    },

    setFormsLoading: (state, action: PayloadAction<boolean>) => {
      state.forms.isLoading = action.payload;
    },
    setFormsList: (state, action: PayloadAction<DictionaryState["forms"]["list"]>) => {
      state.forms.list = action.payload;
    },

    setLocationsLoading: (state, action: PayloadAction<boolean>) => {
      state.locations.isLoading = action.payload;
    },
    setLocationsList: (state, action: PayloadAction<DictionaryState["locations"]["list"]>) => {
      state.locations.list = action.payload;
    },

    setQuorumsLoading: (state, action: PayloadAction<boolean>) => {
      state.quorums.isLoading = action.payload;
    },
    setQuorumsList: (state, action: PayloadAction<DictionaryState["quorums"]["list"]>) => {
      state.quorums.list = action.payload;
    },

    setApartmentTypeLoading: (state, action: PayloadAction<boolean>) => {
      state.apartmentType.isLoading = action.payload;
    },
    setApartmentTypeList: (state, action: PayloadAction<DictionaryState["apartmentType"]["list"]>) => {
      state.apartmentType.list = action.payload;
    },

    setOwnershipLoading: (state, action: PayloadAction<boolean>) => {
      state.ownership.isLoading = action.payload;
    },
    setOwnershipList: (state, action: PayloadAction<DictionaryState["ownership"]["list"]>) => {
      state.ownership.list = action.payload;
    },

    setGisZhkhSentStatusesLoading: (state, action: PayloadAction<boolean>) => {
      state.gisZhkhSentStatuses.isLoading = action.payload;
    },
    setGisZhkhSentStatusesList: (state, action: PayloadAction<DictionaryState["gisZhkhSentStatuses"]["list"]>) => {
      state.gisZhkhSentStatuses.list = action.payload;
    },
  },
  extraReducers: {},
});

const dictionarySelector = (state: RootState) => state.dictionary;

export const getDraftStatus = createSelector(dictionarySelector, (state) => {
  const draft = state.statuses.list.find((it) => it.key === Dictionary.OCCStatus.Draft);
  return draft ?? null;
});

export const getLokoloLocation = createSelector(dictionarySelector, (state) => {
  const locolo = state.locations.list.find((it) => it.key === Dictionary.OCCLocation.InformationSystemLokolo);
  return locolo ?? null;
});

export const getIntramuralExtramuralForm = createSelector(dictionarySelector, (state) => {
  const extramural = state.forms.list.find((it) => it.key === Dictionary.OCCForm.IntramuralExtramural);
  return extramural ?? null;
});

export const selectQuorumById = (
  id: Dictionary.DictionaryItem<Dictionary.Quorum>["id"]
): ((state: RootState) => Dictionary.DictionaryItem<Dictionary.Quorum> | null) =>
  createSelector(dictionarySelector, (state) => {
    const quorum = state.quorums.list.find((it) => it.id === id);
    return quorum ?? null;
  });

export const selectStatusById = (
  id: Dictionary.DictionaryItem<Dictionary.OCCStatus>["id"] | null | undefined
): ((state: RootState) => Dictionary.DictionaryItem<Dictionary.OCCStatus> | null) =>
  createSelector(dictionarySelector, (state) => {
    if (id === null || id === undefined) return null;
    const status = state.statuses.list.find((it) => it.id === id);
    return status ?? null;
  });

export const selectTypeById = (
  id: Dictionary.DictionaryItem<Dictionary.OCCType>["id"] | null | undefined
): ((state: RootState) => Dictionary.DictionaryItem<Dictionary.OCCType> | null) =>
  createSelector(dictionarySelector, (state) => {
    if (id === null || id === undefined) return null;
    const type = state.types.list.find((it) => it.id === id);
    return type ?? null;
  });

export const selectFormById = (
  id: Dictionary.DictionaryItem<Dictionary.OCCForm>["id"] | null | undefined
): ((state: RootState) => Dictionary.DictionaryItem<Dictionary.OCCForm> | null) =>
  createSelector(dictionarySelector, (state) => {
    if (id === null || id === undefined) return null;
    const form = state.forms.list.find((it) => it.id === id);
    return form ?? null;
  });

export const selectGisZhkhSentStatusByKey = (
  key: OCCV2Order.GisZhkhStatus | null | undefined
): ((state: RootState) => Dictionary.DictionaryItem<OCCV2Order.GisZhkhStatus> | null) =>
  createSelector(dictionarySelector, (state) => {
    if (key === null || key === undefined) return null;
    const status = state.gisZhkhSentStatuses.list.find((it) => it.key === key);
    return status ?? null;
  });

export default dictionarySlice.reducer;

export const {
  setStatusesList,
  setStatusesLoading,
  setTypesList,
  setTypesLoading,
  setFormsList,
  setFormsLoading,
  setLocationsList,
  setLocationsLoading,
  setQuorumsList,
  setQuorumsLoading,
  setApartmentTypeLoading,
  setApartmentTypeList,
  setOwnershipLoading,
  setOwnershipList,
  setGisZhkhSentStatusesLoading,
  setGisZhkhSentStatusesList,
} = dictionarySlice.actions;
