import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { House } from "@/types";
import { paths } from "@/consts/routes";
import { UKRegistryService } from "@/services/v2";
import { useAppDispatch } from "@/app/store";
import { activeAddress, setActiveAddress } from "@/app/store/slices/user";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import SelectAddressDropdown from "@/components/SelectAddressDropdown";
import BaseButton from "@/components/base-button";

interface ICreateOCCCheckHouseModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateOCCCheckHouseModal: React.FC<ICreateOCCCheckHouseModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<House.Base | null>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const _activeAddress = useSelector(activeAddress);

  async function handleSubmit() {
    if (!address) {
      return;
    }

    try {
      setLoading(true);

      const { data } = await UKRegistryService.getHouseRegistryDetails(address?.FiasId);
      if (data.Data.Count === 0) {
        setErrorMessage(
          "Создание собрания недоступно, так как не добавлен реестр собственников. После добавления реестра собственников функция будет доступна"
        );
        setLoading(false);
        return;
      }

      await dispatch(setActiveAddress(address)).unwrap();
      navigation(paths.uk().fullPath().createOCC.item.create("new"));
      onClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setAddress(_activeAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseModal isOpen={isOpen} minWidth="520px" maxWidth="520px">
      <BaseModalHeader title="Из списка выберите адрес дома, для которого хотите создать собрание" onClose={onClose} />

      <BaseModalContent sx={{ padding: "16px 24px" }}>
        <div>
          <SelectAddressDropdown
            label="Адрес дома"
            formfield={true}
            custom={{
              value: address ?? null,
              onSelect: (value) => {
                setErrorMessage("");
                setAddress(value);
              },
            }}
          />
          {errorMessage && (
            <p style={{ marginTop: "8px" }} className="sf-text-regular text--primary color--text-error">
              {errorMessage}
            </p>
          )}
        </div>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={onClose}>
          Отмена
        </BaseButton>
        <BaseButton isLoading={isLoading} onClick={handleSubmit}>
          Сохранить
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default CreateOCCCheckHouseModal;
