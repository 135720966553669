import { CSSProperties } from "react";

export const logoIcon = (size: number = 52) => (
  <svg width={size} height={size} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="52" height="52" rx="12" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.6195 16.6326C30.2284 10.6335 21.7715 10.6335 18.3804 16.6326L12.898 26.3313C9.49978 32.3429 13.7352 39.8666 20.5175 39.8666H31.4824C38.2647 39.8666 42.5001 32.3429 39.1019 26.3313L33.6195 16.6326ZM30.2044 19.8407C28.3262 16.4975 23.6737 16.4975 21.7955 19.8407L17.4927 27.4994C15.5998 30.8687 17.9405 35.1 21.6972 35.1H30.3027C34.0594 35.1 36.4001 30.8687 34.5072 27.4994L30.2044 19.8407Z"
      fill="white"
    />
  </svg>
);

export const authSubBackground = (
  <svg width="845" height="824" viewBox="0 0 845 824" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M906.129 184.137C765.943 -61.3787 416.348 -61.379 276.163 184.136L49.5242 581.061C-90.9526 827.086 84.135 1135 364.508 1135H817.784C1098.16 1135 1273.24 827.086 1132.77 581.061L906.129 184.137ZM763.59 319.205C686.601 181.904 495.902 181.904 418.913 319.205L242.547 633.732C164.958 772.102 260.9 945.872 414.886 945.872H767.617C921.603 945.872 1017.55 772.102 939.956 633.732L763.59 319.205Z"
      fill="white"
      fillOpacity="0.1"
    />
  </svg>
);

export const registryIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#818C99" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.0001 2.1001C3.84619 2.1001 2.1001 3.84619 2.1001 6.0001V17.0001C2.1001 19.154 3.84619 20.9001 6.0001 20.9001H7.0001C7.49715 20.9001 7.9001 20.4972 7.9001 20.0001C7.9001 19.503 7.49715 19.1001 7.0001 19.1001H6.0001C4.8403 19.1001 3.9001 18.1599 3.9001 17.0001V6.0001C3.9001 4.8403 4.8403 3.9001 6.0001 3.9001H18.0001C19.1599 3.9001 20.1001 4.8403 20.1001 6.0001V7.0001C20.1001 7.49715 20.503 7.9001 21.0001 7.9001C21.4972 7.9001 21.9001 7.49715 21.9001 7.0001V6.0001C21.9001 3.84619 20.154 2.1001 18.0001 2.1001H6.0001Z"
      fill="#818C99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0001 7.1001C13.8462 7.1001 12.1001 8.84619 12.1001 11.0001C12.1001 13.154 13.8462 14.9001 16.0001 14.9001C18.154 14.9001 19.9001 13.154 19.9001 11.0001C19.9001 8.84619 18.154 7.1001 16.0001 7.1001ZM13.9001 11.0001C13.9001 9.8403 14.8403 8.9001 16.0001 8.9001C17.1599 8.9001 18.1001 9.8403 18.1001 11.0001C18.1001 12.1599 17.1599 13.1001 16.0001 13.1001C14.8403 13.1001 13.9001 12.1599 13.9001 11.0001Z"
      fill="#818C99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1821 21.9552C20.537 22.0566 22.0001 22.1195 22.0001 20.5853C22.0001 18.6202 19.6001 16.0001 16.0001 16.0001C12.4001 16.0001 10.0001 18.6202 10.0001 20.5852C10.0001 22.1195 11.4631 22.0566 13.8181 21.9552C14.4793 21.9268 15.2109 21.8953 16.0001 21.8953C16.7893 21.8953 17.5208 21.9268 18.1821 21.9552ZM20.117 20.1889C19.9924 19.8441 19.7125 19.3804 19.1782 18.922C18.4411 18.2898 17.3529 17.8001 16.0001 17.8001C14.6473 17.8001 13.559 18.2898 12.822 18.922C12.2876 19.3804 12.0078 19.8441 11.8832 20.1889C12.2709 20.2116 12.7755 20.1976 13.4683 20.1685L13.7383 20.157C14.3975 20.1286 15.1693 20.0953 16.0001 20.0953C16.8307 20.0953 17.6024 20.1286 18.2615 20.157L18.5319 20.1685C19.2247 20.1976 19.7293 20.2116 20.117 20.1889Z"
      fill="#818C99"
    />
    <path
      d="M6.7501 6.0001C6.33588 6.0001 6.0001 6.33588 6.0001 6.7501C6.0001 7.16431 6.33588 7.5001 6.7501 7.5001H10.2501C10.6643 7.5001 11.0001 7.16431 11.0001 6.7501C11.0001 6.33588 10.6643 6.0001 10.2501 6.0001H6.7501Z"
      fill="#818C99"
    />
    <path
      d="M6.0001 9.7501C6.0001 9.33588 6.33588 9.0001 6.7501 9.0001H10.2501C10.6643 9.0001 11.0001 9.33588 11.0001 9.7501C11.0001 10.1643 10.6643 10.5001 10.2501 10.5001H6.7501C6.33588 10.5001 6.0001 10.1643 6.0001 9.7501Z"
      fill="#818C99"
    />
    <path
      d="M6.7501 12.0001C6.33588 12.0001 6.0001 12.3359 6.0001 12.7501C6.0001 13.1643 6.33588 13.5001 6.7501 13.5001H8.2501C8.66431 13.5001 9.0001 13.1643 9.0001 12.7501C9.0001 12.3359 8.66431 12.0001 8.2501 12.0001H6.7501Z"
      fill="#818C99"
    />
  </svg>
);

export const ukIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M22.2141 22.5938V15.0924H14.1349V22.5938H12.7286V13.6862H17.4714V5.66616H15.8905V2.52408H14.1016V0H12.6953V2.52408H10.9064V5.66616H9.3255V22.5938H7.91925V8.80828H1.78589V22.5938H0V24H24V22.5938H22.2141ZM14.2664 7.40203H15.6726V8.80828H14.2664V7.40203ZM14.2664 10.5441H15.6726V11.9504H14.2664V10.5441ZM5.52464 22.0423H4.11839V20.636H5.52464V22.0423ZM5.52464 18.6783H4.11839V17.272H5.52464V18.6783ZM5.52464 15.3143H4.11839V13.9081H5.52464V15.3143ZM5.52464 11.9504H4.11839V10.5441H5.52464V11.9504ZM12.5305 11.9504H11.1243V10.5441H12.5305V11.9504ZM12.5305 8.80828H11.1243V7.40203H12.5305V8.80828ZM12.6953 4.25991H14.1015V5.66616H12.6953V4.25991ZM17.2967 22.5938H15.8905V16.95H17.2967V22.5938ZM20.4585 22.5938H19.0523V16.95H20.4585V22.5938Z"
      fill="#284FD8"
    />
  </svg>
);

export const myHouseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6ZM10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10Z"
      fill="#818C99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4253 21.8184C11.4256 21.8186 11.4258 21.8187 12 21L11.4253 21.8184C11.7699 22.0601 12.2295 22.0604 12.5742 21.8187L12 21C12.5742 21.8187 12.5739 21.8189 12.5742 21.8187L12.5754 21.8179L12.5772 21.8166L12.5828 21.8127L12.6014 21.7994C12.6171 21.7882 12.6393 21.7722 12.6675 21.7517C12.7238 21.7106 12.8044 21.6511 12.9057 21.5741C13.1083 21.4203 13.3942 21.1962 13.7359 20.9101C14.4177 20.3389 15.3289 19.5139 16.2431 18.4987C18.0363 16.5076 20 13.6105 20 10.3636C20 8.16134 19.1688 6.0397 17.6745 4.46777C16.1786 2.89413 14.1385 2 12 2C9.86146 2 7.82141 2.89413 6.32548 4.46777C4.83118 6.0397 4 8.16134 4 10.3636C4 13.6105 5.96368 16.5076 7.75692 18.4987C8.67111 19.5139 9.58227 20.3389 10.2641 20.9101C10.6058 21.1962 10.8917 21.4203 11.0943 21.5741C11.1956 21.6511 11.2762 21.7106 11.3325 21.7517C11.3607 21.7722 11.3829 21.7882 11.3986 21.7994L11.4172 21.8127L11.4228 21.8166L11.4253 21.8184ZM7.77503 5.84574C8.90461 4.65749 10.4255 4 12 4C13.5745 4 15.0954 4.65749 16.225 5.84574C17.3562 7.03572 18 8.66001 18 10.3636C18 12.844 16.4637 15.2652 14.7569 17.1603C13.9211 18.0884 13.0823 18.8486 12.4516 19.3769C12.2846 19.5168 12.1327 19.64 12 19.7453C11.8673 19.64 11.7154 19.5168 11.5484 19.3769C10.9177 18.8486 10.0789 18.0884 9.24308 17.1603C7.53632 15.2652 6 12.844 6 10.3636C6 8.66001 6.64381 7.03572 7.77503 5.84574Z"
      fill="#818C99"
    />
  </svg>
);

export const createIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M18 3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3V6H13C12.4477 6 12 6.44772 12 7C12 7.55228 12.4477 8 13 8H16V11C16 11.5523 16.4477 12 17 12C17.5523 12 18 11.5523 18 11V8H21C21.5523 8 22 7.55228 22 7C22 6.44772 21.5523 6 21 6H18V3Z"
      fill="#818C99"
    />
    <path
      d="M8.7587 3C7.95373 2.99999 7.28937 2.99998 6.74818 3.04419C6.18608 3.09012 5.66937 3.18868 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43598 5.18404C3.18868 5.66937 3.09012 6.18608 3.04419 6.74817C2.99998 7.28937 2.99999 7.95372 3 8.75868V15.2413C2.99999 16.0463 2.99998 16.7106 3.04419 17.2518C3.09012 17.8139 3.18868 18.3306 3.43598 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.66937 20.8113 6.18608 20.9099 6.74818 20.9558C7.28937 21 7.95372 21 8.75868 21H15.2413C16.0463 21 16.7106 21 17.2518 20.9558C17.8139 20.9099 18.3306 20.8113 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.8113 18.3306 20.9099 17.8139 20.9558 17.2518C21 16.7106 21 16.0463 21 15.2413V13C21 12.4477 20.5523 12 20 12C19.4477 12 19 12.4477 19 13V15.2C19 16.0566 18.9992 16.6389 18.9624 17.089C18.9266 17.5274 18.8617 17.7516 18.782 17.908C18.5903 18.2843 18.2843 18.5903 17.908 18.782C17.7516 18.8617 17.5274 18.9266 17.089 18.9624C16.6389 18.9992 16.0566 19 15.2 19H8.8C7.94342 19 7.36113 18.9992 6.91104 18.9624C6.47262 18.9266 6.24842 18.8617 6.09202 18.782C5.7157 18.5903 5.40974 18.2843 5.21799 17.908C5.1383 17.7516 5.07337 17.5274 5.03755 17.089C5.00078 16.6389 5 16.0566 5 15.2V8.8C5 7.94342 5.00078 7.36113 5.03755 6.91104C5.07337 6.47262 5.1383 6.24842 5.21799 6.09202C5.40974 5.71569 5.7157 5.40973 6.09202 5.21799C6.24842 5.1383 6.47262 5.07337 6.91104 5.03755C7.36113 5.00078 7.94342 5 8.8 5H11C11.5523 5 12 4.55228 12 4C12 3.44772 11.5523 3 11 3L8.7587 3Z"
      fill="#818C99"
    />
  </svg>
);

export const myMeetingsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.04652 9.86171C8.74809 9.4017 10 7.84705 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 7.86384 3.27477 9.42994 5 9.87398V21C5 21.5523 5.44772 22 6 22C6.55228 22 7 21.5523 7 21V15.0001C7.28228 15.3764 7.59237 15.7345 7.92893 16.0711C9.59483 17.737 11.788 18.7544 14.1162 18.9609C14.5465 20.7059 16.1221 22 18 22C20.2091 22 22 20.2091 22 18C22 15.7909 20.2091 14 18 14C16.153 14 14.5983 15.2519 14.1383 16.9535C12.3334 16.758 10.6389 15.9526 9.34315 14.6569C8.04737 13.3611 7.24201 11.6666 7.04652 9.86171ZM6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4ZM16 18C16 19.1046 16.8954 20 18 20C19.1046 20 20 19.1046 20 18C20 16.8954 19.1046 16 18 16C16.8954 16 16 16.8954 16 18Z"
      fill="#818C99"
    />
  </svg>
);

export const contactsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12Z"
      fill="#818C99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12ZM12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7Z"
      fill="#818C99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
      fill="#818C99"
    />
  </svg>
);

export const documentsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8718 1.08299C15.0493 1.12561 15.2223 1.18428 15.3887 1.25813C15.4053 1.26515 15.4217 1.2726 15.4378 1.28047C15.5411 1.32854 15.6416 1.38251 15.739 1.44218C16.0445 1.62942 16.298 1.88333 16.5854 2.17131L21.8287 7.4146C22.1167 7.70205 22.3706 7.95549 22.5578 8.26103C22.6175 8.35843 22.6715 8.45901 22.7196 8.56226C22.7274 8.57835 22.7348 8.59468 22.7418 8.61124C22.8157 8.77763 22.8744 8.95064 22.917 9.12819C23.0007 9.47663 23.0003 9.83538 23 10.2423L22.9999 20.0001C22.9999 20.7958 22.6838 21.5588 22.1212 22.1214C21.5586 22.684 20.7956 23.0001 19.9999 23.0001H7.99991C7.20426 23.0001 6.4412 22.684 5.87859 22.1214C5.31598 21.5588 4.99991 20.7958 4.99991 20.0001V4.0001C4.99991 3.20445 5.31598 2.44139 5.87859 1.87878C6.4412 1.31617 7.20426 1.0001 7.99991 1.0001L13.7577 1.00005C14.1646 0.999672 14.5234 0.999338 14.8718 1.08299ZM13.6744 3.0001C13.8072 3.0001 13.9136 3.0005 13.9999 3.00141V7.0001C13.9999 8.65696 15.3431 10.0001 16.9999 10.0001H20.9986C20.9995 10.0864 20.9999 10.1928 20.9999 10.3256V20.0001C20.9999 20.2653 20.8946 20.5197 20.707 20.7072C20.5195 20.8947 20.2651 21.0001 19.9999 21.0001H7.99991C7.73469 21.0001 7.48034 20.8947 7.2928 20.7072C7.10527 20.5197 6.99991 20.2653 6.99991 20.0001V4.0001C6.99991 3.73489 7.10527 3.48053 7.2928 3.29299C7.48034 3.10546 7.73469 3.0001 7.99991 3.0001H13.6744ZM15.9999 7.0001V4.41431L19.5857 8.0001H16.9999C16.4476 8.0001 15.9999 7.55239 15.9999 7.0001Z"
      fill="#818C99"
    />
    <path
      d="M1.3999 6.0001C1.3999 4.78782 2.14374 3.74928 3.19991 3.31599V5.99637L3.1999 6.0001V19.0001L3.19991 19.0038V21.6842C2.14374 21.2509 1.3999 20.2124 1.3999 19.0001V6.0001Z"
      fill="#818C99"
    />
  </svg>
);

export const institutionIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 7C11 4.23858 13.2386 2 16 2H21C21.5523 2 22 2.44772 22 3V6C22 8.76142 19.7614 11 17 11H13V12V15V20H16C16.5523 20 17 20.4477 17 21C17 21.5523 16.5523 22 16 22H13H11H8C7.44772 22 7 21.5523 7 21C7 20.4477 7.44772 20 8 20H11V16H7C4.23858 16 2 13.7614 2 11V8C2 7.44772 2.44772 7 3 7H8C9.12561 7 10.1643 7.37194 11 7.99963V7ZM11 12C11 10.3431 9.65685 9 8 9H4V11C4 12.6569 5.34315 14 7 14H11V12ZM17 9H13V7C13 5.34315 14.3431 4 16 4H20V6C20 7.65685 18.6569 9 17 9Z"
      fill="#818C99"
    />
  </svg>
);

export const institutionSntsMapIcon = (color: string = "#818C99") => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.539 2.15767C22.8262 2.34146 23 2.65898 23 3V18C23 18.3901 22.7732 18.7445 22.4191 18.908L15.9191 21.908C15.6696 22.0231 15.3837 22.0305 15.1286 21.9285L8.04556 19.0953L2.44721 21.8944C2.13723 22.0494 1.76909 22.0329 1.47427 21.8507C1.17945 21.6684 1 21.3466 1 21V6C1 5.60995 1.22679 5.2555 1.58094 5.09204L8.08094 2.09204C8.33812 1.97335 8.63357 1.96928 8.89392 2.08086L15.4849 4.90558L21.5809 2.09204C21.8906 1.94913 22.2517 1.97387 22.539 2.15767ZM14.5 6.6594L9.5 4.51654V17.523L14.5 19.523V6.6594ZM16.5 19.4371L21 17.3602V4.56291L16.5 6.63983V19.4371ZM7.5 17.132V4.56291L3 6.63983V19.382L7.5 17.132Z"
      fill={color}
    />
  </svg>
);

export const accordionIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711L8.70711 11.7071C8.31658 12.0976 7.68342 12.0976 7.29289 11.7071L2.29289 6.70711C1.90237 6.31658 1.90237 5.68342 2.29289 5.29289C2.68342 4.90237 3.31658 4.90237 3.70711 5.29289L8 9.58579L12.2929 5.29289C12.6834 4.90237 13.3166 4.90237 13.7071 5.29289Z"
      fill="black"
    />
  </svg>
);

export const addIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.4 3C8.731 3 9 3.269 9 3.6V7H12.4C12.731 7 13 7.269 13 7.6V8.4C13 8.731 12.731 9 12.4 9H9V12.4C9 12.731 8.731 13 8.4 13H7.6C7.269 13 7 12.731 7 12.4V9H3.6C3.269 9 3 8.731 3 8.4V7.6C3 7.269 3.269 7 3.6 7H7V3.6C7 3.269 7.269 3 7.6 3L8.4 3Z"
      fill="white"
    />
  </svg>
);

export const addIcon2 = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5Z"
      fill="white"
    />
  </svg>
);

export const checkIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <path
      d="M3.94874 9.75397C4.38459 9.75397 4.71409 9.58661 4.93724 9.25188L9.76464 1.97678C9.84484 1.85823 9.90411 1.74142 9.94247 1.62636C9.98082 1.50781 10 1.39449 10 1.2864C10 0.98654 9.8954 0.738981 9.68619 0.543723C9.48047 0.344978 9.22594 0.245605 8.92259 0.245605C8.71339 0.245605 8.53556 0.287446 8.38912 0.371128C8.24616 0.451324 8.11018 0.590794 7.98117 0.789539L3.92782 7.1546L1.89854 4.72259C1.67887 4.46806 1.40342 4.34079 1.07218 4.34079C0.761855 4.34079 0.505579 4.44017 0.303347 4.63891C0.101116 4.83766 0 5.08696 0 5.38682C0 5.5228 0.0209205 5.65181 0.0627615 5.77385C0.108089 5.8924 0.190028 6.01618 0.308577 6.14519L3.00209 9.31987C3.24965 9.60927 3.5652 9.75397 3.94874 9.75397Z"
      fill="white"
    />
  </svg>
);

export const allCheck = (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="4" viewBox="0 0 11 4" fill="none">
    <path
      d="M1.73987 3.05821H9.66418C9.85149 3.05821 10.0235 3.01043 10.1802 2.91486C10.337 2.81547 10.4631 2.6855 10.5587 2.52495C10.6542 2.3644 10.702 2.18856 10.702 1.99743C10.702 1.8063 10.6542 1.63046 10.5587 1.46991C10.4631 1.30936 10.337 1.1813 10.1802 1.08573C10.0235 0.990166 9.85149 0.942383 9.66418 0.942383H1.73987C1.56403 0.942383 1.39775 0.990166 1.24102 1.08573C1.08429 1.1813 0.95432 1.30936 0.851109 1.46991C0.751721 1.63046 0.702026 1.8063 0.702026 1.99743C0.702026 2.18856 0.751721 2.3644 0.851109 2.52495C0.95432 2.6855 1.08429 2.81547 1.24102 2.91486C1.39775 3.01043 1.56403 3.05821 1.73987 3.05821Z"
      fill="white"
    />
  </svg>
);

export const clearIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M11.2111 3.98273C12.2599 4.68477 12.7327 6.01428 12.2776 7.23491L6.91131 5.32799C7.36636 4.10735 8.60383 3.38557 9.8695 3.506L10.6327 1.45876C10.7676 1.09706 11.1772 0.910574 11.5476 1.04222C11.9181 1.17387 12.1091 1.5738 11.9743 1.93549L11.2111 3.98273ZM8.9263 14.7747C8.20578 14.6315 7.45192 14.42 6.6647 14.1403C5.80791 13.8358 5.03463 13.4942 4.34485 13.1155L4.80343 12.0165C4.82965 11.9537 4.81613 11.8817 4.76878 11.832C4.70162 11.7616 4.58869 11.7577 4.51656 11.8232L3.59061 12.6649C2.98127 12.2685 2.45107 11.837 2 11.3702C2.97659 8.75058 4.30871 6.87536 5.99637 5.74453L12.7043 8.12818C13.2526 10.0536 13.0384 12.3261 12.0618 14.9457C11.3581 15.026 10.6108 15.0172 9.82001 14.9195V13.341C9.82001 13.2448 9.74011 13.1668 9.64155 13.1668C9.56618 13.1668 9.49894 13.213 9.47354 13.2823L8.9263 14.7747Z"
      fill="black"
    />
  </svg>
);

export const editIcon = (color: CSSProperties["fill"] = "black") => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.40499 3.18401L4.69699 7.89201C4.69499 7.89401 4.44699 8.16301 4.34799 8.68401C4.20799 9.42501 4.10599 9.96501 4.00799 10.485C3.97999 10.633 4.02799 10.782 4.11499 10.885C4.21799 10.972 4.36699 11.02 4.51499 10.992C5.03499 10.894 5.57499 10.792 6.31599 10.652C6.83799 10.553 7.10699 10.304 7.10799 10.303L8.31399 9.09801L11.816 5.59501L9.40499 3.18401ZM11.38 1.20901C11.658 0.930006 12.111 0.930006 12.39 1.20901L13.791 2.61001C14.07 2.88901 14.07 3.34201 13.791 3.62001L12.84 4.57101L10.429 2.16001L11.38 1.20901ZM3 13C2.44772 13 2 13.4477 2 14C2 14.5523 2.44772 15 3 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H3Z"
      fill={color}
    />
  </svg>
);

export const requiredIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.7501 4C8.16431 4 8.5001 4.33579 8.5001 4.75V6.73659L10.141 5.84158C10.5046 5.64323 10.9602 5.77722 11.1585 6.14086C11.3569 6.5045 11.2229 6.96007 10.8592 7.15842L9.31641 7.99996L10.8593 8.84147C11.2229 9.03981 11.3569 9.49538 11.1586 9.85902C10.9602 10.2227 10.5047 10.3567 10.141 10.1583L8.5001 9.26333V11.25C8.5001 11.6642 8.16431 12 7.7501 12C7.33588 12 7.0001 11.6642 7.0001 11.25V9.26341L5.35924 10.1584C4.9956 10.3568 4.54002 10.2228 4.34167 9.85914C4.14333 9.4955 4.27732 9.03993 4.64096 8.84158L6.18386 8L4.641 7.15849C4.27736 6.96015 4.14336 6.50457 4.34169 6.14093C4.54003 5.77729 4.99561 5.64329 5.35925 5.84163L7.0001 6.73659V4.75C7.0001 4.33579 7.33588 4 7.7501 4Z"
      fill="#FA4547"
    />
  </svg>
);

export const errorIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 18C13.1045 18 14 18.8954 14 20C14 21.1046 13.1045 22 12 22C10.8954 22 9.99998 21.1046 9.99998 20C9.99998 18.8954 10.8954 18 12 18ZM13.0046 2C13.5569 2 14.0046 2.44772 14.0046 3C14.0046 3.01215 14.0044 3.0243 14.004 3.03644L13.5871 14.4697C13.576 14.7754 13.4979 15.0473 13.3647 15.274C13.2315 15.5007 13.0433 15.6822 12.8119 15.807C12.5805 15.9319 12.3059 16 12 16C11.6941 16 11.4195 15.9319 11.1881 15.807C10.9567 15.6822 10.7685 15.5007 10.6353 15.274C10.5021 15.0473 10.424 14.7754 10.4129 14.4697L9.99604 3.03644C9.97592 2.48452 10.407 2.02079 10.9589 2.00066L13.0046 2Z"
      fill="#DD2B0E"
    />
  </svg>
);

export const deleteIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.12602 4C8.57006 2.27477 10.1362 1 12 1C13.8638 1 15.4299 2.27477 15.874 4H20C20.5523 4 21 4.44772 21 5C21 5.55228 20.5523 6 20 6H19.4356L18.56 19.133C18.49 20.1836 17.6174 21 16.5644 21H7.43555C6.38263 21 5.51002 20.1836 5.43998 19.133L4.56445 6H4C3.44772 6 3 5.55228 3 5C3 4.44772 3.44772 4 4 4H8.12602ZM10.2676 4C10.6134 3.4022 11.2597 3 12 3C12.7403 3 13.3866 3.4022 13.7324 4H10.2676ZM11 10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10L9 15C9 15.5523 9.44771 16 10 16C10.5523 16 11 15.5523 11 15L11 10ZM14 9C14.5523 9 15 9.44772 15 10V15C15 15.5523 14.5523 16 14 16C13.4477 16 13 15.5523 13 15V10C13 9.44772 13.4477 9 14 9Z"
      fill="#DD2B0E"
    />
  </svg>
);

export const successIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.7071 9.70711C17.0976 9.31658 17.0976 8.68342 16.7071 8.29289C16.3166 7.90237 15.6834 7.90237 15.2929 8.29289L10.5 13.0858L8.70711 11.2929C8.31658 10.9024 7.68342 10.9024 7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071L9.79289 15.2071C10.1834 15.5976 10.8166 15.5976 11.2071 15.2071L16.7071 9.70711Z"
      fill="#0DC268"
    />
  </svg>
);

export const networkErrorIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M20.6274 4.70711C21.0144 4.31658 21.0144 3.68342 20.6274 3.29289C20.2405 2.90237 19.6131 2.90237 19.2262 3.29289L3.37262 19.2929C2.98567 19.6834 2.98567 20.3166 3.37262 20.7071C3.75957 21.0976 4.38694 21.0976 4.77389 20.7071L9.87526 15.5586C11.4425 14.5525 13.5132 14.7288 14.8726 16.1008C15.2209 16.4523 15.7855 16.4523 16.1337 16.1008C16.482 15.7493 16.482 15.1795 16.1337 14.828C15.0595 13.7438 13.6829 13.1794 12.2892 13.1224L14.1793 11.2148C15.4619 11.576 16.6691 12.2571 17.6751 13.2724C18.0233 13.6238 18.588 13.6238 18.9362 13.2724C19.2845 12.9209 19.2845 12.351 18.9362 11.9996C17.95 11.0042 16.8061 10.2721 15.5877 9.7934L17.2487 8.11709C18.4144 8.68982 19.5078 9.46507 20.4777 10.4439C20.8259 10.7954 21.3906 10.7954 21.7388 10.4439C22.0871 10.0925 22.0871 9.52261 21.7388 9.17114C20.7782 8.20164 19.71 7.40467 18.5726 6.78093L20.6274 4.70711Z"
      fill="#DD2B0E"
    />
    <path
      d="M2.26119 9.17114C1.91294 9.52261 1.91294 10.0925 2.26119 10.4439C2.60945 10.7954 3.17408 10.7954 3.52233 10.4439C6.30235 7.63823 10.1073 6.49777 13.7236 7.02482C14.211 7.09586 14.6632 6.75464 14.7336 6.26269C14.804 5.77074 14.4659 5.31434 13.9785 5.2433C9.82961 4.63863 5.45566 5.94716 2.26119 9.17114Z"
      fill="#DD2B0E"
    />
    <path
      d="M5.06367 13.2724C4.71541 12.9209 4.71541 12.351 5.06367 11.9996C6.33639 10.7151 7.8742 9.85614 9.50025 9.42524C9.97661 9.29901 10.4642 9.58641 10.5892 10.0672C10.7143 10.5479 10.4296 11.04 9.9532 11.1662C8.6246 11.5183 7.36802 12.2195 6.32481 13.2724C5.97655 13.6238 5.41192 13.6238 5.06367 13.2724Z"
      fill="#DD2B0E"
    />
    <path
      d="M13.4863 19C13.4863 19.8284 12.8209 20.5 12 20.5C11.1792 20.5 10.5138 19.8284 10.5138 19C10.5138 18.1716 11.1792 17.5 12 17.5C12.8209 17.5 13.4863 18.1716 13.4863 19Z"
      fill="#DD2B0E"
    />
  </svg>
);

export const closeIcon = (color: string = "#3A3B3D") => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.19125 6.66325L12.5312 2.32325C12.9622 1.89225 13.6623 1.89225 14.0933 2.32325C14.5243 2.75425 14.5243 3.45425 14.0933 3.88525L9.75325 8.22525L14.0933 12.5653C14.5153 12.9862 14.5153 13.6713 14.0933 14.0933C13.6713 14.5153 12.9862 14.5153 12.5653 14.0933L8.22525 9.75325L3.88525 14.0933C3.45425 14.5243 2.75425 14.5243 2.32325 14.0933C1.89225 13.6623 1.89225 12.9622 2.32325 12.5312L6.66325 8.19125L2.32325 3.85125C1.90125 3.43025 1.90125 2.74525 2.32325 2.32325C2.74525 1.90125 3.43025 1.90125 3.85125 2.32325L8.19125 6.66325Z"
      fill={color}
    />
  </svg>
);

export const closeIcon24 = (color: string = "#3A3B3D") => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4142 12L16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L12 10.5858L8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L10.5858 12L7.2929 15.2929C6.90238 15.6834 6.90238 16.3166 7.2929 16.7071C7.68343 17.0976 8.31659 17.0976 8.70712 16.7071L12 13.4142L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L13.4142 12Z"
      fill={color}
    />
  </svg>
);

export const dropDownIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711L8.70711 11.7071C8.31658 12.0976 7.68342 12.0976 7.29289 11.7071L2.29289 6.70711C1.90237 6.31658 1.90237 5.68342 2.29289 5.29289C2.68342 4.90237 3.31658 4.90237 3.70711 5.29289L8 9.58579L12.2929 5.29289C12.6834 4.90237 13.3166 4.90237 13.7071 5.29289Z"
      fill="#A8ACB1"
    />
  </svg>
);

export const searchIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 7C11.5 9.48528 9.48528 11.5 7 11.5C4.51472 11.5 2.5 9.48528 2.5 7C2.5 4.51472 4.51472 2.5 7 2.5C9.48528 2.5 11.5 4.51472 11.5 7ZM10.4765 11.8907C9.49572 12.5892 8.29583 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 8.29583 12.5892 9.49572 11.8907 10.4765L14.7071 13.2929C15.0976 13.6834 15.0976 14.3166 14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071L10.4765 11.8907Z"
      fill="#99A2AD"
    />
  </svg>
);

export const checkedIcon = (color = "#2C2D2E") => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7071 3.29289C15.0976 3.68342 15.0976 4.31658 14.7071 4.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L1.29289 8.70711C0.902369 8.31658 0.902369 7.68342 1.29289 7.29289C1.68342 6.90237 2.31658 6.90237 2.70711 7.29289L6 10.5858L13.2929 3.29289C13.6834 2.90237 14.3166 2.90237 14.7071 3.29289Z"
      fill={color}
    />
  </svg>
);

export const eyeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8101 7.904C15.9229 8.16554 15.7723 8.45937 15.4996 8.54153L14.4566 8.85575C14.2004 8.93293 13.931 8.7936 13.8213 8.54958C13.3768 7.56078 12.6464 6.70116 11.7066 6.0681C10.6285 5.34196 9.33024 4.95121 7.99793 4.95191C6.66562 4.9526 5.36779 5.34471 4.29064 6.07197C3.35157 6.70599 2.62231 7.56634 2.17901 8.55557C2.06959 8.79975 1.80029 8.93939 1.54401 8.86248L0.500509 8.54933C0.227738 8.46747 0.076896 8.17385 0.189334 7.91219C0.775399 6.54836 1.76631 5.36204 3.05216 4.49388C4.48881 3.5239 6.21979 3.00093 7.99675 3C9.7737 2.99907 11.5053 3.52024 12.9431 4.48871C14.23 5.35552 15.2224 6.54079 15.8101 7.904ZM8 13C9.65685 13 11 11.6569 11 10C11 8.34315 9.65685 7 8 7C6.34315 7 5 8.34315 5 10C5 11.6569 6.34315 13 8 13Z"
      fill="#B8C1CC"
    />
  </svg>
);

export const tableToolbarAddIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M18 3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3V6H13C12.4477 6 12 6.44772 12 7C12 7.55228 12.4477 8 13 8H16V11C16 11.5523 16.4477 12 17 12C17.5523 12 18 11.5523 18 11V8H21C21.5523 8 22 7.55228 22 7C22 6.44772 21.5523 6 21 6H18V3Z"
      fill="#818C99"
    />
    <path
      d="M4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H9C9.55228 8 10 7.55228 10 7C10 6.44772 9.55228 6 9 6H4Z"
      fill="#818C99"
    />
    <path
      d="M4 12C3.44772 12 3 12.4477 3 13C3 13.5523 3.44772 14 4 14H13C13.5523 14 14 13.5523 14 13C14 12.4477 13.5523 12 13 12H4Z"
      fill="#818C99"
    />
    <path
      d="M4 18C3.44772 18 3 18.4477 3 19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 18H4Z"
      fill="#818C99"
    />
  </svg>
);

export const tableToolbarUploadIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6745 2.99998C11.8073 2.99998 11.9137 3.00037 12 3.00129V6.99998C12 8.65683 13.3431 9.99998 15 9.99998H18.9987C18.9996 10.0863 19 10.1927 19 10.3255V20C19 20.2652 18.8946 20.5195 18.7071 20.7071C18.5196 20.8946 18.2652 21 18 21H13C12.4477 21 12 21.4477 12 22C12 22.5523 12.4477 23 13 23H18C18.7957 23 19.5587 22.6839 20.1213 22.1213C20.6839 21.5587 21 20.7956 21 20L21.0001 10.2421C21.0004 9.83526 21.0008 9.47651 20.9171 9.12807C20.8745 8.95052 20.8158 8.7775 20.7419 8.61112C20.7349 8.59456 20.7275 8.57823 20.7197 8.56214C20.6716 8.45888 20.6176 8.3583 20.5579 8.26091C20.3707 7.95537 20.1168 7.70193 19.8288 7.41448L14.5855 2.17119C14.298 1.88321 14.0446 1.6293 13.7391 1.44206C13.6417 1.38239 13.5411 1.32842 13.4379 1.28035C13.4218 1.27248 13.4054 1.26503 13.3888 1.25801C13.2224 1.18416 13.0494 1.12549 12.8719 1.08287C12.5235 0.999216 12.1647 0.99955 11.7578 0.999928L6 0.99998C5.20435 0.99998 4.44129 1.31605 3.87868 1.87866C3.31607 2.44127 3 3.20433 3 3.99998V12C3 12.5523 3.44772 13 4 13C4.55228 13 5 12.5523 5 12V3.99998C5 3.73476 5.10536 3.48041 5.29289 3.29287C5.48043 3.10534 5.73478 2.99998 6 2.99998H11.6745ZM14 6.99998V4.41419L17.5858 7.99998H15C14.4477 7.99998 14 7.55226 14 6.99998Z"
      fill="#818C99"
    />
    <path
      d="M8 23C8 23.5523 7.55228 24 7 24C6.44772 24 6 23.5523 6 23V17.4142L4.70711 18.7071C4.31658 19.0976 3.68342 19.0976 3.29289 18.7071C2.90237 18.3166 2.90237 17.6834 3.29289 17.2929L6.29289 14.2929C6.68342 13.9023 7.31658 13.9023 7.70711 14.2929L10.7071 17.2929C11.0976 17.6834 11.0976 18.3166 10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071L8 17.4142V23Z"
      fill="#818C99"
    />
  </svg>
);

export const tableToolbarSaveIcon = (
  <svg width="24" height="24" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="xml-color">
      <path
        id="Object 27"
        d="M11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V7L11 1Z"
        fill="#FFEDC7"
        stroke="#FFAA1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="Object 26">
        <path
          d="M6.28524 17.3966C6.28026 17.3966 6.27031 17.3939 6.25538 17.3885C6.24044 17.3885 6.22302 17.3831 6.20311 17.3724L3.21653 15.2C3.15182 15.1517 3.09955 15.1061 3.05973 15.0632C3.01991 15.0149 3 14.9479 3 14.8621V14.5241C3 14.4383 3.01991 14.3739 3.05973 14.331C3.09955 14.2828 3.15182 14.2345 3.21653 14.1862L6.20311 12.0138C6.22302 12.0031 6.24044 11.9977 6.25538 11.9977C6.27031 11.9923 6.28026 11.9897 6.28524 11.9897C6.33004 11.9897 6.36737 12.0057 6.39724 12.0379C6.4271 12.0701 6.44204 12.1103 6.44204 12.1586V13.4621C6.44204 13.564 6.41217 13.6418 6.35244 13.6954C6.29769 13.749 6.24791 13.7893 6.20311 13.8161L4.89648 14.6931L6.20311 15.5782C6.24791 15.605 6.29769 15.6425 6.35244 15.6908C6.41217 15.7391 6.44204 15.8169 6.44204 15.9241V17.2276C6.44204 17.2759 6.4271 17.3161 6.39724 17.3483C6.36737 17.3805 6.33004 17.3966 6.28524 17.3966Z"
          fill="#FFAA1F"
        />
        <path
          d="M7.16426 18C7.11946 18 7.07964 17.9812 7.0448 17.9437C7.01493 17.9115 7 17.8713 7 17.823C7 17.8015 7.00498 17.7774 7.01493 17.7506L9.5162 11.2414C9.54109 11.1824 9.57842 11.1287 9.62819 11.0805C9.67797 11.0268 9.75015 11 9.84472 11H10.8452C10.89 11 10.9274 11.0188 10.9572 11.0563C10.9921 11.0885 11.0095 11.1287 11.0095 11.177C11.0095 11.1985 11.0045 11.2226 10.9946 11.2494L8.47089 17.7586C8.45098 17.8123 8.41614 17.8659 8.36636 17.9195C8.31659 17.9732 8.24192 18 8.14237 18H7.16426Z"
          fill="#FFAA1F"
        />
        <path
          d="M11.7148 17.4046C11.67 17.4046 11.6326 17.3885 11.6028 17.3563C11.5729 17.3241 11.558 17.2839 11.558 17.2356V15.9322C11.558 15.8249 11.5853 15.7471 11.6401 15.6989C11.6998 15.6506 11.7521 15.613 11.7969 15.5862L13.1035 14.7011L11.7969 13.8241C11.7521 13.7973 11.6998 13.7571 11.6401 13.7034C11.5853 13.6498 11.558 13.572 11.558 13.4701V12.1667C11.558 12.1184 11.5729 12.0782 11.6028 12.046C11.6326 12.0138 11.67 11.9977 11.7148 11.9977C11.7247 11.9977 11.7347 12.0004 11.7446 12.0057C11.7596 12.0057 11.777 12.0111 11.7969 12.0218L14.7835 14.1943C14.8532 14.2425 14.9054 14.2908 14.9403 14.3391C14.9801 14.382 15 14.4464 15 14.5322V14.8701C15 14.9559 14.9801 15.023 14.9403 15.0713C14.9054 15.1142 14.8532 15.1598 14.7835 15.208L11.7969 17.3805C11.777 17.3912 11.7596 17.3966 11.7446 17.3966C11.7347 17.4019 11.7247 17.4046 11.7148 17.4046Z"
          fill="#FFAA1F"
        />
      </g>
      <g id="Object 25">
        <path
          d="M11 1V3.8C11 4.9201 11 5.48016 11.218 5.90798C11.4097 6.2843 11.7157 6.59027 12.092 6.78201C12.5198 7 13.0799 7 14.2 7H17"
          fill="#FFAA1F"
        />
      </g>
    </g>
  </svg>
);

export const moreBtnIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12C7 10.8954 6.10457 10 5 10Z"
      fill="#818C99"
    />
    <path
      d="M10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12Z"
      fill="#818C99"
    />
    <path
      d="M17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12Z"
      fill="#818C99"
    />
  </svg>
);

export const landRegistryIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.539 2.15767C22.8262 2.34146 23 2.65898 23 3V18C23 18.3901 22.7732 18.7445 22.4191 18.908L15.9191 21.908C15.6696 22.0231 15.3837 22.0305 15.1286 21.9285L8.04556 19.0953L2.44721 21.8944C2.13723 22.0494 1.76909 22.0329 1.47427 21.8507C1.17945 21.6684 1 21.3466 1 21V6C1 5.60995 1.22679 5.2555 1.58094 5.09204L8.08094 2.09204C8.33812 1.97335 8.63357 1.96928 8.89392 2.08086L15.4849 4.90558L21.5809 2.09204C21.8906 1.94913 22.2517 1.97387 22.539 2.15767ZM14.5 6.6594L9.5 4.51654V17.523L14.5 19.523V6.6594ZM16.5 19.4371L21 17.3602V4.56291L16.5 6.63983V19.4371ZM7.5 17.132V4.56291L3 6.63983V19.382L7.5 17.132Z"
      fill="#818C99"
    />
  </svg>
);

export const gardeningMembers = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="0.5" y="0.5" width="15" height="15" rx="2.5" fill="#E6F7E6" stroke="#A6E0A6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.9997 6C8.9997 7.65685 8.15652 9 6.49967 9C4.84281 9 3.9997 7.65685 3.9997 6C3.9997 4.34315 4.84281 3 6.49967 3C8.15652 3 8.9997 4.34315 8.9997 6ZM9.21386 13.5C9.20918 13.5 9.20446 13.5 9.1997 13.5H9.19964L6.79976 13.5H3.7997C3.5455 13.5 3.4184 13.5 3.29504 13.4209C3.20616 13.3639 3.10444 13.2215 3.07931 13.119C3.04444 12.9767 3.0743 12.8895 3.13402 12.715C3.54401 11.5178 4.85221 10.5 6.49968 10.5C8.14717 10.5 9.45538 11.5178 9.86537 12.715C9.92509 12.8895 9.95496 12.9767 9.92008 13.119C9.89495 13.2215 9.79324 13.3639 9.70435 13.4209C9.62725 13.4703 9.54869 13.4889 9.43855 13.4958C9.37578 13.4998 9.30273 13.5 9.21386 13.5ZM11.0855 13.4046C11.0775 13.4372 11.0685 13.4691 11.0587 13.5H12.1998C12.454 13.5 12.5811 13.5 12.7044 13.4209C12.7933 13.3639 12.895 13.2215 12.9201 13.119C12.955 12.9767 12.9252 12.8895 12.8654 12.715C12.4774 11.5819 11.2847 10.6095 9.7614 10.5086C10.3324 11.0098 10.7642 11.6359 11.0006 12.3263L11.0113 12.3569C11.0712 12.5275 11.2062 12.9119 11.0855 13.4046ZM9.21448 8.98614C9.2512 8.94285 9.287 8.89857 9.32185 8.85329C9.9433 8.04597 10.1996 7.01488 10.1996 6C10.1996 4.98512 9.9433 3.95403 9.32185 3.14671C9.287 3.10143 9.2512 3.05714 9.21448 3.01386C9.30672 3.00469 9.4018 3 9.49973 3C11.1566 3 11.9998 4.34315 11.9998 6C11.9998 7.65685 11.1566 9 9.49973 9C9.4018 9 9.30672 8.99531 9.21448 8.98614Z"
      fill="#2DAA2D"
    />
  </svg>
);

export const notGardeningMembers = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="0.5" y="0.5" width="15" height="15" rx="2.5" fill="#F8CFCF" fillOpacity="0.4" stroke="#F89999" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.9997 6C8.9997 7.65685 8.15652 9 6.49967 9C4.84281 9 3.9997 7.65685 3.9997 6C3.9997 4.34315 4.84281 3 6.49967 3C8.15652 3 8.9997 4.34315 8.9997 6ZM9.21386 13.5C9.20918 13.5 9.20446 13.5 9.1997 13.5H9.19964L6.79976 13.5H3.7997C3.5455 13.5 3.4184 13.5 3.29504 13.4209C3.20616 13.3639 3.10444 13.2215 3.07931 13.119C3.04444 12.9767 3.0743 12.8895 3.13402 12.715C3.54401 11.5178 4.85221 10.5 6.49968 10.5C8.14717 10.5 9.45538 11.5178 9.86537 12.715C9.92509 12.8895 9.95496 12.9767 9.92008 13.119C9.89495 13.2215 9.79324 13.3639 9.70435 13.4209C9.62725 13.4703 9.54869 13.4889 9.43855 13.4958C9.37578 13.4998 9.30273 13.5 9.21386 13.5ZM11.0855 13.4046C11.0775 13.4372 11.0685 13.4691 11.0587 13.5H12.1998C12.454 13.5 12.5811 13.5 12.7044 13.4209C12.7933 13.3639 12.895 13.2215 12.9201 13.119C12.955 12.9767 12.9252 12.8895 12.8654 12.715C12.4774 11.5819 11.2847 10.6095 9.7614 10.5086C10.3324 11.0098 10.7642 11.6359 11.0006 12.3263L11.0113 12.3569C11.0712 12.5275 11.2062 12.9119 11.0855 13.4046ZM9.21448 8.98614C9.2512 8.94285 9.287 8.89857 9.32185 8.85329C9.9433 8.04597 10.1996 7.01488 10.1996 6C10.1996 4.98512 9.9433 3.95403 9.32185 3.14671C9.287 3.10143 9.2512 3.05714 9.21448 3.01386C9.30672 3.00469 9.4018 3 9.49973 3C11.1566 3 11.9998 4.34315 11.9998 6C11.9998 7.65685 11.1566 9 9.49973 9C9.4018 9 9.30672 8.99531 9.21448 8.98614Z"
      fill="#F43535"
    />
  </svg>
);

export const ellipsesIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.63507 8.00078C4.63507 9.00278 3.82107 9.81678 2.81907 9.81678C1.81707 9.81678 1.00307 9.00278 1.00307 8.00078C1.00307 6.99878 1.81707 6.18478 2.81907 6.18478C3.82107 6.18478 4.63507 6.99878 4.63507 8.00078ZM9.82607 8.00078C9.82607 9.00778 9.00807 9.82578 8.00107 9.82578C6.99407 9.82578 6.17607 9.00778 6.17607 8.00078C6.17607 6.99378 6.99407 6.17578 8.00107 6.17578C9.00807 6.17578 9.82607 6.99378 9.82607 8.00078ZM14.9971 8.00078C14.9971 8.99878 14.1861 9.80978 13.1881 9.80978C12.1891 9.80978 11.3791 8.99878 11.3791 8.00078C11.3791 7.00278 12.1891 6.19178 13.1881 6.19178C14.1861 6.19178 14.9971 7.00278 14.9971 8.00078Z"
      fill="white"
    />
  </svg>
);

export const importantInfoIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="information-40">
      <circle id="Background [8]" cx="20" cy="20" r="20" fill="#F8D5CF" />
      <g id="Icon">
        <g clipPath="url(#clip0_1462_35412)">
          <g id="info_circle (filled)">
            <path
              id="&#226;&#134;&#179; Icon Color"
              d="M20 10C14.48 10 10 14.48 10 20C10 25.52 14.48 30 20 30C25.52 30 30 25.52 30 20C30 14.48 25.52 10 20 10ZM19 24V20C19 19.4477 19.4477 19 20 19C20.5523 19 21 19.4477 21 20V24C21 24.5523 20.5523 25 20 25C19.4477 25 19 24.5523 19 24ZM18.7502 16.25V15.7502C18.7502 15.1979 19.1979 14.7502 19.7502 14.7502H20.25C20.8023 14.7502 21.25 15.1979 21.25 15.7502V16.25C21.25 16.8023 20.8023 17.25 20.25 17.25H19.7502C19.1979 17.25 18.7502 16.8023 18.7502 16.25Z"
              fill="#DD2B0E"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1462_35412">
        <rect x="8" y="8" width="24" height="24" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const errorInfoIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#F8D5CF" />
    <g clipPath="url(#clip0_2445_8464)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 30C25.5228 30 30 25.5228 30 20C30 14.4772 25.5228 10 20 10C14.4772 10 10 14.4772 10 20C10 25.5228 14.4772 30 20 30ZM16 19C15.4477 19 15 19.4477 15 20C15 20.5523 15.4477 21 16 21H24C24.5523 21 25 20.5523 25 20C25 19.4477 24.5523 19 24 19H16Z"
        fill="#DD2B0E"
      />
    </g>
    <defs>
      <clipPath id="clip0_2445_8464">
        <rect x="8" y="8" width="24" height="24" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const arrowRightWhiteIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2929 4.29289C13.6834 3.90237 14.3166 3.90237 14.7071 4.29289L21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071L14.7071 19.7071C14.3166 20.0976 13.6834 20.0976 13.2929 19.7071C12.9024 19.3166 12.9024 18.6834 13.2929 18.2929L18.5858 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H18.5858L13.2929 5.70711C12.9024 5.31658 12.9024 4.68342 13.2929 4.29289Z"
      fill="white"
    />
  </svg>
);

export const arrowLeftIcon = (color = "#0BB261") => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7071 4.29289C11.0976 4.68342 11.0976 5.31658 10.7071 5.70711L5.41421 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5.41421L10.7071 18.2929C11.0976 18.6834 11.0976 19.3166 10.7071 19.7071C10.3166 20.0976 9.68342 20.0976 9.29289 19.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L9.29289 4.29289C9.68342 3.90237 10.3166 3.90237 10.7071 4.29289Z"
      fill={color}
    />
  </svg>
);

export const xlsFileIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
    <path
      d="M11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V7L11 1Z"
      fill="#BCF3D8"
      stroke="#0DC268"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 1V3.8C11 4.9201 11 5.48016 11.218 5.90798C11.4097 6.2843 11.7157 6.59027 12.092 6.78201C12.5198 7 13.0799 7 14.2 7H17"
      fill="#0DC268"
    />
  </svg>
);

export const pdfFileIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
    <path
      d="M11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V7L11 1Z"
      fill="#F9C0C0"
      stroke="#EE3838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 1V3.8C11 4.9201 11 5.48016 11.218 5.90798C11.4097 6.2843 11.7157 6.59027 12.092 6.78201C12.5198 7 13.0799 7 14.2 7H17"
      fill="#EE3838"
    />
  </svg>
);

export const docFileIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
    <path
      d="M11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V7L11 1Z"
      fill="#BCD5FA"
      stroke="#1067E6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 1V3.8C11 4.9201 11 5.48016 11.218 5.90798C11.4097 6.2843 11.7157 6.59027 12.092 6.78201C12.5198 7 13.0799 7 14.2 7H17"
      fill="#226DFF"
    />
    <rect x="4" y="10" width="5" height="1" rx="0.5" fill="#226DFF" />
    <rect x="4" y="13" width="10" height="1" rx="0.5" fill="#226DFF" />
    <rect x="4" y="16" width="10" height="1" rx="0.5" fill="#226DFF" />
  </svg>
);

export const otherFileIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
    <path
      d="M11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V7L11 1Z"
      fill="#D9E0EB"
      stroke="#97A5BA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 1V3.8C11 4.9201 11 5.48016 11.218 5.90798C11.4097 6.2843 11.7157 6.59027 12.092 6.78201C12.5198 7 13.0799 7 14.2 7H17"
      fill="#97A5BA"
    />
  </svg>
);

export const chevronLefttIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071L4.29289 8.70711C3.90237 8.31658 3.90237 7.68342 4.29289 7.29289L9.29289 2.29289C9.68342 1.90237 10.3166 1.90237 10.7071 2.29289C11.0976 2.68342 11.0976 3.31658 10.7071 3.70711L6.41421 8L10.7071 12.2929C11.0976 12.6834 11.0976 13.3166 10.7071 13.7071Z"
      fill="#3A3B3D"
    />
  </svg>
);

export const chevronRight16Icon = (color: string = "#3A3B3D", size: number = 16) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 2.29289C5.68342 1.90237 6.31658 1.90237 6.70711 2.29289L11.7071 7.29289C11.8946 7.48043 12 7.73478 12 8C12 8.26522 11.8946 8.51957 11.7071 8.70711L6.70711 13.7071C6.31658 14.0976 5.68342 14.0976 5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929L9.58579 8L5.29289 3.70711C4.90237 3.31658 4.90237 2.68342 5.29289 2.29289Z"
      fill={color}
    />
  </svg>
);

export const chevronRight24Icon = (color: string = "#898D93") => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.2635 6.26361C8.91203 6.61508 8.91203 7.18493 9.26351 7.5364L13.7271 12L9.2635 16.4636C8.91203 16.8151 8.91203 17.3849 9.26351 17.7364C9.61498 18.0879 10.1848 18.0879 10.5363 17.7364L15.6363 12.6364C15.9878 12.2849 15.9878 11.715 15.6363 11.3636L10.5363 6.2636C10.1848 5.91213 9.61498 5.91213 9.2635 6.26361Z"
      fill={color}
    />
  </svg>
);

export const chevronUpIcon = (color: string = "#3A3B3D", size: number = 16) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L7.29289 4.29289C7.68342 3.90237 8.31658 3.90237 8.70711 4.29289L13.7071 9.29289C14.0976 9.68342 14.0976 10.3166 13.7071 10.7071C13.3166 11.0976 12.6834 11.0976 12.2929 10.7071L8 6.41421L3.70711 10.7071C3.31658 11.0976 2.68342 11.0976 2.29289 10.7071Z"
      fill={color}
    />
  </svg>
);

export const chevronDownIcon = (color: string = "#3A3B3D", size: number = 16) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711L8.70711 11.7071C8.31658 12.0976 7.68342 12.0976 7.29289 11.7071L2.29289 6.70711C1.90237 6.31658 1.90237 5.68342 2.29289 5.29289C2.68342 4.90237 3.31658 4.90237 3.70711 5.29289L8 9.58579L12.2929 5.29289C12.6834 4.90237 13.3166 4.90237 13.7071 5.29289Z"
      fill={color}
    />
  </svg>
);

export const dropdownSecondaryChevron = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7071 6.29289C12.0976 6.68342 12.0976 7.31658 11.7071 7.70711L8.70711 10.7071C8.31658 11.0976 7.68342 11.0976 7.29289 10.7071L4.29289 7.70711C3.90237 7.31658 3.90237 6.68342 4.29289 6.29289C4.68342 5.90237 5.31658 5.90237 5.70711 6.29289L8 8.58579L10.2929 6.29289C10.6834 5.90237 11.3166 5.90237 11.7071 6.29289Z"
      fill="#3A3B3D"
    />
  </svg>
);

export const addIcon24 = (color: string = "white") => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5Z"
      fill={color}
    />
  </svg>
);

export const subtractIcon24 = (color: string = "white") => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
      fill={color}
    />
  </svg>
);

export const abstainIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M6.03034 5.26205C6.71101 5.26205 7.0757 5.81179 7.0757 6.48564V12.1179C7.0757 12.339 7.33782 12.5231 7.56315 12.5231C7.79419 12.5231 8.09257 12.339 8.09257 12.1179V4.04051C8.09257 3.36256 8.43394 2.81692 9.11876 2.81692C9.79944 2.81692 10.176 3.36205 10.176 4.04051V11.3C10.176 11.5246 10.476 11.7056 10.7003 11.7056C10.9298 11.7056 11.2131 11.5246 11.2131 11.3V3.22205C11.2131 2.54872 11.5384 2 12.2238 2C12.9081 2 13.2976 2.54872 13.2976 3.22205V11.3C13.2976 11.5246 13.5483 11.7056 13.7778 11.7056C14.0021 11.7056 14.3176 11.5246 14.3176 11.3V4.85692C14.3176 4.17949 14.647 3.64974 15.3272 3.64974C16.0115 3.64974 16.3855 4.17897 16.3855 4.85692V15.8605L18.7539 13.1159C19.0973 12.6569 19.7946 12.4174 20.4665 12.8523C20.8161 13.0549 21.3564 13.6836 20.6675 14.5738C20.6675 14.5738 18.1354 17.7405 16.7274 19.4333C15.3857 21.1297 12.7801 22 11.0152 22C5.25642 22 5 18.1149 5 16.8V6.48564C4.99948 5.81179 5.34552 5.26205 6.03034 5.26205Z"
      fill="#818C99"
    />
  </svg>
);

export const agreeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3192 2.58841C10.481 2.23022 10.8376 2 11.2306 2C12.2183 2 13.1688 2.38599 13.872 3.07807C14.5758 3.77067 14.9741 4.71348 14.9741 5.69998V8.29996H19.1453C19.5508 8.29631 19.9528 8.379 20.3239 8.54291C20.6969 8.70766 21.0304 8.95083 21.3005 9.25658C21.5707 9.56238 21.7711 9.92341 21.8866 10.315C22.0021 10.7067 22.0299 11.1188 21.9676 11.5224L21.9674 11.5239L20.7056 19.6222L20.7054 19.6234C20.6017 20.2947 20.2555 20.9032 19.7351 21.3385C19.2163 21.7725 18.5588 22.006 17.8832 21.9999H4.82902C4.08384 21.9999 3.36601 21.7087 2.83428 21.1854C2.30201 20.6616 2 19.9477 2 19.1999V12.8999C2 12.1521 2.30201 11.4382 2.83428 10.9144C3.36601 10.3911 4.08383 10.0999 4.82902 10.0999H6.92692L10.3192 2.58841ZM6.57256 19.9999V12.0999H4.82902C4.60404 12.0999 4.39142 12.1881 4.23714 12.3399C4.08339 12.4912 4 12.693 4 12.8999V19.1999C4 19.4069 4.08339 19.6086 4.23714 19.7599C4.39142 19.9118 4.60403 19.9999 4.82902 19.9999H6.57256Z"
      fill="#0ED6A6"
    />
  </svg>
);

export const disagreeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6372 4.26537C19.4308 4.09103 19.1638 3.99535 18.8866 4.00017L18.8692 4.00033H17.4274V11.9001H18.8692L18.8866 11.9003C19.1638 11.9051 19.4308 11.8094 19.6372 11.6351C19.8281 11.4739 19.9543 11.2564 20 11.0203V4.88012C19.9543 4.64405 19.8281 4.42662 19.6372 4.26537ZM18.8613 2.00033C19.6159 1.98948 20.351 2.2503 20.9278 2.73753C21.5074 3.22716 21.8873 3.91213 21.9907 4.66413C21.9969 4.70925 22 4.75474 22 4.80029V11.1002C22 11.1457 21.9969 11.1912 21.9907 11.2363C21.8873 11.9883 21.5074 12.6733 20.9278 13.1629C20.351 13.6502 19.6159 13.911 18.8613 13.9001H17.0731L13.6808 21.4116C13.519 21.7698 13.1624 22 12.7694 22C11.7817 22 10.8312 21.614 10.128 20.9219C9.42423 20.2294 9.02587 19.2866 9.02587 18.3V15.7001H4.85472C4.44919 15.7038 4.04717 15.6211 3.67608 15.4572C3.30307 15.2924 2.96962 15.0493 2.69946 14.7435C2.42926 14.4377 2.22893 14.0767 2.11339 13.6851C1.99785 13.2934 1.97015 12.8813 2.0324 12.4777L3.2944 4.378C3.39811 3.70654 3.74445 3.09704 4.2649 2.66169C4.78363 2.22777 5.44115 1.99427 6.11672 2.00033H18.8613Z"
      fill="#F82A2A"
    />
  </svg>
);

export const checkDoubledIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7071 4.70711C14.0976 4.31658 14.0976 3.68342 13.7071 3.29289C13.3166 2.90237 12.6834 2.90237 12.2929 3.29289L5 10.5858L1.70711 7.29289C1.31658 6.90237 0.683417 6.90237 0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L4.29289 12.7071C4.68342 13.0976 5.31658 13.0976 5.70711 12.7071L13.7071 4.70711ZM10.7071 12.7071L15.7071 7.70711C16.0976 7.31658 16.0976 6.68342 15.7071 6.29289C15.3166 5.90237 14.6834 5.90237 14.2929 6.29289L9.29289 11.2929C8.90237 11.6834 8.90237 12.3166 9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071Z"
      fill="#226DFF"
    />
  </svg>
);

export const subtractIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4 7C14.731 7 15 7.269 15 7.6V8.4C15 8.731 14.731 9 14.4 9H1.6C1.269 9 1 8.731 1 8.4V7.6C1 7.269 1.269 7 1.6 7L14.4 7Z"
      fill="#818C99"
    />
  </svg>
);

export const deleteGarbageIcon = (color: string = "#818C99") => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2.3C9 2.01997 9 1.87996 8.9455 1.773C8.89757 1.67892 8.82108 1.60243 8.727 1.5545C8.62004 1.5 8.48003 1.5 8.2 1.5H7.8C7.51997 1.5 7.37996 1.5 7.273 1.5545C7.17892 1.60243 7.10243 1.67892 7.0545 1.773C7 1.87996 7 2.01997 7 2.3V3H9V2.3ZM5.5 2.4V3.09091L1.71304 3.43518C1.30921 3.47189 1 3.81048 1 4.21598C1 4.64898 1.35102 5 1.78402 5H5.5H10.5H14.216C14.649 5 15 4.64898 15 4.21598C15 3.81048 14.6908 3.47189 14.287 3.43518L10.5 3.09091V2.4C10.5 1.55992 10.5 1.13988 10.3365 0.819014C10.1927 0.536771 9.96323 0.3073 9.68099 0.16349C9.36012 0 8.94008 0 8.1 0H7.9C7.05992 0 6.63988 0 6.31901 0.16349C6.03677 0.3073 5.8073 0.536771 5.66349 0.819014C5.5 1.13988 5.5 1.55992 5.5 2.4ZM3.0969 6.30816C3.04746 6.42715 3.06454 6.58088 3.0987 6.88834L3.76278 12.865C3.84667 13.62 3.88861 13.9975 4.06276 14.2824C4.21617 14.5333 4.43996 14.7336 4.70631 14.8584C5.00868 15 5.38849 15 6.1481 15H9.8519C10.6115 15 10.9913 15 11.2937 14.8584C11.56 14.7336 11.7838 14.5333 11.9372 14.2824C12.1114 13.9975 12.1533 13.62 12.2372 12.865L12.9013 6.88835C12.9355 6.58088 12.9525 6.42715 12.9031 6.30816C12.8597 6.20366 12.7821 6.11697 12.683 6.06228C12.5702 6 12.4155 6 12.1062 6H3.89381C3.58446 6 3.42978 6 3.31698 6.06228C3.21791 6.11697 3.14032 6.20366 3.0969 6.30816Z"
      fill={color}
    />
  </svg>
);

export const fileUploadIcon = (color: string = "#2E2D2D") => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6745 3.0001C11.8073 3.0001 11.9137 3.0005 12 3.00141V7.0001C12 8.65696 13.3431 10.0001 15 10.0001H18.9987C18.9996 10.0864 19 10.1928 19 10.3256V20.0001C19 20.2653 18.8946 20.5197 18.7071 20.7072C18.5196 20.8947 18.2652 21.0001 18 21.0001H13C12.4477 21.0001 12 21.4478 12 22.0001C12 22.5524 12.4477 23.0001 13 23.0001H18C18.7957 23.0001 19.5587 22.684 20.1213 22.1214C20.6839 21.5588 21 20.7958 21 20.0001L21.0001 10.2423C21.0004 9.83538 21.0008 9.47663 20.9171 9.12819C20.8745 8.95064 20.8158 8.77763 20.7419 8.61124C20.7349 8.59468 20.7275 8.57835 20.7197 8.56226C20.6716 8.45901 20.6176 8.35843 20.5579 8.26103C20.3707 7.95549 20.1168 7.70205 19.8288 7.4146L14.5855 2.17131C14.298 1.88333 14.0446 1.62942 13.7391 1.44218C13.6417 1.38251 13.5411 1.32854 13.4379 1.28047C13.4218 1.2726 13.4054 1.26515 13.3888 1.25813C13.2224 1.18428 13.0494 1.12561 12.8719 1.08299C12.5235 0.999338 12.1647 0.999672 11.7578 1.00005L6 1.0001C5.20435 1.0001 4.44129 1.31617 3.87868 1.87878C3.31607 2.44139 3 3.20445 3 4.0001V12.0001C3 12.5524 3.44772 13.0001 4 13.0001C4.55228 13.0001 5 12.5524 5 12.0001V4.0001C5 3.73489 5.10536 3.48053 5.29289 3.29299C5.48043 3.10546 5.73478 3.0001 6 3.0001H11.6745ZM14 7.0001V4.41431L17.5858 8.0001H15C14.4477 8.0001 14 7.55239 14 7.0001Z"
      fill={color}
    />
    <path
      d="M8 23.0001C8 23.5524 7.55228 24.0001 7 24.0001C6.44772 24.0001 6 23.5524 6 23.0001V17.4143L4.70711 18.7072C4.31658 19.0977 3.68342 19.0977 3.29289 18.7072C2.90237 18.3167 2.90237 17.6835 3.29289 17.293L6.29289 14.293C6.68342 13.9025 7.31658 13.9025 7.70711 14.293L10.7071 17.293C11.0976 17.6835 11.0976 18.3167 10.7071 18.7072C10.3166 19.0977 9.68342 19.0977 9.29289 18.7072L8 17.4143V23.0001Z"
      fill={color}
    />
  </svg>
);

export const privacyIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M5.9945 5.1805C6.6515 5.1805 7.0035 5.7165 7.0035 6.3735V11.865C7.0035 12.0805 7.2565 12.26 7.474 12.26C7.697 12.26 7.985 12.0805 7.985 11.865V3.9895C7.985 3.3285 8.3145 2.7965 8.9755 2.7965C9.6325 2.7965 9.996 3.328 9.996 3.9895V11.0675C9.996 11.2865 10.2855 11.463 10.502 11.463C10.7235 11.463 10.997 11.2865 10.997 11.0675V3.1915C10.997 2.535 11.311 2 11.9725 2C12.633 2 13.009 2.535 13.009 3.1915V11.0675C13.009 11.2865 13.251 11.463 13.4725 11.463C13.689 11.463 13.9935 11.2865 13.9935 11.0675V4.7855C13.9935 4.125 14.3115 3.6085 14.968 3.6085C15.6285 3.6085 15.9895 4.1245 15.9895 4.7855V15.514L18.2755 12.838C18.607 12.3905 19.28 12.157 19.9285 12.581C20.266 12.7785 20.7875 13.3915 20.1225 14.2595C20.1225 14.2595 17.6785 17.347 16.3195 18.9975C15.0245 20.6515 12.5095 21.5 10.806 21.5C5.2475 21.5 5 17.712 5 16.43V6.3735C4.9995 5.7165 5.3335 5.1805 5.9945 5.1805Z"
      fill="#FFCE0A"
    />
  </svg>
);

export const infoBlueCircleIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 16V12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16ZM10.7502 8.24998V7.7502C10.7502 7.19791 11.1979 6.7502 11.7502 6.7502H12.25C12.8023 6.7502 13.25 7.19791 13.25 7.7502V8.24998C13.25 8.80226 12.8023 9.24998 12.25 9.24998H11.7502C11.1979 9.24998 10.7502 8.80226 10.7502 8.24998Z"
      fill="#00A1F0"
    />
  </svg>
);

export const successModalIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#CFF3E1" />
    <g clipPath="url(#clip0_4728_20740)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 30C25.5228 30 30 25.5228 30 20C30 14.4772 25.5228 10 20 10C14.4772 10 10 14.4772 10 20C10 25.5228 14.4772 30 20 30ZM24.7071 17.7071C25.0976 17.3166 25.0976 16.6834 24.7071 16.2929C24.3166 15.9024 23.6834 15.9024 23.2929 16.2929L18.5 21.0858L16.7071 19.2929C16.3166 18.9024 15.6834 18.9024 15.2929 19.2929C14.9024 19.6834 14.9024 20.3166 15.2929 20.7071L17.7929 23.2071C18.1834 23.5976 18.8166 23.5976 19.2071 23.2071L24.7071 17.7071Z"
        fill="#0DC268"
      />
    </g>
    <defs>
      <clipPath id="clip0_4728_20740">
        <rect x="8" y="8" width="24" height="24" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const usersIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 13C18.7614 13 21 10.7614 21 8C21 5.23858 18.7614 3 16 3C13.2386 3 11 5.23858 11 8C11 10.7614 13.2386 13 16 13ZM16 11C17.6569 11 19 9.65685 19 8C19 6.34315 17.6569 5 16 5C14.3431 5 13 6.34315 13 8C13 9.65685 14.3431 11 16 11Z"
      fill="#818C99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9964 14.7619C12.5617 14.1216 14.4773 14 16 14C17.5227 14 19.4383 14.1216 21.0036 14.7619C21.7991 15.0873 22.5562 15.5673 23.1156 16.2821C23.6861 17.011 24 17.9199 24 18.9999C24 20.1045 23.1046 20.9999 22 20.9999H9.99998C8.89542 20.9999 8 20.1045 8 18.9999C8 17.9199 8.3139 17.011 8.88438 16.2821C9.44381 15.5673 10.2009 15.0873 10.9964 14.7619ZM22 18.9999C22 18.33 21.8139 17.8639 21.5406 17.5147C21.2563 17.1514 20.8259 16.8501 20.2464 16.613C19.0617 16.1284 17.4773 16 16 16C14.5227 16 12.9383 16.1284 11.7536 16.613C11.1741 16.8501 10.7437 17.1514 10.4594 17.5147C10.1861 17.8639 10 18.33 10 18.9999H22Z"
      fill="#818C99"
    />
    <path
      d="M9.70711 9.20711C10.0976 8.81658 10.0976 8.18342 9.70711 7.79289C9.31658 7.40237 8.68342 7.40237 8.29289 7.79289L4 12.0858L2.20711 10.2929C1.81658 9.90237 1.18342 9.90237 0.792893 10.2929C0.402369 10.6834 0.402369 11.3166 0.792893 11.7071L3.29289 14.2071C3.68342 14.5976 4.31658 14.5976 4.70711 14.2071L9.70711 9.20711Z"
      fill="#818C99"
    />
  </svg>
);

export const clearFieldIcon = (color = "#B8C1CC") => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.988 0C12.396 0 15.976 3.58 15.976 7.988C15.976 12.396 12.396 15.976 7.988 15.976C3.58 15.976 0 12.396 0 7.988C0 3.58 3.58 0 7.988 0ZM7.988 6.565L6.173 4.75C5.781 4.358 5.143 4.358 4.75 4.75C4.358 5.143 4.358 5.781 4.75 6.173L6.565 7.988L4.757 9.796C4.365 10.188 4.365 10.826 4.757 11.219C5.15 11.611 5.788 11.611 6.18 11.219L7.988 9.411L9.803 11.226C10.195 11.618 10.833 11.618 11.226 11.226C11.618 10.833 11.618 10.195 11.226 9.803L9.411 7.988L11.219 6.18C11.611 5.788 11.611 5.15 11.219 4.757C10.826 4.365 10.188 4.365 9.796 4.757L7.988 6.565Z"
      fill={color}
    />
  </svg>
);

export const tooltipIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C12.415 0 16 3.585 16 8C16 12.415 12.415 16 8 16C3.585 16 0 12.415 0 8C0 3.585 3.585 0 8 0ZM6.964 11.478C6.964 12.041 7.422 12.499 7.985 12.499C8.548 12.499 9.006 12.041 9.006 11.478C9.006 10.915 8.548 10.457 7.985 10.457C7.422 10.457 6.964 10.915 6.964 11.478ZM8.116 3.521C6.388 3.521 5.392 4.974 5.392 5.681C5.392 5.995 5.563 6.493 6.126 6.493C6.558 6.493 6.846 6.179 6.859 5.904C6.898 5.629 7.186 5 8.064 5C8.692 5 9.268 5.445 9.268 5.995C9.268 7.095 7.121 7.183 7.121 8.807C7.121 9.357 7.501 9.75 7.972 9.75C8.469 9.75 8.849 9.357 8.849 8.859C8.849 7.979 10.918 7.841 10.918 5.956C10.918 4.581 9.648 3.521 8.116 3.521Z"
      fill="#A8ACB1"
    />
  </svg>
);

export const lockedIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6.004V5C3 2.792 5.016 1 7.5 1C9.984 1 12 2.792 12 5V6.004C12.56 6.05 13 6.479 13 7V14C13 14.552 12.507 15 11.9 15H3.1C2.493 15 2 14.552 2 14V7C2 6.479 2.44 6.05 3 6.004ZM8.6 10C8.6 9.448 8.107 9 7.5 9C6.893 9 6.4 9.448 6.4 10V11C6.4 11.552 6.893 12 7.5 12C8.107 12 8.6 11.552 8.6 11V10ZM10 6V5C10 3.896 8.742 3 7.5 3C6.258 3 5 3.896 5 5V6H10Z"
      fill="#818C99"
    />
  </svg>
);

export const analyticsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0005 1C11.7353 1 11.4809 1.10536 11.2934 1.29289C11.1058 1.48043 11.0005 1.73478 11.0005 2V12C11.0005 12.5523 11.4482 13 12.0005 13H22.0005C22.5528 13 23.0005 12.5523 23.0005 12C23.0005 10.5555 22.716 9.12506 22.1632 7.79048C21.6104 6.4559 20.8001 5.24327 19.7787 4.22183C18.7572 3.20038 17.5446 2.39013 16.21 1.83733C14.8754 1.28452 13.445 1 12.0005 1ZM13.0005 11V3.05573C13.8391 3.14949 14.662 3.3609 15.4446 3.68508C16.5366 4.13738 17.5287 4.80031 18.3644 5.63604C19.2002 6.47177 19.8631 7.46392 20.3154 8.55585C20.6396 9.33849 20.851 10.1614 20.9448 11H13.0005Z"
      fill="#3A3B3D"
    />
    <path
      d="M8.40036 3.74649C8.90655 3.5256 9.13783 2.93618 8.91694 2.43C8.69604 1.92381 8.10663 1.69253 7.60044 1.91342C5.95361 2.63207 4.5079 3.7432 3.38969 5.14966C2.27149 6.55611 1.51484 8.21508 1.1859 9.98152C0.856961 11.748 0.965745 13.5681 1.50274 15.2828C2.03974 16.9975 2.9886 18.5545 4.26637 19.8177C5.54413 21.081 7.1119 22.012 8.8326 22.5294C10.5533 23.0468 12.3746 23.1348 14.1371 22.7857C15.8997 22.4366 17.5499 21.661 18.9435 20.5269C20.3371 19.3927 21.4316 17.9344 22.1314 16.2794C22.3465 15.7707 22.1085 15.184 21.5999 14.9689C21.0912 14.7538 20.5045 14.9918 20.2894 15.5005C19.7168 16.8545 18.8213 18.0477 17.6811 18.9757C16.5408 19.9036 15.1907 20.5382 13.7486 20.8238C12.3065 21.1094 10.8163 21.0374 9.40849 20.6141C8.00065 20.1908 6.71793 19.4291 5.67248 18.3955C4.62704 17.3619 3.8507 16.088 3.41133 14.685C2.97197 13.2821 2.88297 11.7929 3.1521 10.3477C3.42123 8.90239 4.04031 7.54505 4.9552 6.39432C5.8701 5.24358 7.05296 4.33447 8.40036 3.74649Z"
      fill="#3A3B3D"
    />
  </svg>
);

export const doNotDisturbIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 10.15 4.63 8.45 5.69 7.1L16.9 18.31C15.55 19.37 13.85 20 12 20ZM18.31 16.9L7.1 5.69C8.45 4.63 10.15 4 12 4C16.42 4 20 7.58 20 12C20 13.85 19.37 15.55 18.31 16.9Z"
      fill="#DD2B0E"
    />
  </svg>
);

export const sntRoleIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <rect width="20" height="20" rx="10" fill="#FF3389" />
    <path
      d="M13 14C13.5523 14 14 13.5523 14 13V10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10V13C6 13.5523 6.44772 14 7 14C7.55228 14 8 13.5523 8 13V10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10V13C12 13.5523 12.4477 14 13 14Z"
      fill="white"
    />
  </svg>
);

export const ukRoleIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#FF8D4D"
    />
    <path d="M8.5 4L10 3L11.5 4L11 6H9L8.5 4Z" fill="white" />
    <path d="M9 6L7 14.5L10 17L13 14.5L11 6H9Z" fill="white" />
  </svg>
);

export const infoIcon24 = (color: string = "var(--icons-primary)") => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12C11 11.4477 11.4477 11 12 11Z"
      fill={color}
    />
    <path
      d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
      fill={color}
    />
  </svg>
);
