import styles from "./styles.module.scss";

const InteractiveEyes: React.FC = () => {
  return (
    <svg
      className={styles["b-interactive-eyes"]}
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="59" cy="100" rx="36" ry="60" fill="#DBE8FB" />
      <ellipse cx="141" cy="100" rx="36" ry="60" fill="#DBE8FB" />
      <g className={styles["b-interactive-eyes__pupil"]}>
        <g filter="url(#filter0_i_8329_43478)">
          <ellipse cx="45" cy="101.5" rx="21" ry="22.5" fill="url(#paint0_linear_8329_43478)" />
        </g>
        <g>
          <ellipse cx="44.5" cy="102" rx="15.5" ry="16" fill="#2B313B" />
          <ellipse
            cx="35.4117"
            cy="92.9056"
            rx="4.5"
            ry="6.5"
            transform="rotate(34.939 35.4117 92.9056)"
            fill="#F6F7FA"
          />
        </g>
      </g>

      <g className={styles["b-interactive-eyes__pupil"]}>
        <g filter="url(#filter1_i_8329_43478)">
          <ellipse cx="127" cy="101.5" rx="21" ry="22.5" fill="url(#paint1_linear_8329_43478)" />
        </g>
        <ellipse cx="126.5" cy="102" rx="15.5" ry="16" fill="#2B313B" />
        <ellipse
          cx="117.412"
          cy="92.9056"
          rx="4.5"
          ry="6.5"
          transform="rotate(34.939 117.412 92.9056)"
          fill="#F6F7FA"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_8329_43478"
          x="24"
          y="79"
          width="42"
          height="49"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8329_43478" />
        </filter>
        <filter
          id="filter1_i_8329_43478"
          x="106"
          y="79"
          width="42"
          height="49"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8329_43478" />
        </filter>
        <linearGradient
          id="paint0_linear_8329_43478"
          x1="66"
          y1="112.187"
          x2="22.5546"
          y2="106.647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1784E6" />
          <stop offset="1" stopColor="#0C66D1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8329_43478"
          x1="148"
          y1="112.187"
          x2="104.555"
          y2="106.647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#30A0F9" />
          <stop offset="1" stopColor="#1F78E2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8329_43478"
          x1="148"
          y1="112.187"
          x2="104.555"
          y2="106.647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1784E6" />
          <stop offset="1" stopColor="#0C66D1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default InteractiveEyes;
