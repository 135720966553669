import { Chats, Pagination } from "@/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ChatsState {
  isLoading: boolean;
  chats: Chats.Chat[];
  filters: {
    query: string;
    read: boolean | null;
  };
  pagination: Pagination | null;
  messageValue: string;
  unreadCount: number;
  allChatsCount: number;
  selectedUserId: Chats.User["Id"] | null;
}

const houseUsersSlice = createSlice({
  name: "chats",

  initialState: {
    isLoading: false,
    chats: [],
    filters: {
      query: "",
      read: null,
    },
    pagination: null,
    messageValue: "",
    unreadCount: 0,
    allChatsCount: 0,
    selectedUserId: null,
  } as ChatsState,

  reducers: {
    toggleLoading: (state, action: PayloadAction<ChatsState["isLoading"]>) => {
      state.isLoading = action.payload;
    },

    setChats: (state, action: PayloadAction<ChatsState["chats"]>) => {
      state.chats = action.payload;
    },

    addChats: (state, action: PayloadAction<ChatsState["chats"]>) => {
      state.chats.push(...action.payload);
    },

    setQuery: (state, action: PayloadAction<ChatsState["filters"]["query"]>) => {
      state.filters.query = action.payload;
    },

    setReadFilter: (state, action: PayloadAction<ChatsState["filters"]["read"]>) => {
      state.filters.read = action.payload;
    },

    setMessageValue: (state, action: PayloadAction<ChatsState["messageValue"]>) => {
      state.messageValue = action.payload;
    },

    setUnreadCount: (state, action: PayloadAction<ChatsState["unreadCount"]>) => {
      state.unreadCount = action.payload;
    },

    setAllChatsCount: (state, action: PayloadAction<ChatsState["allChatsCount"]>) => {
      state.allChatsCount = action.payload;
    },

    setPagination: (state, action: PayloadAction<ChatsState["pagination"]>) => {
      state.pagination = action.payload;
    },

    setChatRead: (state, action: PayloadAction<Chats.Chat["Id"]>) => {
      const chat = state.chats.find((it) => it.Id === action.payload);
      if (chat && chat.UnreadCount > 0) {
        chat.UnreadCount = 0;
        state.unreadCount = state.unreadCount > 1 ? state.unreadCount - 1 : 0;
      }
    },

    updateChatLastMessage: (state, action: PayloadAction<{ chatId: Chats.Chat["Id"]; message: Chats.Message }>) => {
      const chat = state.chats.find((it) => it.Id === action.payload.chatId);
      if (chat) {
        chat.LastMessage = action.payload.message;
      }
    },

    showUserDetails: (state, action: PayloadAction<ChatsState["selectedUserId"]>) => {
      state.selectedUserId = action.payload;
    },
  },
});

export default houseUsersSlice.reducer;
export * from "./actions";
export const {
  toggleLoading,
  setChats,
  addChats,
  setQuery,
  setReadFilter,
  setMessageValue,
  setUnreadCount,
  setAllChatsCount,
  setPagination,
  setChatRead,
  updateChatLastMessage,
  showUserDetails,
} = houseUsersSlice.actions;
