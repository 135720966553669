import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface IBaseTableRowProps {
  children: React.ReactNode;
  className?: string;
  onClick?: React.EventHandler<any>;
  onMouseEnter?: React.EventHandler<any>;
  onMouseLeave?: React.EventHandler<any>;
  onMouseDown?: React.EventHandler<any>;
  onMouseUp?: React.EventHandler<any>;
}

const BaseTableRow: React.FC<IBaseTableRowProps> = ({
  children,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  onMouseUp,
}) => {
  return (
    <tr
      className={cx(className, { row: true, clickable: !!onClick })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDownCapture={onMouseDown}
      onMouseUpCapture={onMouseUp}
    >
      {children}
    </tr>
  );
};

export default BaseTableRow;
