import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { OCC } from "@/types";
import OccQuestionItem from "@/components/OCC/QuestionItem";
import QuestionDetailsModal from "@/components/OCC/QuestionDetails";
import styles from "./styles.module.scss";

const SntMyOCCItemQuestions: React.FC = () => {
  const occ = useSelector((state: RootState) => state.sntMyOcc.occ);

  const [selectedQuestion, setSelectedQuestion] = useState<OCC.Question | null>(null);
  const [isQuestionDetailsOpen, setQuestionDetailsOpen] = useState<boolean>(false);

  const openQuestionDetails = (question: OCC.Question) => {
    setSelectedQuestion(question);
    setQuestionDetailsOpen(true);
  };

  return (
    <>
      <div className={styles.questions}>
        {occ?.Questions.map((question) => (
          <OccQuestionItem key={question.Id} question={question} onSelect={() => openQuestionDetails(question)} />
        ))}
      </div>

      <QuestionDetailsModal
        selectedQuestion={selectedQuestion}
        isOpen={isQuestionDetailsOpen}
        onClose={() => setQuestionDetailsOpen(false)}
      />
    </>
  );
};

export default SntMyOCCItemQuestions;
