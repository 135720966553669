import { Chats } from "@/types";
import ChatMessage from "../ChatMessage";
import styles from "./styles.module.scss";

interface IChatMessagesGroupProps {
  group: Chats.MessagesGroupedByDate;
}

export const ChatMessagesGroup: React.FC<IChatMessagesGroupProps> = ({ group }) => {
  return (
    <div className={styles["b-chat-messages-group"]}>
      <div className={styles["b-chat-messages-group__date"]}>
        <span className="text--primary sf-text-semibold color--text-secondary">{group.date.formatDate()}</span>
      </div>
      <div className={styles["b-chat-messages-group__list"]}>
        {group.messages.map((message) => (
          <ChatMessage message={message} key={message.Id} />
        ))}
      </div>
    </div>
  );
};

export default ChatMessagesGroup;
