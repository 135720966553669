import { useState } from "react";
import { useSelector } from "react-redux";
import { OCCV2Order, Profile } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { sendOccToGisZhkh } from "@/app/store/slices/snt/myOcc";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import OccStatus from "@/components/OCC/Status";
import OccGisZhkhStatus from "@/components/OCC/GisZhkhStatus";
import styles from "./styles.module.scss";
import BaseButton from "@/components/base-button";

const SntMyOCCItemDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { company, isGisZhkhDisabled } = useSelector((state: RootState) => state.user);
  const occ = useSelector((state: RootState) => state.sntMyOcc.occ);

  const [gisZhkhLoading, setGisZhkhLoading] = useState<boolean>(false);

  async function sendToGisZhkh() {
    if (!occ) {
      return;
    }

    try {
      setGisZhkhLoading(true);
      await dispatch(sendOccToGisZhkh([occ.Order.Id])).unwrap();
      dispatch(
        showSnackbar({
          key: "send-to-gis-zhkh",
          body: "Собрание отправлено в ГИС ЖКХ",
          type: SnackbarType.SUCCESS,
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setGisZhkhLoading(true);
    }
  }

  return (
    <div className={styles.details}>
      <div>
        <span className="text--primary sf-text-regular">Номер собрания:</span>
        <span className="text--primary sf-text-semibold color--text-primary">{occ?.Order.Number}</span>
      </div>

      <div>
        <span className="text--primary sf-text-regular">Дата и время инициализации:</span>
        <span className="text--primary sf-text-semibold color--text-primary">
          {occ?.Order?.PublicationTime
            ? new Date(occ?.Order?.PublicationTime?.parseFromEpochSeconds()).formatDateTime()
            : occ?.Order?.PublicationDate}
        </span>
      </div>

      <div>
        <span className="text--primary sf-text-regular">Форма собрания:</span>
        <span className="text--primary sf-text-semibold color--text-primary">{occ?.Order.FormName}</span>
      </div>

      <div>
        <span className="text--primary sf-text-regular">Период голосования:</span>
        <span className="text--primary sf-text-semibold color--text-primary">
          {occ?.Order?.StartTime
            ? new Date(occ?.Order?.StartTime?.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy")
            : occ?.Order?.StartDate}{" "}
          -{" "}
          {occ?.Order?.EndTime
            ? new Date(occ?.Order?.EndTime?.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy")
            : occ?.Order?.EndDate}
        </span>
      </div>

      <div>
        <span className="text--primary sf-text-regular">Статус:</span>
        <span className="text--primary">
          {occ && <OccStatus statusId={occ?.Order.StatusId} statusName={occ?.Order.StatusName} />}
        </span>
      </div>

      {company?.Type === Profile.CompanyType.UK && (
        <>
          <div>
            <span className="text--primary sf-text-regular">Адрес:</span>
            <span className="text--primary">{occ?.Order.Address}</span>
          </div>
          {!isGisZhkhDisabled && (
            <div>
              <span className="text--primary sf-text-regular">Статус отправки в ГИС ЖКХ:</span>
              <span className="text--primary">
                <span className="flex middle" style={{ gap: "12px" }}>
                  {occ && (
                    <OccGisZhkhStatus
                      statusKey={occ?.Order.GisZhkhSent.Status}
                      statusName={occ?.Order.GisZhkhSent.StatusValue}
                    />
                  )}
                  {company.GisZhkh.HasAccess &&
                    occ &&
                    [OCCV2Order.GisZhkhStatus.Error, OCCV2Order.GisZhkhStatus.NotSent].includes(
                      occ.Order.GisZhkhSent.Status
                    ) && (
                      <BaseButton size="small" isLoading={gisZhkhLoading} onClick={sendToGisZhkh}>
                        Отправить
                      </BaseButton>
                    )}
                </span>
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SntMyOCCItemDetails;
