import classNames from "classnames/bind";
import { Chats } from "@/types";
import { useAppDispatch } from "@/app/store";
import { showUserDetails } from "@/app/store/slices/chats";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IChatMessageProps {
  message: Chats.Message;
}

export const ChatMessage: React.FC<IChatMessageProps> = ({ message }) => {
  const dispatch = useAppDispatch();

  function selectUserId() {
    if (!message.Author?.Id) {
      return;
    }

    dispatch(showUserDetails(message.Author.Id));
  }

  return (
    <div className={cx("b-chat-message", { "b-chat-message--me": message.IsAuthor })}>
      <div className={styles["b-chat-message__wrapper"]}>
        {!message.IsAuthor && (
          <div>
            <BaseUserAvatar avatar={message.Author?.Avatar} size="32px" onClick={selectUserId} />
          </div>
        )}
        <div className={styles["b-chat-message__card"]}>
          <div className={styles["b-chat-message__author"]}>
            <span className="table--middle sf-text-semibold color--text-primary">
              {message.IsAuthor && message.Author?.IsMainRole ? "Вы" : message.Author?.FullName}
            </span>
          </div>
          <div className={styles["b-chat-message__text"]}>
            <p className="text--primary color--text-primary sf-text-regular">{message.Text}</p>
          </div>
          <div className={styles["b-chat-message__datetime"]}>
            <span className="tags--small sf-text-regular color--text-secondary">
              {message.Timestamp.parseFromEpochSeconds().formatByPattern("HH:mm")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
