import { requiredIcon } from "@/consts/icons";
import BaseTooltip from "@/components/base-tooltip";
import { QuestionTooltip16Icon } from "@/consts/icons-v2";
import styles from "./styles.module.scss";

interface IBaseLabelProps {
  children: React.ReactNode;
  required?: boolean;
  className?: string;
  tooltipMessage?: string;
}

const BaseLabel = ({ children, required, className, tooltipMessage }: IBaseLabelProps) => {
  return (
    <div className={[styles.root, className].join(" ")}>
      <label className="sf-text-semibold color--text-primary">{children}</label>
      {required && requiredIcon}
      {tooltipMessage && <BaseTooltip title={tooltipMessage}>{QuestionTooltip16Icon()}</BaseTooltip>}
    </div>
  );
};

export default BaseLabel;
