import { isFuture, parse } from "date-fns";
import errorMessages from "@/helpers/errorMessages.json";
import { SntPassportBoard } from "./";
import { SntPassportEnums } from "../enums";
import {
  Dictionary,
  CheckboxField,
  DatepickerField,
  Form,
  FormFiled,
  FormValidator,
  MultipleSelectField,
  SelectField,
} from "@/types";

export namespace SntPassportBoardForms {
  export class DetailsForm extends Form {
    FoundationYear: FormFiled = new FormFiled("", true);
    RegistrationDate: DatepickerField = new DatepickerField({ isRequired: true });
    INN: FormFiled = new FormFiled("", true);
    MunicipalDistrict: FormFiled = new FormFiled("", true);
    Association: SelectField<SntPassportBoard.Association | null> = new SelectField(null, false); // isRequired when AssociationMembership=true
    AssociationMembership: CheckboxField = new CheckboxField(null, true);
    CadastralNumber: FormFiled = new FormFiled("", false, false, 100, 14);
    ChairmanName: FormFiled = new FormFiled("", true);
    ChairmanPhone: FormFiled = new FormFiled("", true);
    ChairmanEmail: FormFiled = new FormFiled("", true);

    constructor(details?: SntPassportBoard.Details, associations?: SntPassportBoard.Association[]) {
      super();
      if (details) {
        this.FoundationYear = new FormFiled(details.FoundationYear ? String(details.FoundationYear) : "", true);
        this.RegistrationDate = new DatepickerField({
          value: details.RegistrationDate ? parse(details.RegistrationDate, "dd.MM.yyyy", new Date()) : undefined,
          isRequired: true,
        });
        this.INN = new FormFiled(details.INN ?? "", true);
        this.MunicipalDistrict = new FormFiled(details.MunicipalDistrict ?? "", true);
        if (!SntPassportEnums.isNullOrUndefined(details.AssociationId) && associations?.length) {
          const association = associations.find((it) => it.Id === details.AssociationId);
          this.Association = new SelectField(association, false);
        }
        this.AssociationMembership = new CheckboxField(details.AssociationMembership ?? null, true);
        this.CadastralNumber = new FormFiled(details.CadastralNumber ?? "", false, false, 100, 14);
        this.ChairmanName = new FormFiled(details.ChairmanName ?? "", true);
        this.ChairmanPhone = new FormFiled(details.ChairmanPhone ?? "", true);
        this.ChairmanEmail = new FormFiled(details.ChairmanEmail ?? "", true);
      }
    }
  }

  export class QuantitativeDataForm extends Form {
    TotalArea: FormFiled = new FormFiled("", true);
    PublicArea: FormFiled = new FormFiled("", true);
    SectionsArea: FormFiled = new FormFiled("", true);
    TotalSectionsCount: FormFiled = new FormFiled("", true);
    UsedSectionsCount: FormFiled = new FormFiled("", true);
    UnUsedSectionsCount: FormFiled = new FormFiled("", true);
    RegisteredSectionsCount: FormFiled = new FormFiled("", true);
    ResidentialBuildingsCount: FormFiled = new FormFiled("");
    RegisteredCitizensCount: FormFiled = new FormFiled("");
    ContributionsAggregateAmount: FormFiled = new FormFiled("", true);

    constructor(data?: SntPassportBoard.QuantitativeData) {
      super();
      if (data) {
        this.TotalArea = new FormFiled(data.TotalArea?.toString() ?? "", true);
        this.PublicArea = new FormFiled(data.PublicArea?.toString() ?? "", true);
        this.SectionsArea = new FormFiled(data.SectionsArea?.toString() ?? "", true);
        this.TotalSectionsCount = new FormFiled(data.TotalSectionsCount?.toString() ?? "", true);
        this.UsedSectionsCount = new FormFiled(data.UsedSectionsCount?.toString() ?? "", true);
        this.UnUsedSectionsCount = new FormFiled(data.UnUsedSectionsCount?.toString() ?? "", true);
        this.RegisteredSectionsCount = new FormFiled(data.RegisteredSectionsCount?.toString() ?? "", true);
        this.ResidentialBuildingsCount = new FormFiled(data.ResidentialBuildingsCount?.toString() ?? "");
        this.RegisteredCitizensCount = new FormFiled(data.RegisteredCitizensCount?.toString() ?? "");
        this.ContributionsAggregateAmount = new FormFiled(data.ContributionsAggregateAmount?.toString() ?? "", true);
      }
    }
  }

  export class InfrastructureForm extends Form {
    ElectricitySupply: {
      IsAvailable: CheckboxField;
      Type: SelectField<Dictionary.AlternativeDictionaryItem<SntPassportEnums.ElectricitySupplyType> | null>;
      CTS: CheckboxField;
      PowerLineLength: FormFiled;
    } = {
      IsAvailable: new CheckboxField(null, true),
      Type: new SelectField(null), // isRequired when IsAvailable=true
      CTS: new CheckboxField(), // isRequired when IsAvailable=true
      PowerLineLength: new FormFiled(""), // isRequired when IsAvailable=true
    };

    WaterSupply: {
      IsAvailable: CheckboxField;
      Type: MultipleSelectField<Dictionary.AlternativeDictionaryItem<SntPassportEnums.WaterSupplyType> | null>;
      License: FormFiled;
      WaterWellCount: FormFiled;
      SeasonalFireWaterSupply: CheckboxField;
      YearRoundFireWaterSupply: CheckboxField;
    } = {
      IsAvailable: new CheckboxField(null, true),
      Type: new MultipleSelectField([]), // isRequired when IsAvailable=true
      License: new FormFiled(""),
      WaterWellCount: new FormFiled(""),
      SeasonalFireWaterSupply: new CheckboxField(null, true),
      YearRoundFireWaterSupply: new CheckboxField(null, true),
    };

    GasSupply: {
      IsAvailable: CheckboxField;
      CommissioningYear: FormFiled;
      ConnectedBuildingsCount: FormFiled;
    } = {
      IsAvailable: new CheckboxField(null, true),
      CommissioningYear: new FormFiled(""), // isRequired when IsAvailable=true
      ConnectedBuildingsCount: new FormFiled(""), // isRequired when IsAvailable=true
    };

    TKO: {
      WasteCollectionSitesCount: FormFiled;
      HoppersCount: FormFiled;
      ContainersCount: FormFiled;
    } = {
      WasteCollectionSitesCount: new FormFiled("", true),
      HoppersCount: new FormFiled("", true),
      ContainersCount: new FormFiled("", true),
    };

    SectionOuterRoad: {
      CommissioningYear: FormFiled;
      SurfaceWorn: FormFiled;
      PublicTransportStopsDistance: FormFiled;
    } = {
      CommissioningYear: new FormFiled(""),
      SurfaceWorn: new FormFiled(""),
      PublicTransportStopsDistance: new FormFiled(""),
    };

    SectionInnerRoad: {
      Length: FormFiled;
      AlleyWidth: FormFiled;
      SurfaceType: SelectField<Dictionary.AlternativeDictionaryItem<SntPassportEnums.RoadSurfaceType> | null>;
      SurfaceTypeKeyOther: FormFiled;
    } = {
      Length: new FormFiled("", true),
      AlleyWidth: new FormFiled("", true),
      SurfaceType: new SelectField(null, true),
      SurfaceTypeKeyOther: new FormFiled(""),
    };

    Additional: {
      FiberOptic: CheckboxField;
      SecurityRoom: CheckboxField;
      AccessControlSystems: CheckboxField;
      VideoSurveillanceSystems: CheckboxField;
      Fence: CheckboxField;
    } = {
      FiberOptic: new CheckboxField(null, true),
      SecurityRoom: new CheckboxField(null, true),
      AccessControlSystems: new CheckboxField(),
      VideoSurveillanceSystems: new CheckboxField(),
      Fence: new CheckboxField(),
    };

    constructor(
      data?: SntPassportBoard.Infrastructure,
      electricitySupplyTypes?: Dictionary.AlternativeDictionaryItem<SntPassportEnums.ElectricitySupplyType>[],
      waterSupplyTypes?: Dictionary.AlternativeDictionaryItem<SntPassportEnums.WaterSupplyType>[],
      roadSurfaceTypes?: Dictionary.AlternativeDictionaryItem<SntPassportEnums.RoadSurfaceType>[]
    ) {
      super();
      if (data) {
        const electricitySupply = electricitySupplyTypes?.find((it) => it.Key === data?.ElectricitySupply?.TypeKey);
        const waterSupply = waterSupplyTypes?.filter((it) => data?.WaterSupply?.TypeKey?.includes(it.Key));
        const roadSurface = roadSurfaceTypes?.find((it) => it.Key === data?.SectionInnerRoad?.SurfaceTypeKey);

        this.ElectricitySupply = {
          IsAvailable: new CheckboxField(data?.ElectricitySupply?.IsAvailable, true),
          Type: new SelectField(electricitySupply ?? null),
          CTS: new CheckboxField(data?.ElectricitySupply?.CTS),
          PowerLineLength: new FormFiled(data?.ElectricitySupply?.PowerLineLength?.toString() ?? ""),
        };

        this.WaterSupply = {
          IsAvailable: new CheckboxField(data.WaterSupply?.IsAvailable, true),
          Type: new MultipleSelectField(waterSupply ?? []),
          License: new FormFiled(data.WaterSupply?.License ?? ""),
          WaterWellCount: new FormFiled(data.WaterSupply?.WaterWellCount?.toString() ?? ""),
          SeasonalFireWaterSupply: new CheckboxField(data.WaterSupply?.SeasonalFireWaterSupply, true),
          YearRoundFireWaterSupply: new CheckboxField(data.WaterSupply?.YearRoundFireWaterSupply, true),
        };

        this.GasSupply = {
          IsAvailable: new CheckboxField(data.GasSupply?.IsAvailable, true),
          CommissioningYear: new FormFiled(data.GasSupply?.CommissioningYear?.toString() ?? ""),
          ConnectedBuildingsCount: new FormFiled(data.GasSupply?.ConnectedBuildingsCount?.toString() ?? ""),
        };

        this.TKO = {
          WasteCollectionSitesCount: new FormFiled(data.TKO?.WasteCollectionSitesCount?.toString() ?? "", true),
          HoppersCount: new FormFiled(data.TKO?.HoppersCount?.toString() ?? "", true),
          ContainersCount: new FormFiled(data.TKO?.ContainersCount?.toString() ?? "", true),
        };

        this.SectionOuterRoad = {
          CommissioningYear: new FormFiled(data.SectionOuterRoad?.CommissioningYear?.toString() ?? ""),
          SurfaceWorn: new FormFiled(data.SectionOuterRoad?.SurfaceWorn?.toString() ?? ""),
          PublicTransportStopsDistance: new FormFiled(
            data.SectionOuterRoad?.PublicTransportStopsDistance?.toString() ?? ""
          ),
        };

        this.SectionInnerRoad = {
          Length: new FormFiled(data.SectionInnerRoad?.Length?.toString() ?? "", true),
          AlleyWidth: new FormFiled(data.SectionInnerRoad?.AlleyWidth?.toString() ?? "", true),
          SurfaceType: new SelectField(roadSurface ?? null, true),
          SurfaceTypeKeyOther: new FormFiled(data.SectionInnerRoad?.SurfaceTypeKeyOther?.toString() ?? ""),
        };

        this.Additional = {
          FiberOptic: new CheckboxField(data.Additional?.FiberOptic, true),
          SecurityRoom: new CheckboxField(data.Additional?.SecurityRoom, true),
          AccessControlSystems: new CheckboxField(data.Additional?.AccessControlSystems),
          VideoSurveillanceSystems: new CheckboxField(data.Additional?.VideoSurveillanceSystems),
          Fence: new CheckboxField(data.Additional?.Fence),
        };
      }
    }
  }

  export function saveRequestBody(
    details: DetailsForm,
    quantitativeData: QuantitativeDataForm,
    infrastructure: InfrastructureForm
  ): SntPassportBoard.BoardData {
    return {
      Details: {
        FoundationYear: details.FoundationYear.value ? parseInt(details.FoundationYear.value) : null,
        RegistrationDate: details.RegistrationDate.value ? details.RegistrationDate.value.formatDate() : null,
        INN: details.INN.value ? details.INN.value : undefined,
        MunicipalDistrict: details.MunicipalDistrict.value,
        AssociationMembership: details.AssociationMembership.value ?? null,
        AssociationId: details.AssociationMembership.value ? details.Association.value?.Id ?? null : null,
        CadastralNumber: details.CadastralNumber.value ? details.CadastralNumber.value : null,
        ChairmanName: details.ChairmanName.value ? details.ChairmanName.value : undefined,
        ChairmanPhone: details.ChairmanPhone.value ? details.ChairmanPhone.value : null,
        ChairmanEmail: details.ChairmanEmail.value ? details.ChairmanEmail.value : null,
      },

      QuantitativeData: {
        TotalArea: quantitativeData.TotalArea.value ? parseFloat(quantitativeData.TotalArea.value) : null,
        PublicArea: quantitativeData.PublicArea.value ? parseFloat(quantitativeData.PublicArea.value) : null,
        SectionsArea: quantitativeData.SectionsArea.value ? parseFloat(quantitativeData.SectionsArea.value) : null,
        TotalSectionsCount: quantitativeData.TotalSectionsCount.value
          ? parseInt(quantitativeData.TotalSectionsCount.value)
          : null,
        UsedSectionsCount: quantitativeData.UsedSectionsCount.value
          ? parseInt(quantitativeData.UsedSectionsCount.value)
          : null,
        UnUsedSectionsCount: quantitativeData.UnUsedSectionsCount.value
          ? parseInt(quantitativeData.UnUsedSectionsCount.value)
          : null,
        RegisteredSectionsCount: quantitativeData.RegisteredSectionsCount.value
          ? parseInt(quantitativeData.RegisteredSectionsCount.value)
          : null,
        ResidentialBuildingsCount: quantitativeData.ResidentialBuildingsCount.value
          ? parseInt(quantitativeData.ResidentialBuildingsCount.value)
          : null,
        RegisteredCitizensCount: quantitativeData.RegisteredCitizensCount.value
          ? parseInt(quantitativeData.RegisteredCitizensCount.value)
          : null,
        ContributionsAggregateAmount: quantitativeData.ContributionsAggregateAmount.value
          ? parseFloat(quantitativeData.ContributionsAggregateAmount.value)
          : null,
      },

      Infrastructure: {
        ElectricitySupply: {
          IsAvailable: infrastructure.ElectricitySupply.IsAvailable.value ?? null,
          TypeKey: infrastructure.ElectricitySupply.Type.value?.Key ?? null,
          CTS: infrastructure.ElectricitySupply.CTS.value ?? null,
          PowerLineLength:
            infrastructure.ElectricitySupply.PowerLineLength.value.length > 0
              ? infrastructure.ElectricitySupply.PowerLineLength.value
              : null,
        },
        WaterSupply: {
          IsAvailable: infrastructure.WaterSupply.IsAvailable.value ?? null,
          TypeKey: infrastructure.WaterSupply.Type.value
            ? infrastructure.WaterSupply.Type.value.map((it) => (it as any).Key)
            : [],
          License:
            infrastructure.WaterSupply.License.value.length > 0 ? infrastructure.WaterSupply.License.value : null,
          WaterWellCount: infrastructure.WaterSupply.WaterWellCount.value
            ? parseInt(infrastructure.WaterSupply.WaterWellCount.value)
            : null,
          SeasonalFireWaterSupply: infrastructure.WaterSupply.SeasonalFireWaterSupply.value ?? null,
          YearRoundFireWaterSupply: infrastructure.WaterSupply.YearRoundFireWaterSupply.value ?? null,
        },
        GasSupply: {
          IsAvailable: infrastructure.GasSupply.IsAvailable.value ?? null,
          CommissioningYear: infrastructure.GasSupply.CommissioningYear.value
            ? parseInt(infrastructure.GasSupply.CommissioningYear.value)
            : null,
          ConnectedBuildingsCount: infrastructure.GasSupply.ConnectedBuildingsCount.value
            ? parseInt(infrastructure.GasSupply.ConnectedBuildingsCount.value)
            : null,
        },
        TKO: {
          WasteCollectionSitesCount: infrastructure.TKO.WasteCollectionSitesCount.value
            ? parseInt(infrastructure.TKO.WasteCollectionSitesCount.value)
            : null,
          HoppersCount: infrastructure.TKO.HoppersCount.value ? parseInt(infrastructure.TKO.HoppersCount.value) : null,
          ContainersCount: infrastructure.TKO.ContainersCount.value
            ? parseInt(infrastructure.TKO.ContainersCount.value)
            : null,
        },
        SectionOuterRoad: {
          CommissioningYear: infrastructure.SectionOuterRoad.CommissioningYear.value
            ? parseInt(infrastructure.SectionOuterRoad.CommissioningYear.value)
            : null,
          SurfaceWorn: infrastructure.SectionOuterRoad.SurfaceWorn.value
            ? parseInt(infrastructure.SectionOuterRoad.SurfaceWorn.value)
            : null,
          PublicTransportStopsDistance: infrastructure.SectionOuterRoad.PublicTransportStopsDistance.value
            ? parseInt(infrastructure.SectionOuterRoad.PublicTransportStopsDistance.value)
            : null,
        },
        SectionInnerRoad: {
          Length: infrastructure.SectionInnerRoad.Length.value
            ? parseInt(infrastructure.SectionInnerRoad.Length.value)
            : null,
          AlleyWidth: infrastructure.SectionInnerRoad.AlleyWidth.value
            ? parseInt(infrastructure.SectionInnerRoad.AlleyWidth.value)
            : null,
          SurfaceTypeKey: infrastructure.SectionInnerRoad.SurfaceType.value?.Key ?? null,
          SurfaceTypeKeyOther:
            infrastructure.SectionInnerRoad.SurfaceType.value?.Key === SntPassportEnums.RoadSurfaceType.Other
              ? infrastructure.SectionInnerRoad.SurfaceTypeKeyOther.value
              : null,
        },
        Additional: {
          FiberOptic: infrastructure.Additional.FiberOptic.value ?? null,
          SecurityRoom: infrastructure.Additional.SecurityRoom.value ?? null,
          AccessControlSystems: infrastructure.Additional.AccessControlSystems.value ?? null,
          VideoSurveillanceSystems: infrastructure.Additional.VideoSurveillanceSystems.value ?? null,
          Fence: infrastructure.Additional.Fence.value ?? null,
        },
      },
    };
  }

  export function isAllRequiredDetailsFilled(details: SntPassportBoard.Details): boolean {
    return (
      !!details.FoundationYear &&
      !!details.RegistrationDate &&
      !!details.INN &&
      !!details.MunicipalDistrict &&
      !SntPassportEnums.isNullOrUndefined(details.AssociationMembership) &&
      ((details.AssociationMembership && !!details.AssociationId) || details.AssociationMembership === false) &&
      !!details.ChairmanName &&
      !!details.ChairmanPhone &&
      !!details.ChairmanEmail
    );
  }

  export function isAllRequiredQuantitativeDataFilled(data: SntPassportBoard.QuantitativeData): boolean {
    return (
      !SntPassportEnums.isNullOrUndefined(data.TotalArea) &&
      !SntPassportEnums.isNullOrUndefined(data.PublicArea) &&
      !SntPassportEnums.isNullOrUndefined(data.SectionsArea) &&
      !SntPassportEnums.isNullOrUndefined(data.TotalSectionsCount) &&
      !SntPassportEnums.isNullOrUndefined(data.UsedSectionsCount) &&
      !SntPassportEnums.isNullOrUndefined(data.UnUsedSectionsCount) &&
      !SntPassportEnums.isNullOrUndefined(data.RegisteredSectionsCount) &&
      !SntPassportEnums.isNullOrUndefined(data.ContributionsAggregateAmount)
    );
  }

  export function isAllRequiredInfrastructureFilled(data: SntPassportBoard.Infrastructure): boolean {
    return (
      // ElectricitySupply
      !SntPassportEnums.isNullOrUndefined(data.ElectricitySupply?.IsAvailable) &&
      ((data.ElectricitySupply?.IsAvailable &&
        !SntPassportEnums.isNullOrUndefined(data.ElectricitySupply?.TypeKey) &&
        !SntPassportEnums.isNullOrUndefined(data.ElectricitySupply?.CTS) &&
        !SntPassportEnums.isNullOrUndefined(data.ElectricitySupply?.PowerLineLength)) ||
        !data.ElectricitySupply?.IsAvailable) &&
      // WaterSupply
      !SntPassportEnums.isNullOrUndefined(data.WaterSupply?.IsAvailable) &&
      ((data.WaterSupply?.IsAvailable && !SntPassportEnums.isNullOrUndefined(data.WaterSupply?.TypeKey)) ||
        !data.WaterSupply?.IsAvailable) &&
      !SntPassportEnums.isNullOrUndefined(data.WaterSupply?.SeasonalFireWaterSupply) &&
      !SntPassportEnums.isNullOrUndefined(data.WaterSupply?.YearRoundFireWaterSupply) &&
      // GasSupply
      !SntPassportEnums.isNullOrUndefined(data.GasSupply?.IsAvailable) &&
      ((data.GasSupply?.IsAvailable &&
        !SntPassportEnums.isNullOrUndefined(data.GasSupply?.CommissioningYear) &&
        !SntPassportEnums.isNullOrUndefined(data.GasSupply?.ConnectedBuildingsCount)) ||
        !data.GasSupply?.IsAvailable) &&
      // TKO
      !SntPassportEnums.isNullOrUndefined(data.TKO?.WasteCollectionSitesCount) &&
      !SntPassportEnums.isNullOrUndefined(data.TKO?.HoppersCount) &&
      !SntPassportEnums.isNullOrUndefined(data.TKO?.ContainersCount) &&
      // SectionInnerRoad
      !SntPassportEnums.isNullOrUndefined(data.SectionInnerRoad?.Length) &&
      !SntPassportEnums.isNullOrUndefined(data.SectionInnerRoad?.AlleyWidth) &&
      !SntPassportEnums.isNullOrUndefined(data.SectionInnerRoad?.SurfaceTypeKey) &&
      ((data.SectionInnerRoad?.SurfaceTypeKey === SntPassportEnums.RoadSurfaceType.Other &&
        !SntPassportEnums.isNullOrUndefined(data.SectionInnerRoad.SurfaceTypeKeyOther)) ||
        data.SectionInnerRoad?.SurfaceTypeKey !== SntPassportEnums.RoadSurfaceType.Other) &&
      // Additional
      !SntPassportEnums.isNullOrUndefined(data.Additional?.FiberOptic) &&
      !SntPassportEnums.isNullOrUndefined(data.Additional?.SecurityRoom)
    );
  }

  export class DetailsFormValidator {
    static validateFoundationYear(foundationYear: DetailsForm["FoundationYear"], isRequired: boolean = false) {
      if (isRequired && !foundationYear.value) return errorMessages.empty;

      if (foundationYear.value.length > 0 && foundationYear.value.length < 4) {
        return "Необходимо ввести четырехзначное число";
      }

      const year = parseInt(foundationYear.value);
      if (foundationYear.value.length > 0 && year && (year < 1900 || isFuture(new Date().setFullYear(year)))) {
        return `Год основания должен быть между 1900 и ${new Date().getFullYear()}`;
      }

      return "";
    }

    static validateRegistrationDate(registrationDate: DetailsForm["RegistrationDate"], isRequired: boolean = false) {
      if (isRequired && registrationDate.value === null) return errorMessages.empty;

      return "";
    }

    static validateCadastralNumber(field: DetailsForm["CadastralNumber"]) {
      const _value = field.value.trim();

      if (!field.isRequired && _value.length === 0) return "";

      if (!_value) {
        return errorMessages.empty;
      }
      const regex = /^[0-9;:]+$/;
      if ((field.isRequired || (!field.isRequired && _value.length > 0)) && !regex.test(_value)) {
        return errorMessages.cadastralNumber.invalid.default;
      }

      if (
        _value.length > 0 &&
        ((field.minlength && _value.length < field.minlength) || (field.maxlength && _value.length > field.maxlength))
      ) {
        return `Необходимо ввести от ${field.minlength} до ${field.maxlength} символов`;
      }

      return "";
    }

    public static isInvalid(form: DetailsForm, needCheckRequired: boolean = false) {
      const errors = {
        FoundationYear: this.validateFoundationYear(
          form.FoundationYear,
          needCheckRequired && form.FoundationYear.isRequired
        ),
        RegistrationDate: this.validateRegistrationDate(
          form.RegistrationDate,
          needCheckRequired && form.RegistrationDate.isRequired
        ),
        Association: FormValidator.getSelectFieldErrorMessage(
          form.Association.value,
          needCheckRequired && form.AssociationMembership.value === true // isRequired when AssociationMembership=true
        ),
        AssociationMembership: FormValidator.getCheckboxFieldErrorMessage(
          form.AssociationMembership.value,
          needCheckRequired && form.AssociationMembership.isRequired
        ),
        CadastralNumber: this.validateCadastralNumber(form.CadastralNumber),
        ChairmanPhone: FormValidator.getPhoneErrorMessage(
          form.ChairmanPhone.value.trim(),
          needCheckRequired && form.ChairmanPhone.isRequired
        ),
        ChairmanEmail: FormValidator.getEmailErrorMessage(
          form.ChairmanEmail.value.trim(),
          needCheckRequired && form.ChairmanEmail.isRequired
        ),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export class QuantitativeDataFormValidator {
    static validateQuantitativeData(
      value: FormFiled["value"],
      isRequired: boolean = false,
      minLength: number,
      maxLength: number,
      limitsErrorMessage: string
    ) {
      if (isRequired && value.length === 0) return errorMessages.empty;

      if (value.length > 0 && (value.length < minLength || value.length > maxLength)) {
        return limitsErrorMessage;
      }

      return "";
    }

    static validateResidentialBuildingsCount(
      residentialBuildingsCount: QuantitativeDataForm["ResidentialBuildingsCount"],
      isRequired: boolean = false,
      sectionsCount?: QuantitativeDataForm["TotalSectionsCount"]
    ) {
      if (isRequired && residentialBuildingsCount.value.length === 0) return errorMessages.empty;

      const count = parseInt(residentialBuildingsCount.value);
      if (
        residentialBuildingsCount.value.length > 0 &&
        sectionsCount?.value &&
        count > parseInt(sectionsCount?.value)
      ) {
        return "Значение не должно превышать общее количество участков";
      }

      return "";
    }

    public static isInvalid(form: QuantitativeDataForm, needCheckRequired: boolean = false) {
      const errors = {
        TotalArea: this.validateQuantitativeData(
          form.TotalArea.value,
          needCheckRequired && form.TotalArea.isRequired,
          3,
          8,
          "Необходимо ввести от трех до восьми символов"
        ),
        PublicArea: this.validateQuantitativeData(
          form.PublicArea.value,
          needCheckRequired && form.PublicArea.isRequired,
          3,
          8,
          "Необходимо ввести от трех до восьми символов"
        ),
        SectionsArea: this.validateQuantitativeData(
          form.SectionsArea.value,
          needCheckRequired && form.SectionsArea.isRequired,
          3,
          8,
          "Необходимо ввести от трех до восьми символов"
        ),
        TotalSectionsCount: FormValidator.getFieldErrorMessage(
          form.TotalSectionsCount.value.trim(),
          needCheckRequired && form.TotalSectionsCount.isRequired
        ),
        UsedSectionsCount: this.validateQuantitativeData(
          form.UsedSectionsCount.value,
          needCheckRequired && form.UsedSectionsCount.isRequired,
          1,
          5,
          "Необходимо ввести от одного до пяти символов"
        ),
        UnUsedSectionsCount: this.validateQuantitativeData(
          form.UnUsedSectionsCount.value,
          needCheckRequired && form.UnUsedSectionsCount.isRequired,
          1,
          5,
          "Необходимо ввести от одного до пяти символов"
        ),
        RegisteredSectionsCount: this.validateQuantitativeData(
          form.RegisteredSectionsCount.value,
          needCheckRequired && form.RegisteredSectionsCount.isRequired,
          1,
          5,
          "Необходимо ввести от одного до пяти символов"
        ),
        ResidentialBuildingsCount: this.validateResidentialBuildingsCount(
          form.ResidentialBuildingsCount,
          needCheckRequired && form.ResidentialBuildingsCount.isRequired,
          form.TotalSectionsCount
        ),
        RegisteredCitizensCount: this.validateQuantitativeData(
          form.RegisteredCitizensCount.value,
          needCheckRequired && form.RegisteredCitizensCount.isRequired,
          1,
          5,
          "Необходимо ввести от одного до пяти символов"
        ),
        ContributionsAggregateAmount: FormValidator.getFieldErrorMessage(
          form.ContributionsAggregateAmount.value.trim(),
          needCheckRequired && form.ContributionsAggregateAmount.isRequired
        ),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export class InfrastructureFormValidator {
    static validateQuantitativeData(
      value: FormFiled["value"],
      isRequired: boolean = false,
      minLength: number,
      maxLength: number,
      limitsErrorMessage: string
    ) {
      if (isRequired && value.length === 0) return errorMessages.empty;

      if (value.length > 0 && (value.length < minLength || value.length > maxLength)) {
        return limitsErrorMessage;
      }

      return "";
    }

    static validateConnectedBuildingsCount(
      connectedBuildingsCount: InfrastructureForm["GasSupply"]["ConnectedBuildingsCount"],
      isRequired: boolean = false,
      sectionsCount?: QuantitativeDataForm["TotalSectionsCount"]
    ) {
      if (isRequired && connectedBuildingsCount.value.length === 0) return errorMessages.empty;

      const count = parseInt(connectedBuildingsCount.value);
      if (connectedBuildingsCount.value.length > 0 && sectionsCount?.value && count > parseInt(sectionsCount?.value)) {
        return "Значение не должно превышать общее количество участков";
      }

      return "";
    }

    static validateYear(yearField: FormFiled, isRequired: boolean = false) {
      if (isRequired && !yearField.value) return errorMessages.empty;

      if (yearField.value.length > 0 && yearField.value.length < 4) {
        return "Необходимо ввести четырехзначное число";
      }

      const year = parseInt(yearField.value);
      if (yearField.value.length > 0 && year && (year < 1900 || isFuture(new Date().setFullYear(year)))) {
        return `Должен быть между 1900 и ${new Date().getFullYear()}`;
      }

      return "";
    }

    static validateSurfaceWorn(
      surfaceWorn: InfrastructureForm["SectionOuterRoad"]["SurfaceWorn"],
      isRequired: boolean = false
    ) {
      if (isRequired && !surfaceWorn.value) return errorMessages.empty;

      const value = parseInt(surfaceWorn.value);
      if ((surfaceWorn.value.length > 0 && value < 1) || value > 100) {
        return "Необходимо ввести значение в диапазоне от 1 до 100";
      }

      return "";
    }

    static validateSurfaceTypeKeyOther(
      field: InfrastructureForm["SectionInnerRoad"]["SurfaceTypeKeyOther"],
      isRequired: boolean
    ) {
      const errorMessage = FormValidator.getFieldErrorMessage(field.value.trim(), isRequired);

      const regex = /^[\p{Script=Cyrillic} ]+$/u;
      if ((isRequired || (!isRequired && field.value.trim().length > 0)) && !regex.test(field.value.trim())) {
        return "Неверный формат. Покрытие должно содержать только кириллицу";
      }

      return errorMessage;
    }

    public static isInvalid(
      form: InfrastructureForm,
      needCheckRequired: boolean = false,
      sectionsCount: QuantitativeDataForm["TotalSectionsCount"]
    ) {
      const errors = {
        ElectricitySupply: {
          IsAvailable: FormValidator.getCheckboxFieldErrorMessage(
            form.ElectricitySupply.IsAvailable.value,
            needCheckRequired && form.ElectricitySupply.IsAvailable.isRequired
          ),
          Type: FormValidator.getSelectFieldErrorMessage(
            form.ElectricitySupply.Type.value,
            needCheckRequired && form.ElectricitySupply.IsAvailable.value === true // isRequired when IsAvailable=true
          ),
          CTS: FormValidator.getCheckboxFieldErrorMessage(
            form.ElectricitySupply.CTS.value,
            needCheckRequired && form.ElectricitySupply.IsAvailable.value === true // isRequired when IsAvailable=true
          ),
          PowerLineLength: this.validateQuantitativeData(
            form.ElectricitySupply.PowerLineLength.value,
            needCheckRequired && form.ElectricitySupply.IsAvailable.value === true, // isRequired when IsAvailable=true
            2,
            5,
            "Необходимо ввести от двух до пяти символов"
          ),
        },

        WaterSupply: {
          IsAvailable: FormValidator.getCheckboxFieldErrorMessage(
            form.WaterSupply.IsAvailable.value,
            needCheckRequired && form.WaterSupply.IsAvailable.isRequired
          ),
          Type: FormValidator.getSelectFieldErrorMessage(
            form.WaterSupply.Type.value,
            needCheckRequired && form.WaterSupply.IsAvailable.value === true // isRequired when IsAvailable=true
          ),
          License: FormValidator.getFieldErrorMessage(
            form.WaterSupply.License.value.trim(),
            needCheckRequired && form.WaterSupply.License.isRequired
          ),
          WaterWellCount: this.validateQuantitativeData(
            form.WaterSupply.WaterWellCount.value,
            needCheckRequired && form.WaterSupply.WaterWellCount.isRequired,
            1,
            2,
            "Необходимо ввести от одного до двух символов"
          ),
          SeasonalFireWaterSupply: FormValidator.getCheckboxFieldErrorMessage(
            form.WaterSupply.SeasonalFireWaterSupply.value,
            needCheckRequired && form.WaterSupply.SeasonalFireWaterSupply.isRequired
          ),
          YearRoundFireWaterSupply: FormValidator.getCheckboxFieldErrorMessage(
            form.WaterSupply.YearRoundFireWaterSupply.value,
            needCheckRequired && form.WaterSupply.YearRoundFireWaterSupply.isRequired
          ),
        },

        GasSupply: {
          IsAvailable: FormValidator.getCheckboxFieldErrorMessage(
            form.GasSupply.IsAvailable.value,
            needCheckRequired && form.GasSupply.IsAvailable.isRequired
          ),
          CommissioningYear: this.validateYear(
            form.GasSupply.CommissioningYear,
            needCheckRequired && form.GasSupply.IsAvailable.value === true // isRequired when IsAvailable=true
          ),
          ConnectedBuildingsCount: this.validateConnectedBuildingsCount(
            form.GasSupply.ConnectedBuildingsCount,
            needCheckRequired && form.GasSupply.IsAvailable.value === true, // isRequired when IsAvailable=true
            sectionsCount
          ),
        },

        TKO: {
          WasteCollectionSitesCount: this.validateQuantitativeData(
            form.TKO.WasteCollectionSitesCount.value,
            needCheckRequired && form.TKO.WasteCollectionSitesCount.isRequired,
            1,
            2,
            "Необходимо ввести от двух до пяти символов"
          ),
          HoppersCount: this.validateQuantitativeData(
            form.TKO.HoppersCount.value,
            needCheckRequired && form.TKO.HoppersCount.isRequired,
            1,
            2,
            "Необходимо ввести от двух до пяти символов"
          ),
          ContainersCount: this.validateQuantitativeData(
            form.TKO.ContainersCount.value,
            needCheckRequired && form.TKO.ContainersCount.isRequired,
            1,
            2,
            "Необходимо ввести от двух до пяти символов"
          ),
        },

        SectionOuterRoad: {
          CommissioningYear: this.validateYear(
            form.SectionOuterRoad.CommissioningYear,
            needCheckRequired && form.SectionOuterRoad.CommissioningYear.isRequired
          ),
          SurfaceWorn: this.validateSurfaceWorn(
            form.SectionOuterRoad.SurfaceWorn,
            needCheckRequired && form.SectionOuterRoad.SurfaceWorn.isRequired
          ),
          PublicTransportStopsDistance: this.validateQuantitativeData(
            form.SectionOuterRoad.PublicTransportStopsDistance.value,
            needCheckRequired && form.SectionOuterRoad.PublicTransportStopsDistance.isRequired,
            1,
            6,
            "Необходимо ввести от одного до шести символов"
          ),
        },

        SectionInnerRoad: {
          Length: this.validateQuantitativeData(
            form.SectionInnerRoad.Length.value,
            needCheckRequired && form.SectionInnerRoad.Length.isRequired,
            1,
            6,
            "Необходимо ввести от одного до шести символов"
          ),
          AlleyWidth: this.validateQuantitativeData(
            form.SectionInnerRoad.AlleyWidth.value,
            needCheckRequired && form.SectionInnerRoad.AlleyWidth.isRequired,
            1,
            2,
            "Необходимо ввести от одного до двух символов"
          ),
          SurfaceType: FormValidator.getSelectFieldErrorMessage(
            form.SectionInnerRoad.SurfaceType.value,
            needCheckRequired && form.SectionInnerRoad.SurfaceType.isRequired
          ),
          SurfaceTypeKeyOther: this.validateSurfaceTypeKeyOther(
            form.SectionInnerRoad.SurfaceTypeKeyOther,
            needCheckRequired && form.SectionInnerRoad.SurfaceType.value?.Key === SntPassportEnums.RoadSurfaceType.Other
          ),
        },

        Additional: {
          FiberOptic: FormValidator.getCheckboxFieldErrorMessage(
            form.Additional.FiberOptic.value,
            needCheckRequired && form.Additional.FiberOptic.isRequired
          ),
          SecurityRoom: FormValidator.getCheckboxFieldErrorMessage(
            form.Additional.SecurityRoom.value,
            needCheckRequired && form.Additional.SecurityRoom.isRequired
          ),
          AccessControlSystems: FormValidator.getCheckboxFieldErrorMessage(
            form.Additional.AccessControlSystems.value,
            needCheckRequired && form.Additional.AccessControlSystems.isRequired
          ),
          VideoSurveillanceSystems: FormValidator.getCheckboxFieldErrorMessage(
            form.Additional.VideoSurveillanceSystems.value,
            needCheckRequired && form.Additional.VideoSurveillanceSystems.isRequired
          ),
          Fence: FormValidator.getCheckboxFieldErrorMessage(
            form.Additional.Fence.value,
            needCheckRequired && form.Additional.Fence.isRequired
          ),
        },
      };

      if (Object.values(errors).some((it) => Object.values(it).some((y) => !!y))) return errors;

      return false;
    }
  }
}
