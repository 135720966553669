import { useSelector } from "react-redux";
import { Access } from "@/types";
import { sntRoleIcon, ukRoleIcon } from "@/consts/icons";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import styles from "./styles.module.scss";

interface IUserCardProps {
  fio: string;
  phone: string;
  avatar: string;
  role?: Access.RoleKey;
}

const UserCard: React.FC<IUserCardProps> = ({ fio, phone, avatar, role }) => {
  const isUK = useSelector(isCompanyTypeUK);

  return (
    <div className={styles.card}>
      <div>
        <div className={styles.avatar}>
          <div>
            <img src={avatar} alt="" />
          </div>
          {role && <span className={styles.role}>{isUK ? ukRoleIcon : sntRoleIcon}</span>}
        </div>
        <div className={styles.user_name}>
          <h4 className="headline-h4 sf-text-bold">{fio}</h4>
          <p className="text--primary sf-text-regular color--text-primary">{phone.formatPhone()}</p>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
