import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { successIcon } from "@/consts/icons";
import { paths } from "@/consts/routes";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleGisZhkhCanSyncSuccessModal } from "@/app/store/slices/snt/myOcc";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";

const SyncSuccessModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isOpen = useSelector((state: RootState) => state.sntMyOcc.gisZhkh.isSuccessModalOpen);

  function onClose() {
    dispatch(toggleGisZhkhCanSyncSuccessModal(false));
  }

  function navigateToOccList() {
    navigate(paths.myOcc);
    onClose();
  }

  return (
    <BaseModal isOpen={isOpen} minWidth="520px" maxWidth="520px">
      <BaseModalHeader
        variant="info-iconed"
        iconBgColor="#CFF3E1"
        icon={successIcon}
        title="Начался процесс отправки итогов в ГИС ЖКХ"
        onClose={onClose}
      />

      <BaseModalContent>
        <div>
          <p className="text--primary sf-text-regular color--text-primary">
            Вы можете отслеживать данный процесс в разделе «Мои собрания»
          </p>
        </div>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton onClick={navigateToOccList}>Мои собрания</BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default SyncSuccessModal;
