import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Access, House } from "@/types";
import { AccessSettingsService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import { updateUserRole } from "@/app/store/slices/houseUsers";
import { BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import SelectAddressSubmodal, { SelectAddressDefaultButton } from "@/components/SelectAddressSubmodal";
import BaseButton from "@/components/base-button";
import UserCard from "@/components/UserDetails/Card";
import styles from "../styles.module.scss";

interface ISetAccessModalProps {
  left: number;
  top: number;
  onClose: () => void;
}

const SetAccessModal: React.FC<ISetAccessModalProps> = ({ top, left, onClose }) => {
  const dispatch = useAppDispatch();

  const isUK = useSelector(isCompanyTypeUK);
  const company = useSelector((state: RootState) => state.user.company);
  const roles = useSelector((state: RootState) => state.access.roles);
  const user = useSelector((state: RootState) => state.houseUsers.userDetailsModal.user);

  const containerRef = useRef<HTMLDivElement>(null);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [role, setRole] = useState<Access.Role | null>(null);
  const [isSelectAddressSubmodalShown, setSelectAddressSubmodalShown] = useState<boolean>(false);
  const [houseIds, setHouseIds] = useState<Array<House.Item["Id"]>>();
  const [houseIdsError, setHouseIdsError] = useState<string>("");
  const [submodalPosition, setSubmodalPosition] = useState<{ left: number | string }>({ left: 0 });

  const isHeadRole = useMemo(
    () => user?.Role?.Type && [Access.RoleKey.Chairman, Access.RoleKey.Representative].includes(user?.Role?.Type),
    [user]
  );

  function updateRole(value: Access.Role["Key"] | null) {
    const _role = roles.find((it) => it.Key === value);
    if (_role) {
      setRole(_role);
    }
  }

  function clearRole(event: React.SyntheticEvent) {
    event.stopPropagation();
    setRole(null);
    setHouseIds([]);
  }

  function closeModal() {
    onClose();
  }

  async function saveRoleForUser() {
    if (!user?.Role && !role) {
      setErrorMessage("Выберите роль");
      return;
    }

    setErrorMessage("");
    setHouseIdsError("");
    setLoading(true);
    try {
      if (!user) {
        throw Error("no role");
      }

      if (user?.Role && !role) {
        await AccessSettingsService.removeUserRole(user.Role.Id);
        await dispatch(updateUserRole({ userId: user.UserId, role: null }));
      } else if (role) {
        if (isUK && user?.Role) {
          await AccessSettingsService.updateRoleHousesForUser({ UserId: user.UserId, HouseIds: houseIds ?? [] });
        } else {
          await AccessSettingsService.setRoleForUser({
            Phone: user.Phone,
            Role: role.Key,
            HouseIds: isUK ? houseIds : undefined,
          });
        }
        const { data } = await AccessSettingsService.getAccessUsersList();

        const access = data.Data.find((it) => it.User.Id === user.UserId);
        if (access) {
          await dispatch(updateUserRole({ userId: user.UserId, role: { Id: access.Id, Type: role.Key } }));
        }
      }
      closeModal();
    } catch (error: any) {
      const errorsData = error?.response?.data?.Data?.Errors;
      if (errorsData) {
        Object.keys(errorsData).forEach((key: any) => {
          const message = Array.isArray(errorsData[key]) ? errorsData[key][0] : errorsData[key];
          switch (key) {
            case "HouseIds": {
              setHouseIdsError(message);
              break;
            }

            default: {
              setErrorMessage(message);
              break;
            }
          }
        });
      } else if (error?.response?.data?.Data?.Message) {
        setErrorMessage(error?.response?.data?.Data?.Message);
      } else {
        setErrorMessage("Неизвестная ошибка. Попробуйте позже еще раз");
      }
    } finally {
      setLoading(false);
    }
  }

  async function fetchUserRole() {
    if (!user) {
      return;
    }

    try {
      const { data } = await AccessSettingsService.getUserRole(user.UserId);
      setHouseIds(data.Data.Houses ?? []);
      setRole(data.Data.Role);
    } catch (error) {}
  }

  function showSelectAddressSubmodal() {
    const rect = containerRef.current?.getBoundingClientRect();
    setSubmodalPosition({
      left: rect ? `${rect.left - rect.width * (2 / 3)}px` : 0,
    });

    setTimeout(() => {
      setSelectAddressSubmodalShown(true);
    }, 100);
  }

  useEffect(() => {
    if (user?.Role) {
      updateRole(user.Role.Type);
      fetchUserRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div ref={containerRef} className={styles.set_access_modal} style={{ top: `${top}px`, left: `${left}px` }}>
      <BaseModalHeader title="Настроить доступ" onClose={closeModal} />

      <BaseModalContent>
        <div className={styles.set_access_content}>
          <div className={styles.user}>
            {user && <UserCard fio={user.Fio} phone={user.Phone} avatar={user.Avatar} role={user.Role?.Type} />}
          </div>
          <div>
            <BaseDropdown
              value={role?.Key ?? null}
              display={role?.Value}
              variant="formfield"
              isSelectable={true}
              onSelect={updateRole}
              label="Выбранная роль"
              placeholder="Выберите роль"
              errorMessage={errorMessage}
              disabled={isHeadRole || !!user?.Role}
              customToggler={
                role && !isHeadRole ? (
                  <div className={styles.role_select}>
                    <span>{role?.Value}</span>

                    <svg
                      className={styles.clear_btn}
                      onClick={clearRole}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.988 0C12.396 0 15.976 3.58 15.976 7.988C15.976 12.396 12.396 15.976 7.988 15.976C3.58 15.976 0 12.396 0 7.988C0 3.58 3.58 0 7.988 0ZM7.988 6.565L6.173 4.75C5.781 4.358 5.143 4.358 4.75 4.75C4.358 5.143 4.358 5.781 4.75 6.173L6.565 7.988L4.757 9.796C4.365 10.188 4.365 10.826 4.757 11.219C5.15 11.611 5.788 11.611 6.18 11.219L7.988 9.411L9.803 11.226C10.195 11.618 10.833 11.618 11.226 11.226C11.618 10.833 11.618 10.195 11.226 9.803L9.411 7.988L11.219 6.18C11.611 5.788 11.611 5.15 11.219 4.757C10.826 4.365 10.188 4.365 9.796 4.757L7.988 6.565Z"
                        fill="#818C99"
                      />
                    </svg>
                  </div>
                ) : undefined
              }
            >
              {roles.map((role) => (
                <BaseDropdownMenuItem key={role.Key} value={role.Key}>
                  <span className="sf-text-medium">{role?.Value}</span>
                </BaseDropdownMenuItem>
              ))}
            </BaseDropdown>
          </div>

          {isUK && (
            <>
              <SelectAddressDefaultButton
                disabled={isHeadRole}
                errorMessage={houseIdsError}
                onClick={showSelectAddressSubmodal}
              >
                Доступ
                {houseIds?.length === company?.Addresses?.length
                  ? " ко всем адресам"
                  : houseIds?.length === 1
                  ? ` к ${houseIds?.length} адресу`
                  : ` к ${houseIds?.length ?? 0} адресам`}
              </SelectAddressDefaultButton>

              {isSelectAddressSubmodalShown && (
                <SelectAddressSubmodal
                  position={submodalPosition}
                  isOpen={isSelectAddressSubmodalShown}
                  selected={houseIds ?? []}
                  onClose={() => setSelectAddressSubmodalShown(false)}
                  setSelected={(ids) => setHouseIds(ids)}
                />
              )}
            </>
          )}
        </div>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={closeModal}>
          Отменить
        </BaseButton>
        <BaseButton
          disabled={isHeadRole || (!isUK && user?.Role?.Type === role?.Key)}
          isLoading={isLoading}
          onClick={saveRoleForUser}
        >
          Сохранить
        </BaseButton>
      </BaseModalActions>
    </div>
  );
};

export default SetAccessModal;
