import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { House } from "@/types";
import { RootState } from "@/app/store";
import { Search16Icon } from "@/consts/icons-v2";
import BaseButton from "@/components/base-button";
import { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import AddressCheckbox from "./AddressCheckbox";
import SelectAddressDefaultButton from "./DefaultButton";
import styles from "./styles.module.scss";

interface ISelectAddressSubmodalProps {
  isOpen: boolean;
  selected: Array<House.Item["Id"]>;
  position: {
    left?: CSSProperties["left"];
    bottom?: CSSProperties["bottom"];
    top?: CSSProperties["top"];
    right?: CSSProperties["right"];
  };
  onClose: () => void;
  setSelected: (values: Array<House.Item["Id"]>) => void;
}

const SelectAddressSubmodal: React.FC<ISelectAddressSubmodalProps> = ({
  isOpen,
  selected,
  position,
  onClose,
  setSelected,
}) => {
  const addresses = useSelector((state: RootState) => state.user.company?.Addresses);

  const [query, setQuery] = useState<string>("");
  const [localSelected, setLocalSelected] = useState<Array<House.Item["Id"]>>([]);

  const addressesSelectable = useMemo(
    () =>
      addresses
        ? addresses
            .filter((it) => it.Address.toLowerCase().includes(query.toLowerCase()))
            .map((it) => {
              return { ...it, checked: localSelected?.includes(it.Id) || false };
            })
        : [],
    [addresses, localSelected, query]
  );

  const isAllChecked: boolean = useMemo(
    () => addressesSelectable.length > 0 && addressesSelectable.every((it) => it.checked),
    [addressesSelectable]
  );

  function handleCheckbox(id: number, isCheck: boolean) {
    const ids = new Set(localSelected);
    if (isCheck) ids.add(id);
    else ids.delete(id);

    setLocalSelected(Array.from(ids));
  }

  function handleAllCheckBox() {
    if (isAllChecked) {
      setLocalSelected([]);
    } else {
      setLocalSelected(addressesSelectable ? addressesSelectable.map((it) => it.Id) : []);
    }
  }

  function handleClose() {
    onClose();
  }

  function handleSubmit() {
    setSelected(localSelected);
    handleClose();
  }

  useEffect(() => {
    if (isOpen) {
      setLocalSelected(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div
      className={styles["b-select-addresses-submodal"]}
      style={{
        left: position.left,
        bottom: position.bottom,
        top: position.top,
        right: position.right,
      }}
    >
      <BaseModalHeader title="Выберите адреса" onClose={handleClose} />

      <BaseModalContent sx={{ padding: "0 0 8px" }}>
        <div className={styles["b-select-addresses-submodal__search"]}>
          <div className={styles["b-select-addresses-submodal__search_field"]}>
            {Search16Icon()}
            <input
              className="sf-text-regular text--primary color--text-primary"
              type="text"
              placeholder="Поиск по адресам"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>

        <div className={styles["b-select-addresses-submodal__addresses"]}>
          <AddressCheckbox onChange={handleAllCheckBox} all label="Выделить все" checked={isAllChecked} />
          {addressesSelectable.map((address) => (
            <AddressCheckbox
              key={address.Id}
              onChange={(isCheck: boolean) => handleCheckbox(address.Id, isCheck)}
              label={address.Address}
              checked={address.checked}
            />
          ))}
        </div>
      </BaseModalContent>

      <BaseModalActions className="between middle">
        <div>
          {localSelected.length !== addressesSelectable.length && (
            <p className="sf-text-semibold text--primary color--text-primary">
              Выбраны: <span className="color--primary">{localSelected.length}</span>
            </p>
          )}
        </div>
        <div className="flex middle" style={{ gap: "8px" }}>
          <BaseButton variant="secondary" onClick={handleClose}>
            Отменить
          </BaseButton>
          <BaseButton onClick={handleSubmit}>Сохранить</BaseButton>
        </div>
      </BaseModalActions>
    </div>
  );
};

export { SelectAddressDefaultButton };
export default SelectAddressSubmodal;
