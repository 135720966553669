import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

interface IPageHeaderProps {
  title: string | JSX.Element;
  defaultLink?: string;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
  onLinkClick?: () => void;
}

const PageHeader: React.FC<IPageHeaderProps> = ({ title, defaultLink, children, onLinkClick }) => {
  const navigate = useNavigate();

  function handleOnLinkClick(event: React.SyntheticEvent) {
    event.preventDefault();

    if (onLinkClick) {
      onLinkClick();
    } else if (window?.history?.length > 2) {
      navigate(-1);
    } else {
      navigate(defaultLink ?? "/");
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.header_title}>
        <h1 className="title-h1">{title}</h1>

        {children && <div className={styles.header_actions}>{children}</div>}
      </div>

      {defaultLink && (
        <div className="flex middle">
          <span className={styles.back_link} onClick={handleOnLinkClick} role="link">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.20711 2.79289C7.59763 3.18342 7.59763 3.81658 7.20711 4.20711L4.41421 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H4.41421L7.20711 11.7929C7.59763 12.1834 7.59763 12.8166 7.20711 13.2071C6.81658 13.5976 6.18342 13.5976 5.79289 13.2071L1.29289 8.70711C0.902369 8.31658 0.902369 7.68342 1.29289 7.29289L5.79289 2.79289C6.18342 2.40237 6.81658 2.40237 7.20711 2.79289Z"
                fill="#226DFF"
              />
            </svg>
            <span className="sf-text-regular text--primary color--primary">Вернуться назад</span>
          </span>
        </div>
      )}
    </div>
  );
};

export default PageHeader;
