import styles from "./styles.module.scss";
import classNames from "classnames/bind";

interface IBaseContainerProps {
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  className?: string;
  defaultTopOffset?: boolean;
  defaultHorizontalOffset?: boolean;
}

const cx = classNames.bind(styles);

const BaseContainer: React.FC<IBaseContainerProps> = ({
  children,
  className,
  defaultTopOffset,
  defaultHorizontalOffset,
}) => {
  return (
    <div
      className={cx(className, {
        container: true,
        "default-top-offset": defaultTopOffset,
        "default-horizontal-offset": defaultHorizontalOffset,
      })}
    >
      {children}
    </div>
  );
};

export default BaseContainer;
