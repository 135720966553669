import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "@/app/store";
import { useParams } from "react-router";
import { Chats, Pagination } from "@/types";
import { ChatsService } from "@/services/v2";
import { setChatRead, updateChatLastMessage } from "@/app/store/slices/chats";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import ChatInput from "../../components/ChatInput";
import ChatMessagesGroup from "../../components/ChatMessagesGroup";
import Spinner from "@/components/spinner";
import styles from "./styles.module.scss";

const AppealsChatByUserId: React.FC = () => {
  const urlParams = useParams();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [groupedMessages, setGroupedMessages] = useState<Chats.MessagesGroupedByDate[]>([]);
  const [pagination, setPagination] = useState<Pagination>();

  const { measureRef, isIntersecting, observer, hasMore } = useInfiniteScroll({ pagination });

  const chatId: Chats.Chat["Id"] | null = useMemo(() => {
    return urlParams.chatId ? parseInt(urlParams.chatId) : null;
  }, [urlParams.chatId]);

  async function sendMessage(message: string) {
    if (!chatId) {
      return;
    }

    const { data } = await ChatsService.sendMessage(chatId, message);
    const newstate = await Chats.messageGroupsMapper([data.Data], [...groupedMessages], true);
    setGroupedMessages(newstate);
    dispatch(updateChatLastMessage({ chatId, message: data.Data }));
  }

  async function fetchMessages(Page?: number) {
    if (!chatId) {
      throw new Error("");
    }

    try {
      const { data } = await ChatsService.getChatMessages(chatId, Page);
      const newstate = await Chats.messageGroupsMapper(data.Data, Page ? [...groupedMessages] : []);
      setGroupedMessages(newstate);

      setPagination(data.Page);

      if (observer && isIntersecting && data.Page.Index === data.Page.Count) {
        observer.disconnect();
      }

      if (chatId !== null) {
        dispatch(setChatRead(chatId));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMessages((pagination?.Index ?? 0) + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  useEffect(() => {
    setLoading(true);
    setPagination(undefined);
    fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.chatId]);

  return (
    <div className={styles["b-appeals-chat"]}>
      <div className={styles["b-appeals-chat__stack"]}>
        {isLoading ? (
          <div className={styles["b-appeals-chat__loading"]}>
            <Spinner size={36} />
          </div>
        ) : groupedMessages.length > 0 ? (
          <>
            {groupedMessages.map((group) => (
              <ChatMessagesGroup group={group} key={`${group.date.formatDate()}-${group.messages.length}`} />
            ))}

            {hasMore && (
              <div ref={measureRef} className="flex center" style={{ padding: "12px 0" }}>
                <Spinner color="#226dff" size={36} />
              </div>
            )}
          </>
        ) : null}
      </div>
      <div className={styles["b-appeals-chat__input"]}>
        <ChatInput onSend={sendMessage} />
      </div>
    </div>
  );
};

export default AppealsChatByUserId;
