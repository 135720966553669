import { forwardRef } from "react";
import { BaseModal, BaseModalHeader, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

interface IUserDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
}

const UserDetailsModal = forwardRef<HTMLDivElement, IUserDetailsModalProps>((props, ref) => {
  const { isOpen, onClose, children } = props;
  return (
    <BaseModal isOpen={isOpen} maxWidth="520px" minWidth="520px">
      <div ref={ref}>
        <div className={styles.wrapper}>
          <BaseModalHeader title="Общие данные" subtitle="Расскажем вам про пользователя" onClose={onClose} />

          {children}

          <BaseModalActions>
            <BaseButton variant="secondary" onClick={onClose} style={{ width: "100%" }}>
              Закрыть
            </BaseButton>
          </BaseModalActions>
        </div>
      </div>
    </BaseModal>
  );
});

export default UserDetailsModal;
