import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { addDays, isBefore, isAfter } from "date-fns";
import { paths } from "@/consts/routes";
import { arrowRightWhiteIcon } from "@/consts/icons";
import { DatepickerField, Dictionary, OCCV2Order, OCCV2OrderForms } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { activeAddress } from "@/app/store/slices/user";
import { selectStatusById } from "@/app/store/slices/dictionary";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { setOccId, createOcc, getOccOrder, saveLocalOccChanges, reset } from "@/app/store/slices/createOcc";
import BaseInput from "@/components/base-input";
import DatepickerInput from "@/components/base-input/datepicker";
import BaseTextarea from "@/components/base-textarea";
import BaseButton from "@/components/base-button";
import BaseTooltip from "@/components/base-tooltip";
import SelectAddressDropdown from "@/components/SelectAddressDropdown";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BottomBar from "@/features/CreateOccNew/OccItem/BottomBar";
import CreateOccItemPageSubheader from "@/features/CreateOccNew/OccItem/PageSubheader";
import styles from "./styles.module.scss";

const minDaysBeforeStart = 10;
const votingDurationDaysMin = 7;
const votingDurationDaysMax = 60;

const UKCreateOccCreatingStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = useParams();

  const company = useSelector((state: RootState) => state.user.company);
  const { occId, order, isOccCreateLoading } = useSelector((state: RootState) => state.createOcc);
  const OCCTypes = useSelector((state: RootState) => state.dictionary.types.list);
  const OCCForms = useSelector((state: RootState) => state.dictionary.forms.list);
  const status = useSelector(selectStatusById(order?.StatusId));
  const _activeAddress = useSelector(activeAddress);

  const [form, setForm] = useState<OCCV2OrderForms.CreateForm>(
    new OCCV2OrderForms.CreateForm({ address: _activeAddress })
  );

  function rebuildForm(occ?: OCCV2Order.ItemFull) {
    setForm(
      new OCCV2OrderForms.CreateForm({
        occ,
        address: _activeAddress,
        types: OCCTypes,
        forms: OCCForms,
        minDaysBeforeStart,
        votingDaysMin: votingDurationDaysMin,
        votingDaysMax: votingDurationDaysMax,
        addressesList: company?.Addresses,
      })
    );
  }

  function renderDayContents(day: number, date: Date | undefined, field: DatepickerField, tooltip: string) {
    if (
      (date && field.maxDate && isAfter(date, field.maxDate)) ||
      (date && field.minDate && isBefore(date, field.minDate))
    ) {
      return (
        <BaseTooltip title={tooltip}>
          <span>{day}</span>
        </BaseTooltip>
      );
    }

    return day;
  }

  function updateFormFieldValue(key: keyof OCCV2OrderForms.CreateForm, value: unknown) {
    const field = form[key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      setForm((prevState) => ({
        ...prevState,
        [key]: { ...field, value },
      }));
    }
  }

  function setPublicationTimestamp(date: Date) {
    if (!form.PublicationTimestamp.value && date?.getHours() === 0 && date?.getMinutes() === 0) {
      date.setHours(9);
    }

    const startMinDate = addDays(date, minDaysBeforeStart);
    const startDate =
      form.StartTimestamp.value && isAfter(form.StartTimestamp.value, startMinDate)
        ? form.StartTimestamp.value
        : startMinDate;
    const endMinDate = addDays(startDate, votingDurationDaysMin);
    const endMaxDate = addDays(startDate, votingDurationDaysMax);
    const endDate =
      form.EndTimestamp.value &&
      isAfter(form.EndTimestamp.value, endMinDate) &&
      isBefore(form.EndTimestamp.value, endMaxDate)
        ? form.EndTimestamp.value
        : endMinDate;
    setForm((prevState) => ({
      ...prevState,
      PublicationTimestamp: { ...form.PublicationTimestamp, value: date },
      StartTimestamp: { ...form.StartTimestamp, value: startDate, minDate: startMinDate },
      EndTimestamp: { ...form.EndTimestamp, value: endDate, minDate: endMinDate, maxDate: endMaxDate },
    }));
  }

  function setStartTimestamp(date: Date) {
    if (!form.StartTimestamp.value && date?.getHours() === 0 && date?.getMinutes() === 0) {
      date?.setHours(9);
    }

    const endMinDate = addDays(date, votingDurationDaysMin);
    const endMaxDate = addDays(date, votingDurationDaysMax);
    const endDate =
      form.EndTimestamp.value &&
      isAfter(form.EndTimestamp.value, endMinDate) &&
      isBefore(form.EndTimestamp.value, endMaxDate)
        ? form.EndTimestamp.value
        : endMinDate;
    setForm((prevState) => ({
      ...prevState,
      StartTimestamp: { ...form.StartTimestamp, value: date },
      EndTimestamp: { ...form.EndTimestamp, value: endDate, minDate: endMinDate, maxDate: endMaxDate },
    }));
  }

  function setEndTimestamp(date: Date) {
    if (!form.EndTimestamp.value && date?.getHours() === 0 && date?.getMinutes() === 0) {
      date?.setHours(9);
    }

    setForm((prevState) => ({
      ...prevState,
      EndTimestamp: { ...form.EndTimestamp, value: date },
    }));
  }

  function handleError(payload: any) {
    if (payload.Errors) {
      Object.keys(payload.Errors).forEach((key: any) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        switch (key) {
          case "Number": {
            setForm((prevState) => ({
              ...prevState,
              Number: { ...form.Number, error: message },
            }));
            break;
          }

          case "TypeId": {
            setForm((prevState) => ({
              ...prevState,
              Type: {
                ...form.Type,
                error: message,
              },
            }));
            break;
          }

          case "FormId": {
            setForm((prevState) => ({
              ...prevState,
              Form: {
                ...form.Form,
                error: message,
              },
            }));
            break;
          }

          case "ProcedureForMakingWrittenDecisions": {
            setForm((prevState) => ({
              ...prevState,
              ProcedureForMakingWrittenDecisions: { ...form.ProcedureForMakingWrittenDecisions, error: message },
            }));
            break;
          }

          case "ProcedureForFamiliarizationWithMaterialsOfOCC": {
            setForm((prevState) => ({
              ...prevState,
              ProcedureForFamiliarizationWithMaterialsOfOCC: {
                ...form.ProcedureForFamiliarizationWithMaterialsOfOCC,
                error: message,
              },
            }));
            break;
          }

          case "StartTimestamp": {
            setForm((prevState) => ({
              ...prevState,
              StartTimestamp: {
                ...form.StartTimestamp,
                error: message,
              },
            }));
            break;
          }

          case "EndTimestamp": {
            setForm((prevState) => ({
              ...prevState,
              EndTimestamp: {
                ...form.EndTimestamp,
                error: message,
              },
            }));
            break;
          }

          case "PublicationTimestamp": {
            setForm((prevState) => ({
              ...prevState,
              PublicationTimestamp: {
                ...form.PublicationTimestamp,
                error: message,
              },
            }));
            break;
          }

          default: {
            setForm((prevState) => ({ ...prevState, error: message }));
            dispatch(showSnackbar({ key: "create-occ-error", body: message, type: SnackbarType.ERROR }));
            break;
          }
        }
      });
    } else {
      setForm((prevState) => ({ ...prevState, error: payload.Message }));
      dispatch(showSnackbar({ key: "create-occ-error", body: payload.Message, type: SnackbarType.ERROR }));
    }
  }

  function isFormValid() {
    const validator = OCCV2OrderForms.CreateFormValidator.isInvalid(form);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        Number: {
          ...form.Number,
          error: validator.Number,
        },
        ProcedureForMakingWrittenDecisions: {
          ...form.ProcedureForMakingWrittenDecisions,
          error: validator.ProcedureForMakingWrittenDecisions,
        },
        ProcedureForFamiliarizationWithMaterialsOfOCC: {
          ...form.ProcedureForFamiliarizationWithMaterialsOfOCC,
          error: validator.ProcedureForFamiliarizationWithMaterialsOfOCC,
        },
        StartTimestamp: {
          ...form.StartTimestamp,
          error: validator.StartTimestamp,
        },
        EndTimestamp: {
          ...form.EndTimestamp,
          error: validator.EndTimestamp,
        },
        PublicationTimestamp: {
          ...form.PublicationTimestamp,
          error: validator.PublicationTimestamp,
        },
      }));
    }
    return !validator;
  }

  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });
    setForm((prevState) => ({
      ...prevState,
      error: "",
    }));

    if (!isFormValid()) {
      return;
    }

    try {
      let occId = order?.Id ?? "new";
      if (status && status.key === Dictionary.OCCStatus.OccPlanned) {
        await dispatch(
          saveLocalOccChanges({
            occ: {
              FiasId: form.FiasId.value?.FiasId ?? _activeAddress?.FiasId,
              OccId: order ? order.Id : undefined,
              TypeId: form.Type?.value?.id ?? 0,
              FormId: form.Form?.value?.id ?? 0,
              Number: form.Number.value,
              ProcedureForMakingWrittenDecisions: form.ProcedureForMakingWrittenDecisions.value,
              ProcedureForFamiliarizationWithMaterialsOfOCC: form.ProcedureForFamiliarizationWithMaterialsOfOCC.value,
              StartTimestamp: form.StartTimestamp?.value?.parseToEpochSeconds() ?? 0,
              EndTimestamp: form.EndTimestamp?.value?.parseToEpochSeconds() ?? 0,
              PublicationTimestamp: form.PublicationTimestamp?.value?.parseToEpochSeconds() ?? 0,
            },
          })
        ).unwrap();
      } else {
        occId = await dispatch(
          createOcc({
            FiasId: form.FiasId.value?.FiasId ?? _activeAddress?.FiasId,
            OccId: order ? order.Id : undefined,
            TypeId: form.Type?.value?.id ?? 0,
            FormId: form.Form?.value?.id ?? 0,
            Number: form.Number.value,
            ProcedureForMakingWrittenDecisions: form.ProcedureForMakingWrittenDecisions.value,
            ProcedureForFamiliarizationWithMaterialsOfOCC: form.ProcedureForFamiliarizationWithMaterialsOfOCC.value,
            StartTimestamp: form.StartTimestamp?.value?.parseToEpochSeconds() ?? 0,
            EndTimestamp: form.EndTimestamp?.value?.parseToEpochSeconds() ?? 0,
            PublicationTimestamp: form.PublicationTimestamp?.value?.parseToEpochSeconds() ?? 0,
          })
        ).unwrap();
      }

      navigate(
        paths
          .uk()
          .fullPath()
          .createOCC.item.author(occId as any),
        { state: location.state }
      );
    } catch (error: any) {
      handleError(error.Data);
    }
  }

  async function fetch() {
    try {
      if (!occId && urlParams?.occId) {
        dispatch(setOccId(parseInt(urlParams?.occId)));
      }

      const data = await dispatch(getOccOrder()).unwrap();
      rebuildForm(data as any);
    } catch (error) {
      console.error(error);
      rebuildForm();
    }
  }

  useEffect(() => {
    if (urlParams?.occId !== "new") {
      fetch();
    } else {
      dispatch(reset()).unwrap();
      rebuildForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveChangesDebounced = debounce((_form: OCCV2OrderForms.CreateForm) => {
    dispatch(
      saveLocalOccChanges({
        occ: {
          OccId: order ? order.Id : undefined,
          TypeId: _form.Type?.value?.id ?? 0,
          FormId: _form.Form?.value?.id ?? 0,
          Number: _form.Number.value,
          ProcedureForMakingWrittenDecisions: _form.ProcedureForMakingWrittenDecisions.value,
          ProcedureForFamiliarizationWithMaterialsOfOCC: _form.ProcedureForFamiliarizationWithMaterialsOfOCC.value,
          StartTimestamp: _form.StartTimestamp?.value?.parseToEpochSeconds() ?? 0,
          EndTimestamp: _form.EndTimestamp?.value?.parseToEpochSeconds() ?? 0,
          PublicationTimestamp: _form.PublicationTimestamp?.value?.parseToEpochSeconds() ?? 0,
        },
      })
    ).unwrap();
  }, 1000);

  useEffect(() => {
    if (status && status.key === Dictionary.OCCStatus.OccPlanned) {
      saveChangesDebounced(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, form]);

  const isSubmitDisabled: boolean = useMemo(
    () =>
      form.Type.value === null ||
      form.Form.value === null ||
      form.Number.value.length === 0 ||
      form.ProcedureForMakingWrittenDecisions.value.length === 0 ||
      form.ProcedureForFamiliarizationWithMaterialsOfOCC.value.length === 0 ||
      form.StartTimestamp.value === null ||
      form.EndTimestamp.value === null ||
      form.PublicationTimestamp.value === null,
    [form]
  );

  return (
    <div>
      <CreateOccItemPageSubheader>
        <h3 className="headline-h3 sf-bold color--text-primary">Заполните общие сведения о собрании</h3>
      </CreateOccItemPageSubheader>

      <form className={styles["b-create-order-form"]} onSubmit={handleOnSubmit}>
        <div className={styles["col-12"]}>
          <div className={styles["col-6"]}>
            <SelectAddressDropdown
              formfield={true}
              disabled={true}
              label="Адрес дома"
              tooltipMessage="Из списка выберите адрес дома, для которого хотите создать собрание."
              custom={{
                value: form.FiasId.value,
                onSelect: (value) => {
                  updateFormFieldValue("FiasId", value);
                },
              }}
            />
          </div>
        </div>

        <div className={styles["col-12"]}>
          <div className={styles["col-6"]}>
            <BaseInput
              value={form.Number.value}
              label="Номер собрания"
              required={form.Number.isRequired}
              tooltipMessage="Например: ЛО-2022/1-2481. Данный номер будет присвоен итоговому протоколу собрания"
              placeholder="Например: (01)"
              errorMessage={form.Number.error}
              onChange={(value) => updateFormFieldValue("Number", value)}
            />
          </div>
        </div>

        <div className={styles["col-6"]}>
          <BaseDropdown
            value={form.Form?.value?.id ?? null}
            display={form.Form?.value?.name}
            isSelectable={true}
            label="Форма собрания"
            placeholder="Форма собрания"
            variant="formfield"
            required={form.Form.isRequired}
            onSelect={(value) => {
              const selected = OCCForms.find((it) => it.id === value);
              if (selected) updateFormFieldValue("Form", selected);
            }}
          >
            {OCCForms.map((type) => (
              <BaseDropdownMenuItem key={type.id} value={type.id}>
                <span className="sf-text-medium">{type.name}</span>
              </BaseDropdownMenuItem>
            ))}
          </BaseDropdown>
        </div>

        <div className={styles["col-6"]}>
          <BaseDropdown
            value={form.Type?.value?.id ?? null}
            display={form.Type?.value?.name}
            isSelectable={true}
            label="Вид собрания"
            placeholder="Вид собрания"
            variant="formfield"
            required={form.Type.isRequired}
            onSelect={(value) => {
              const selected = OCCTypes.find((it) => it.id === value);
              if (selected) updateFormFieldValue("Type", selected);
            }}
          >
            {OCCTypes.map((type) => (
              <BaseDropdownMenuItem key={type.id} value={type.id}>
                <span className="sf-text-medium">{type.name}</span>
              </BaseDropdownMenuItem>
            ))}
          </BaseDropdown>
        </div>

        <div className={styles["b-create-order-form"]}>
          <div className={styles["col-3"]}>
            <DatepickerInput
              required={form.PublicationTimestamp.isRequired}
              minDate={form.PublicationTimestamp.minDate}
              maxDate={form.PublicationTimestamp.maxDate}
              label="Дата публикации сообщения"
              tooltipMessage="Выберите в календаре дату отправки уведомления собственникам для информирования о проведении собрания"
              value={form.PublicationTimestamp.value}
              errorMessage={form.PublicationTimestamp.error}
              placeholder="--.--.----"
              showTimeInput
              onChange={setPublicationTimestamp}
            />
          </div>

          <div className={styles["col-3"]}>
            <DatepickerInput
              required={form.StartTimestamp.isRequired}
              minDate={form.StartTimestamp.minDate}
              maxDate={form.StartTimestamp.maxDate}
              label="Cтарт приема решений"
              tooltipMessage="Выберите в календаре дату старта голосования. Напоминаем, что с даты публикации сообщения до начала собрания должно пройти 10 полных дней"
              value={form.StartTimestamp.value}
              errorMessage={form.StartTimestamp.error}
              onChange={setStartTimestamp}
              placeholder="--.--.----"
              showTimeInput
              renderDayContents={(day, date) =>
                renderDayContents(day, date, form.StartTimestamp, "Должно пройти 10 дней с даты публикации сообщения")
              }
            />
          </div>

          <div className={styles["col-3"]}>
            <DatepickerInput
              required={form.EndTimestamp.isRequired}
              minDate={form.EndTimestamp.minDate}
              maxDate={form.EndTimestamp.maxDate}
              label="Окончание приема решений"
              tooltipMessage="Выберите в календаре дату окончания голосования. Напоминаем, что продолжительность голосования не менее 7 и не более 60 дней"
              value={form.EndTimestamp.value}
              onChange={setEndTimestamp}
              errorMessage={form.EndTimestamp.error}
              placeholder="--.--.----"
              showTimeInput
              renderDayContents={(day, date) =>
                renderDayContents(
                  day,
                  date,
                  form.EndTimestamp,
                  "Продолжительность голосования не менее 7 и не более 60 дней с даты и времени начала проведения голосования"
                )
              }
            />
          </div>
        </div>

        <div className={styles["col-12"]}>
          <div className={styles["col-6"]}>
            <BaseTextarea
              minRows={1}
              value={form.ProcedureForMakingWrittenDecisions.value}
              label="Порядок приема письменных решений"
              required={form.ProcedureForMakingWrittenDecisions.isRequired}
              tooltipMessage="Укажите адрес и время приема собственников, по которому они смогут проголосовать, если нет возможности проголосовать в электронной форме"
              placeholder={"Например: Адрес: г.Казань, ул. Московская, д.1 \nДата: 22.10 - 27.10 (10-15ч)"}
              errorMessage={form.ProcedureForMakingWrittenDecisions.error}
              onChange={(value) => updateFormFieldValue("ProcedureForMakingWrittenDecisions", value)}
            />
          </div>
        </div>

        <div className={styles["col-12"]}>
          <div className={styles["col-6"]}>
            <BaseTextarea
              minRows={1}
              value={form.ProcedureForFamiliarizationWithMaterialsOfOCC.value}
              label="Порядок ознакомления с материалами собрания"
              required={form.ProcedureForFamiliarizationWithMaterialsOfOCC.isRequired}
              tooltipMessage="Укажите адрес и время приема собственников, по которому они смогут ознакомиться с материалами собрания, если нет возможности ознакомиться в электронной форме"
              placeholder=""
              errorMessage={form.ProcedureForFamiliarizationWithMaterialsOfOCC.error}
              onChange={(value) => updateFormFieldValue("ProcedureForFamiliarizationWithMaterialsOfOCC", value)}
            />
          </div>
        </div>
      </form>

      <BottomBar>
        <BaseButton
          variant="primary"
          isLoading={isOccCreateLoading}
          disabled={isSubmitDisabled}
          appearance="positive"
          endIcon={arrowRightWhiteIcon}
          onClick={handleOnSubmit}
        >
          Дальше
        </BaseButton>
      </BottomBar>
    </div>
  );
};

export default UKCreateOccCreatingStep;
