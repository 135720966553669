import { createAsyncThunk } from "@reduxjs/toolkit";
import { OCC, OCCV2, OCCV2Order, Pagination, ResponseAbstractWithPagination } from "@/types";
import { OCCService } from "@/services/v2";
import { RootState } from "@/app/store";
import {
  setOCC,
  setList,
  toggleLoading,
  setQuery,
  toggleIsActual,
  setPagination,
  setDocuments,
  setOwners,
  setProgress,
  setOwnersResults,
  setQuestionsResults,
  reset,
  SntMyOccState,
  pushList,
  showGisZhkhCanSendOccModal,
  addGisZhkhCanSendOccList,
  closeGisZhkhCanSendOccModal,
  toggleGisZhkhCanSyncSuccessModal,
  toggleGisZhkhErrorModal,
} from "./";

export const fetchOccList = createAsyncThunk<OCC.OccOrderItem[], undefined>(
  "snt/my-occ/fetch-occ-list",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const rootState = getState() as RootState;
    dispatch(toggleLoading(true));
    dispatch(setPagination(null));
    dispatch(setList([]));
    try {
      let params: any = {};
      if (rootState.sntMyOcc.query) {
        params["Number"] = rootState.sntMyOcc.query;
      }
      if (typeof rootState.sntMyOcc.IsActual === "boolean") {
        params["IsActual"] = rootState.sntMyOcc.IsActual;
      }
      const { data } = await OCCService.getOCCList(params);
      dispatch(setList(data.Data));
      dispatch(setPagination(data.Page));

      return data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    } finally {
      dispatch(toggleLoading(false));
    }
  }
);

export const fetchMoreOccList = createAsyncThunk<Pagination, undefined>(
  "snt/my-occ/fetch-more-occ-list",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const rootState = getState() as RootState;
    if (rootState.sntMyOcc.isLoading) {
      throw Error();
    }

    try {
      let params: any = {
        page: (rootState.sntMyOcc.page?.Index ?? 0) + 1,
      };
      if (rootState.sntMyOcc.query) {
        params["Number"] = rootState.sntMyOcc.query;
      }
      if (typeof rootState.sntMyOcc.IsActual === "boolean") {
        params["IsActual"] = rootState.sntMyOcc.IsActual;
      }
      const { data } = await OCCService.getOCCList(params);
      dispatch(pushList(data.Data));
      dispatch(setPagination(data.Page));

      return data.Page;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const setOccListSearchQuery = createAsyncThunk(
  "snt/my-occ/set-search-query",
  async (query: string, { rejectWithValue, getState, dispatch }) => {
    dispatch(setQuery(query));
    dispatch(fetchOccList());
  }
);

export const setOccListFilter = createAsyncThunk(
  "snt/my-occ/set-search-filter",
  async (IsActual: SntMyOccState["IsActual"], { rejectWithValue, getState, dispatch }) => {
    dispatch(toggleIsActual(IsActual));
    dispatch(fetchOccList());
  }
);

export const getOccDetailsById = createAsyncThunk<OCCV2.Details, OCCV2Order.Base["Id"]>(
  "snt/my-occ/get-occ-details",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(reset());
      const { data } = await OCCService.getOCCFullDetails(payload);
      dispatch(setOCC(data.Data));
      return data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getOccDocumentsByOccId = createAsyncThunk<OCC.OCCDocument[], OCC.OccOrderBase["Id"]>(
  "snt/my-occ/get-occ-documents",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await OCCService.getOCCFiles(payload);
      dispatch(setDocuments(data.Data.Documents));
      return data.Data.Documents;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getOccOwnerReestrByOccId = createAsyncThunk<OCC.RegistryOwner[], OCC.OccOrderBase["Id"]>(
  "snt/my-occ/get-owner-reestr",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await OCCService.getOwnerReestr(payload);
      dispatch(setOwners(data.Data.Owners));
      return data.Data.Owners;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getOwnerReestrById = createAsyncThunk<
  OCC.RegistryOwner,
  { OccId: OCC.OccOrderBase["Id"]; OwnerReestrId: OCC.RegistryOwner["Id"] }
>("snt/my-occ/get-owner-reestr-by-id", async ({ OccId, OwnerReestrId }, { rejectWithValue, dispatch, getState }) => {
  const owners = (getState() as RootState).sntMyOcc.owners;
  try {
    const owner = owners.find((it) => it.Id === OwnerReestrId);
    if (owner) {
      return owner;
    }

    const { data } = await OCCService.getOwnerReestrById(OccId, OwnerReestrId);
    return data.Data;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data);
  }
});

export const getProgressByOccId = createAsyncThunk<OCC.Progress, OCC.OccOrderBase["Id"]>(
  "snt/my-occ/get-progress",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await OCCService.getProgress(payload);
      dispatch(setProgress(data.Data));
      return data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getQuestionsResultByOccId = createAsyncThunk<Array<OCC.QuestionResult>, OCC.OccOrderBase["Id"]>(
  "snt/my-occ/get-questions-result",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await OCCService.getQuestionsResult(payload);
      dispatch(setQuestionsResults(data.Data.Questions));
      return data.Data.Questions;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getOwnersQuestionsResultsByOccId = createAsyncThunk<
  Array<OCC.OwnersQuestionsResultsResponse>,
  OCC.OccOrderBase["Id"]
>("snt/my-occ/get-owners-questions-results", async (payload, { rejectWithValue, dispatch, getState }) => {
  try {
    const { data } = await OCCService.getOwnersQuestionsResults(payload);
    dispatch(setOwnersResults(data.Data.OccOwnersQuestionsResults));
    return data.Data.OccOwnersQuestionsResults;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data);
  }
});

export const sendOccToGisZhkh = createAsyncThunk<boolean, Array<OCC.OccOrderBase["Id"]>>(
  "my-occ/send-occ-to-gis-zhkh",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      await OCCService.syncOccWithgGisZhkh(payload);
      await dispatch(closeGisZhkhCanSendOccModal());
      await dispatch(toggleGisZhkhCanSyncSuccessModal(true));
      return true;
    } catch (err: any) {
      await dispatch(closeGisZhkhCanSendOccModal());
      await dispatch(toggleGisZhkhErrorModal({ isOpen: true, type: "onsend" }));
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getOccListToSyncWithgGisZhkh = createAsyncThunk<ResponseAbstractWithPagination<OCCV2Order.Item[]>>(
  "my-occ/get-occ-list-to-sync-with-gis-zhkh",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await OCCService.getOccListToSyncWithgGisZhkh({});
      if (data.Data.length > 0) {
        dispatch(showGisZhkhCanSendOccModal(data));
      }
      return data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const loadMoreOccListToSyncWithgGisZhkh = createAsyncThunk<ResponseAbstractWithPagination<OCCV2Order.Item[]>>(
  "my-occ/load-more-occ-list-to-sync-with-gis-zhkh",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const rootState = getState() as RootState;
    try {
      const { data } = await OCCService.getOccListToSyncWithgGisZhkh({
        page: (rootState.sntMyOcc.gisZhkh.canSendOcc.page?.Index ?? 0) + 1,
      });
      dispatch(addGisZhkhCanSendOccList(data));
      return data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
