import { House } from "@/types";
import { checkedIcon } from "@/consts/icons";
import styles from "./styles.module.scss";

interface IAddressItemProps {
  address: House.Base;
  onSelect: () => void;
  isActive: boolean;
}

const AddressItem: React.FC<IAddressItemProps> = ({ address, onSelect, isActive }) => {
  function handleOnClick() {
    onSelect();
  }

  return (
    <div className={styles.address} onClick={handleOnClick}>
      <span className="text--primary sf-text-regular color--text-primary">{address.Address}</span>
      {isActive ? checkedIcon() : ""}
    </div>
  );
};

export default AddressItem;
