import throttle from "lodash/throttle";
import { arrowRightWhiteIcon } from "@/consts/icons";
import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

type ISntPassportBottomBarProps = {
  publicationText: string;
  publishBtnText?: string;
  isSaveLoading?: boolean;
  isPublishLoading?: boolean;
  onSave?: () => void;
  onPublish?: () => void;
};

const SntPassportBottomBar: React.FC<ISntPassportBottomBarProps> = ({
  publicationText,
  publishBtnText = "Опубликовать",
  isSaveLoading = false,
  isPublishLoading = false,
  onSave,
  onPublish,
}) => {
  const handleOnSaveThrottled = throttle(handleOnSave, 1500);
  const handleOnPublishThrottled = throttle(handleOnPublish, 1500);

  function handleOnSave() {
    if (onSave) {
      onSave();
    }
  }

  function handleOnPublish() {
    if (onPublish) {
      onPublish();
    }
  }

  return (
    <div className={styles["b-snt-passport-bottom-bar"]}>
      <div>
        <p className="headline-h4 sf-text-bold color--text-secondary">{publicationText}</p>
      </div>
      <div>
        <BaseButton
          variant="secondary"
          isLoading={isSaveLoading}
          disabled={isSaveLoading}
          onClick={handleOnSaveThrottled}
        >
          Просто сохранить
        </BaseButton>
        <BaseButton
          variant="primary"
          endIcon={arrowRightWhiteIcon}
          disabled={isPublishLoading}
          isLoading={isPublishLoading}
          onClick={handleOnPublishThrottled}
        >
          {publishBtnText}
        </BaseButton>
      </div>
    </div>
  );
};

export default SntPassportBottomBar;
