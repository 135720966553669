import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Chats, House } from "@/types";
import { ChatsService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { showUserDetails } from "@/app/store/slices/chats";
import { activeAddress } from "@/app/store/slices/user";
import Spinner from "@/components/spinner";
import SelectAddressDropdown from "@/components/SelectAddressDropdown";
import UserDetailsModal from "@/components/UserDetails/Modal";
import UserOwnershipStatus from "@/components/UserDetails/OwnershipStatus";
import UserCard from "@/components/UserDetails/Card";
import styles from "./styles.module.scss";

type FullAddress = House.Base & {
  ObjectNumber: Chats.ChatUserAddress["ObjectNumber"];
};

const ChatUserDetailsModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const userId = useSelector((state: RootState) => state.chats.selectedUserId);
  const _activeAddress = useSelector(activeAddress);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [user, setUser] = useState<Chats.ChatUserDetails>();
  const [currentAddress, setCurrentAddress] = useState<{
    FiasId: House.Item["FiasId"];
    ObjectNumber: Chats.ChatUserAddress["ObjectNumber"];
  }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addressesFormatted: Array<FullAddress> = useMemo(() => {
    return (
      user?.Addresses.map((it) => ({
        ...it.House,
        Address: `${it.House.Address}, ${it.House.Type === House.HouseType.Snt ? "уч." : "кв."} ${it.ObjectNumber}`,
        ObjectNumber: it.ObjectNumber,
      })) ?? []
    );
  }, [user?.Addresses]);

  const detailsByAddress: Chats.ChatUserAddress | null = useMemo(() => {
    return (
      user?.Addresses.find(
        (it) => it.House.FiasId === currentAddress?.FiasId && it.ObjectNumber === currentAddress.ObjectNumber
      ) ?? null
    );
  }, [user, currentAddress]);

  function close() {
    setIsOpen(false);
    dispatch(showUserDetails(null));
  }

  async function fetchData() {
    if (!userId) {
      setIsOpen(false);
      return;
    }

    setIsLoading(true);
    setIsOpen(true);
    try {
      const { data } = await ChatsService.getChatUser(userId);
      if (data.Data.Addresses.length === 0) {
        throw Error();
      }
      setCurrentAddress(() => {
        const active = data.Data.Addresses.find((it) => it.House.FiasId === _activeAddress?.FiasId);
        if (active) {
          return {
            FiasId: active.House.FiasId,
            ObjectNumber: active.ObjectNumber,
          };
        }
        return {
          FiasId: data.Data.Addresses[0].House.FiasId,
          ObjectNumber: data.Data.Addresses[0].ObjectNumber,
        };
      });
      setUser(data.Data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <UserDetailsModal isOpen={isOpen} onClose={close}>
      {isLoading ? (
        <div className={styles["b-chat-user-details__loading"]}>
          <Spinner size={36} />
        </div>
      ) : user ? (
        <>
          <div className={styles["b-chat-user-details__profile"]}>
            <UserCard fio={user.Fio} phone={user.Phone} avatar={user.Avatar} role={detailsByAddress?.Role?.Type} />
            <h4 className="headline-h4 sf-text-bold color--text-primary">По профилю:</h4>

            <div className={styles["b-chat-user-details__profile_list"]}>
              <div className={styles["b-chat-user-details__cell"]}>
                <p className="text--primary sf-text-semibold color--text-primary">Вход через ЕСИА:</p>
                <p className="text--primary sf-text-regular color--text-secondary">{user.EsiaExists ? "Да" : "Нет"}</p>
              </div>
              <div className={styles["b-chat-user-details__cell"]}>
                <p className="text--primary sf-text-semibold color--text-primary">Дата регистрации в приложении:</p>
                <p className="text--primary sf-text-regular color--text-secondary">{user.RegisteredAt}</p>
              </div>
            </div>
          </div>

          <div className={styles["b-chat-user-details__address"]}>
            <h4 className="headline-h4 sf-text-bold color--text-primary">По адресу:</h4>

            <SelectAddressDropdown
              custom={{
                value:
                  addressesFormatted.find(
                    (it) => it.FiasId === currentAddress?.FiasId && it.ObjectNumber === currentAddress.ObjectNumber
                  ) ?? null,
                onSelect: (value) =>
                  setCurrentAddress({
                    FiasId: value.FiasId,
                    ObjectNumber: (value as FullAddress).ObjectNumber,
                  }),
                addresses: addressesFormatted,
                isActive: (value) =>
                  value.FiasId === currentAddress?.FiasId &&
                  (value as FullAddress).ObjectNumber === currentAddress.ObjectNumber,
              }}
              color="var(--text-link)"
            />
            {detailsByAddress && (
              <>
                <div className={styles["b-chat-user-details__address_divider"]}></div>
                <div className={styles["b-chat-user-details__cell"]}>
                  <p className="text--primary sf-text-semibold color--text-primary">Статус в приложении:</p>
                  {detailsByAddress?.Ownership && <UserOwnershipStatus status={detailsByAddress.Ownership} />}
                </div>
                <div className={styles["b-chat-user-details__cell"]}>
                  <p className="text--primary sf-text-semibold color--text-primary">Есть в моем реестре:</p>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {detailsByAddress.InRegistry ? "Да" : "Нет"}
                  </p>
                </div>
                <div className={styles["b-chat-user-details__cell"]}>
                  <p className="text--primary sf-text-semibold color--text-primary">Может участвовать в собраниях:</p>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {detailsByAddress.CanOccVote ? "Да" : "Нет"}
                  </p>
                </div>
              </>
            )}
          </div>
        </>
      ) : null}
    </UserDetailsModal>
  );
};

export default ChatUserDetailsModal;
