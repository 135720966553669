import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OCC, OCCV2, OCCV2Order, Pagination, ResponseAbstractWithPagination } from "@/types";

export interface SntMyOccState {
  occ: OCCV2.Details | null;
  list: OCCV2Order.Item[];
  isLoading: boolean;
  IsActual: boolean | null;
  query: string;
  page: Pagination | null;
  documents: OCC.OCCDocument[];
  owners: OCC.RegistryOwner[];
  results: {
    progress: OCC.Progress | null;
    owners: OCC.OwnersQuestionsResultsResponse[];
    questions: OCC.QuestionResult[];
  };
  gisZhkh: {
    isAccessPeriodEndedMessageShown: boolean;
    canSendOcc: {
      isOpen: boolean;
      list: OCCV2Order.Item[];
      page: Pagination | null;
    };
    isSuccessModalOpen: boolean;
    errorModal: {
      isOpen: boolean;
      type: "onsend" | "onget" | null;
    };
  };
}

const initialState: SntMyOccState = {
  occ: null,
  list: [],
  isLoading: false,
  IsActual: null,
  query: "",
  page: null,
  documents: [],
  owners: [],
  results: {
    progress: null,
    owners: [],
    questions: [],
  },
  gisZhkh: {
    isAccessPeriodEndedMessageShown: false,
    canSendOcc: {
      isOpen: false,
      list: [],
      page: null,
    },
    isSuccessModalOpen: false,
    errorModal: {
      isOpen: false,
      type: null,
    },
  },
};

const sntMyOccSlice = createSlice({
  name: "snt-my-occ",
  initialState,
  reducers: {
    setOCC: (state, action: PayloadAction<SntMyOccState["occ"]>) => {
      state.occ = action.payload;
    },

    setList: (state, action: PayloadAction<SntMyOccState["list"]>) => {
      state.list = action.payload;
    },

    pushList: (state, action: PayloadAction<SntMyOccState["list"]>) => {
      state.list.push(...action.payload);
    },

    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },

    toggleIsActual: (state, action: PayloadAction<SntMyOccState["IsActual"]>) => {
      state.IsActual = action.payload;
    },

    setPagination: (state, action: PayloadAction<SntMyOccState["page"]>) => {
      state.page = action.payload;
    },

    setDocuments: (state, action: PayloadAction<SntMyOccState["documents"]>) => {
      state.documents = action.payload;
    },

    setOwners: (state, action: PayloadAction<SntMyOccState["owners"]>) => {
      state.owners = action.payload;
    },

    setProgress: (state, action: PayloadAction<SntMyOccState["results"]["progress"]>) => {
      state.results.progress = action.payload;
    },

    setOwnersResults: (state, action: PayloadAction<SntMyOccState["results"]["owners"]>) => {
      state.results.owners = action.payload;
    },

    setQuestionsResults: (state, action: PayloadAction<SntMyOccState["results"]["questions"]>) => {
      state.results.questions = action.payload;
    },

    reset: (state) => {
      state.occ = null;
      state.documents = [];
      state.owners = [];
      state.results = {
        progress: null,
        owners: [],
        questions: [],
      };
    },

    togggleGisZhkhAccessPeriodEndedMessage: (state, action: PayloadAction<boolean>) => {
      state.gisZhkh.isAccessPeriodEndedMessageShown = action.payload;
    },

    showGisZhkhCanSendOccModal: (state, action: PayloadAction<ResponseAbstractWithPagination<OCCV2Order.Item[]>>) => {
      state.gisZhkh.canSendOcc.list = action.payload.Data;
      state.gisZhkh.canSendOcc.page = action.payload.Page;
      state.gisZhkh.canSendOcc.isOpen = true;
    },
    addGisZhkhCanSendOccList: (state, action: PayloadAction<ResponseAbstractWithPagination<OCCV2Order.Item[]>>) => {
      state.gisZhkh.canSendOcc.list.push(...action.payload.Data);
      state.gisZhkh.canSendOcc.page = action.payload.Page;
    },
    closeGisZhkhCanSendOccModal: (state) => {
      state.gisZhkh.canSendOcc.isOpen = false;
    },

    toggleGisZhkhCanSyncSuccessModal: (state, action: PayloadAction<boolean>) => {
      state.gisZhkh.isSuccessModalOpen = action.payload;
    },

    toggleGisZhkhErrorModal: (
      state,
      action: PayloadAction<{ isOpen: boolean; type?: SntMyOccState["gisZhkh"]["errorModal"]["type"] }>
    ) => {
      if (action.payload.isOpen && action.payload.type) {
        state.gisZhkh.errorModal.type = action.payload.type;
      }
      state.gisZhkh.errorModal.isOpen = action.payload.isOpen;
    },
  },
  extraReducers: {},
});

export const {
  setOCC,
  setList,
  pushList,
  toggleLoading,
  setQuery,
  toggleIsActual,
  setPagination,
  setDocuments,
  setOwners,
  setProgress,
  setOwnersResults,
  setQuestionsResults,
  reset,
  togggleGisZhkhAccessPeriodEndedMessage,
  showGisZhkhCanSendOccModal,
  addGisZhkhCanSendOccList,
  closeGisZhkhCanSendOccModal,
  toggleGisZhkhCanSyncSuccessModal,
  toggleGisZhkhErrorModal,
} = sntMyOccSlice.actions;
export * from "./actions";
export default sntMyOccSlice.reducer;
