import { createTheme, experimental_sx as sx } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: { main: "#2D3998" },
    secondary: { main: "#000000" },
    error: { main: "#FC5130" },
    success: { main: "#1EC876" },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: "48px",
          borderRadius: "10px",
          textTransform: "none",
          padding: "5px 24px",
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "error" && {
              border: "1px solid rgba(0, 0, 0, 0.1)",
            }),
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "secondary" && {
              border: "1px solid rgba(0, 0, 0, 0.1)",
            }),
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // border: "1px solid rgba(43, 57, 73, 0.16))",
          borderRadius: "10px",
          boxShadow: "none",
          backgroundColor: "#ffffff",
        },
        input: {
          padding: "12.5px 14px",
          borderRadius: "10px",
        },
        multiline: {
          padding: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#76818A",
          fontSize: "0.875rem",
          marginBottom: "8px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === "error" && {
            background: "rgba(252, 81, 48, 0.1)",
            borderRadius: "10px",
            color: "error.main",
          }),
          ...(ownerState.severity === "info" && {
            background: "#E5E7F1",
            borderRadius: "10px",
            color: "#2D3998",
          }),
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          marginBottom: "35px",
          justifyContent: "space-between",
          alignItems: "stretch",
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          height: "100%",
        },
        iconContainer: {
          display: "none",
        },
        labelContainer: {
          height: "100%",
        },
        label: sx({
          textAlign: "left",
          fontSize: "1.2rem",
          fontWeight: 500,
          paddingBottom: "10px",
          borderBottom: "4px solid",
          height: "100%",
          "&.Mui-active": {
            color: "primary.main",
            borderColor: "primary.main",
          },
          "&.Mui-completed": {
            color: "success.main",
            borderColor: "success.main",
          },
          // TODO: Позже заменить на breakpoints
          "@media (max-width: 1440px)": {
            fontSize: "1rem",
          },
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: sx({
          color: "primary.main",
          width: "180px",
          height: "48px",
          borderRadius: "10px",
          textTransform: "capitalize",
          padding: "5px 20px",
          borderColor: "primary.main",
          "&.Mui-selected": {
            color: "#ffffff",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: sx({
          boxShadow: "none",
          "&:first-of-type": {
            "border-top-left-radius": "16px",
            "border-top-right-radius": "16px",
          },
          "&:last-of-type": {
            "border-bottom-left-radius": "16px",
            "border-bottom-right-radius": "16px",
          },
          "&.Mui-expanded": {
            margin: "1px 0 0 0",
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: sx({
          padding: "0 16px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: sx({
          padding: "12px 16px",
          color: "secondary.main",
          fontSize: "14px",
          textTransform: "capitalize",
          "&.Mui-selected": {
            color: "secondary.main",
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#212121",
          borderRadius: "6px",
          "& .MuiTooltip-arrow": {
            color: "#212121",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: sx({
          color: "secondary.main",
        }),
        input: sx({
          "&::placeholder": {
            color: "rgba(0, 0, 0, 1);",
          },
        }),
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: sx({
          "& th": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          },
        }),
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: sx({
          boxShadow: "none",
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: sx({
          padding: "6px 8px",
          textAlign: "left",
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: sx({
          paddingTop: "14px",
        }),
      },
    },
  },
});

export { theme };
