import { isSameDay } from "date-fns";
import { House } from "./house";
import { HouseUsers } from "./house-users";

export namespace Chats {
  export interface User {
    Id: number;
    FullName: string;
    Avatar: string;
    IsMainRole: boolean;
  }

  export interface Chat {
    Id: number;
    User: User;
    LastMessage: Message;
    UnreadCount: number;
  }

  export interface Message {
    Id: number;
    Text: string;
    Timestamp: number;
    IsAuthor: boolean;
    IsRead: boolean;
    Author?: User;
  }

  export interface ChatUserAddress {
    InRegistry: HouseUsers.User["InRegistry"];
    ObjectNumber: HouseUsers.User["ObjectNumber"];
    CanOccVote: HouseUsers.User["CanOccVote"];
    Ownership: HouseUsers.User["Ownership"];
    Role: HouseUsers.User["Role"];
    House: House.Base;
  }

  export interface ChatUserDetails {
    UserId: HouseUsers.User["UserId"];
    Fio: HouseUsers.User["Fio"];
    Phone: HouseUsers.User["Phone"];
    EsiaExists: HouseUsers.User["EsiaExists"];
    Avatar: HouseUsers.User["Avatar"];
    RegisteredAt: HouseUsers.User["RegisteredAt"];
    Addresses: Array<ChatUserAddress>;
  }

  export class MessagesGroupedByDate {
    date: Date;
    messages: Message[];

    constructor(timestamp: number, messages: Message[]) {
      this.date = timestamp.parseFromEpochSeconds();
      this.messages = messages;
    }
  }

  export async function messageGroupsMapper(
    messages: Message[],
    oldGroups?: MessagesGroupedByDate[],
    addOnStart: boolean = false
  ) {
    let groups = oldGroups ?? [];
    await messages.forEach((message) => {
      const group = groups.find((gr) => isSameDay(gr.date, message.Timestamp.parseFromEpochSeconds()));
      if (group) {
        if (addOnStart) {
          group.messages.unshift(message);
        } else {
          group.messages.push(message);
        }
        group.date = message.Timestamp.parseFromEpochSeconds();
      } else {
        if (addOnStart) {
          groups.unshift(new MessagesGroupedByDate(message.Timestamp, [message]));
        } else {
          groups.push(new MessagesGroupedByDate(message.Timestamp, [message]));
        }
      }
    });

    return groups;
  }
}
