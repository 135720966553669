import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import debounce from "lodash/debounce";
import { Pagination } from "@/types";
import { RootState } from "@/app/store";
import { ChatsService } from "@/services/v2";
import {
  toggleLoading,
  setChats,
  addChats,
  setQuery,
  setReadFilter,
  setUnreadCount,
  setPagination,
  setAllChatsCount,
} from "./";

const debouncedHandler = debounce(setFilterLastNameDebounced, 300);

export const setFilterLastName = createAsyncThunk(
  "chats/set-filter-lastname",
  async (query: string, { rejectWithValue, getState, dispatch }) => {
    const state = (getState() as RootState).chats;
    if (state.filters.query === query) {
      return;
    }

    dispatch(setQuery(query));
    debouncedHandler({ dispatch, getState } as BaseThunkAPI<unknown, unknown>);
  }
);

async function setFilterLastNameDebounced({ dispatch, getState }: BaseThunkAPI<unknown, unknown>) {
  await dispatch(fetchChats() as any).unwrap();
}

export const setFilterRead = createAsyncThunk(
  "chats/set-filter-read",
  async (read: boolean | null, { rejectWithValue, getState, dispatch }) => {
    const state = (getState() as RootState).chats;
    if (state.filters.read === read) {
      return;
    }

    dispatch(setReadFilter(read));
    await dispatch(fetchChats()).unwrap();
  }
);

export const fetchChats = createAsyncThunk<Pagination, undefined>(
  "chats/fetch-chats",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const state = (getState() as RootState).chats;

    try {
      dispatch(toggleLoading(true));
      const { data } = await ChatsService.getChats({
        Read: state.filters.read ?? undefined,
        LastName: state.filters.query?.length > 2 ? state.filters.query : undefined,
      });
      dispatch(setChats(data.Data));
      dispatch(setPagination(data.Page));
      if (data.Data.some((it) => it.UnreadCount > 0)) {
        await dispatch(getChatsCount());
      }
      return data.Page;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    } finally {
      dispatch(toggleLoading(false));
    }
  }
);

export const loadChats = createAsyncThunk<Pagination, undefined>(
  "chats/load-chats",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const state = (getState() as RootState).chats;

    try {
      const { data } = await ChatsService.getChats({
        Read: state.filters.read ?? undefined,
        LastName: state.filters.query ? state.filters.query : undefined,
        Page: (state.pagination?.Index ?? 0) + 1,
      });
      dispatch(addChats(data.Data));
      dispatch(setPagination(data.Page));
      return data.Page;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getChatsCount = createAsyncThunk<any, undefined>(
  "chats/get-chats-count",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const resp = await Promise.allSettled([ChatsService.getChatsCount(), ChatsService.getChatsCount(false)]);
      if (resp[0].status === "fulfilled" && resp[0].value.data.Data) {
        dispatch(setAllChatsCount(resp[0].value.data.Data));
      }
      if (resp[1].status === "fulfilled" && resp[1].value.data.Data) {
        dispatch(setUnreadCount(resp[1].value.data.Data));
      }
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
