import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames/bind";
import isToday from "date-fns/isToday";
import { paths } from "@/consts/routes";
import { Chats } from "@/types";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IAppealDialogProps {
  chat: Chats.Chat;
  openUserDetails: () => void;
}

const AppealDialog: React.FC<IAppealDialogProps> = ({ chat, openUserDetails }) => {
  const datetime = useMemo(() => {
    const date = chat.LastMessage.Timestamp.parseFromEpochSeconds();
    return date.formatByPattern(isToday(date) ? "HH:mm" : "dd.MM.yyyy");
  }, [chat.LastMessage.Timestamp]);

  return (
    <NavLink
      to={paths.appeals.fullPath().item(chat.Id)}
      className={({ isActive }) => cx("b-appeal-dialog", { "b-appeal-dialog--selected": isActive })}
      role="button"
    >
      <div className={styles["b-appeal-dialog__head"]}>
        <BaseUserAvatar avatar={chat.User.Avatar} size="32px" gap="4px" onClick={openUserDetails}>
          <span className="table--middle sf-text-semibold color--text-primary">{chat.User.FullName}</span>
        </BaseUserAvatar>

        <span className="tags--small sf-text-regular color--text-secondary">{datetime}</span>
      </div>

      <div className={styles["b-appeal-dialog__body"]}>
        <div className={styles["b-appeal-dialog__message"]}>
          <p className="caption--middle sf-text-regular color--text-primary">
            {chat.LastMessage.IsAuthor && <span className="color--text-secondary">Вы: </span>}
            {chat.LastMessage.Text}
          </p>
        </div>

        {chat.UnreadCount ? (
          <span className={styles["b-appeal-dialog__unread"]}>
            <span className="caption--middle color--text-white sf-text-medium">{chat.UnreadCount}</span>
          </span>
        ) : null}
      </div>
    </NavLink>
  );
};

export default AppealDialog;
