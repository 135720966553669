import InteractiveEyes from "@/features/InteractiveEyes";
import styles from "./styles.module.scss";

const AppealsChatRoot: React.FC = () => {
  return (
    <div className={styles["p-appeals-chat-root"]}>
      <InteractiveEyes />
      <h4 className="headline-h4 sf-text-bold color--text-primary">Выберите обращение</h4>
    </div>
  );
};

export default AppealsChatRoot;
