import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, ScrollRestoration } from "react-router-dom";

import { paths } from "../consts/routes";
import { RootState, useAppDispatch } from "./store";
import { fetchUser } from "./store/slices/user";
import { Spinner } from "../components/spinner";
import SnackbarController from "../features/snackbar";
import NetworkListener from "../features/network-listener";
import GisZhkhModals from "@/features/GisZhkhModals";
import "./app.module.scss";

const App = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const { token, profile } = useSelector((state: RootState) => state.user);

  const dispatch = useAppDispatch();

  const checkUser = async () => {
    setLoading(true);
    try {
      if (!token) {
        setLoading(false);
        return;
      }

      if (!profile && token) {
        await dispatch(fetchUser()).unwrap();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <Spinner size={36} />
    </div>
  ) : !token ? (
    <Navigate to={paths.authorization} />
  ) : (
    <div className="app">
      <ScrollRestoration />

      <Outlet />

      <SnackbarController />
      <NetworkListener />
      <GisZhkhModals />
    </div>
  );
};

export default App;
