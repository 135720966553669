import { CSSProperties } from "react";
import { ChevronDown16Icon } from "@/consts/icons-v2";
import { Accordion, CustomCollapsed, Expanded } from "@/components/base-accordion";
import BaseButton from "@/components/base-button";
import AccordionFiltersRowItem from "./RowItem";
import styles from "./styles.module.scss";

const ACCORDION_DEFAULT_ID = "filter";

interface IAccordionFiltersProps {
  filterId?: string;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  onSync: () => void;
  onApply: () => void;
  onReset: () => void;
  offset?: CSSProperties["padding"];
}

const AccordionFilters: React.FC<IAccordionFiltersProps> = ({
  children,
  filterId,
  onSync,
  onApply,
  onReset,
  offset = "16px 0",
}) => {
  async function handleSync() {
    onSync();
  }

  function handleApply() {
    onApply();
  }

  function handleReset() {
    onReset();
  }

  const filterAccordionId = filterId ?? ACCORDION_DEFAULT_ID;

  return (
    <div className={styles["b-accordion-filters"]} style={{ padding: offset }}>
      <Accordion isAsync={true} callback={handleSync}>
        <div className="flex">
          <CustomCollapsed id={filterAccordionId} upAndDownIconAnimation={true}>
            <BaseButton
              variant="secondary"
              endIcon={
                <span className="flex middle center" role="spinbutton">
                  {ChevronDown16Icon()}
                </span>
              }
            >
              Фильтр
            </BaseButton>
          </CustomCollapsed>
        </div>

        <Expanded id={filterAccordionId}>
          <div className={styles["b-accordion-filters__content"]}>
            <div>{children}</div>

            <div className={styles["b-accordion-filters__actions"]}>
              <div>
                <BaseButton onClick={handleApply}>Показать</BaseButton>
              </div>
              <div>
                <BaseButton variant="secondary" appearance="negative" onClick={handleReset}>
                  Сбросить фильтр
                </BaseButton>
              </div>
            </div>
          </div>
        </Expanded>
      </Accordion>
    </div>
  );
};

export default AccordionFilters;
export { AccordionFiltersRowItem };
