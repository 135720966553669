import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { OCCV2Order } from "@/types";
import { successIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import {
  closeGisZhkhCanSendOccModal,
  loadMoreOccListToSyncWithgGisZhkh,
  sendOccToGisZhkh,
} from "@/app/store/slices/snt/myOcc";
import BaseButton from "@/components/base-button";
import BaseTable, {
  BaseContainer,
  BaseTableBody,
  BaseTableCell,
  BaseTableHead,
  BaseTableHeadCell,
  BaseTableRow,
} from "@/components/base-table";
import BaseModal, { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import Checkbox from "@/components/checkbox";
import Spinner from "@/components/spinner";

const CanSyncOccListModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isOpen, list, page } = useSelector((state: RootState) => state.sntMyOcc.gisZhkh.canSendOcc);

  const { measureRef, isIntersecting, observer, hasMore } = useInfiniteScroll({ pagination: page ?? undefined });

  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [selectedToSync, selectToSync] = useState<Set<OCCV2Order.Base["Id"]>>(new Set());

  const isAllSelected: boolean = useMemo(
    () => list.length > 0 && list.every((it) => selectedToSync.has(it.Id)),
    [list, selectedToSync]
  );

  function selectAll() {
    if (isAllSelected) {
      selectToSync(new Set());
    } else {
      selectToSync(new Set([...list.map((it) => it.Id)]));
    }
  }

  function onClose() {
    dispatch(closeGisZhkhCanSendOccModal());
  }

  async function onSubmit() {
    setSubmitLoading(true);
    try {
      const ids = Array.from(selectedToSync);
      await dispatch(sendOccToGisZhkh(ids)).unwrap();
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitLoading(false);
    }
  }

  async function fetchMore() {
    try {
      const { Page } = await dispatch(loadMoreOccListToSyncWithgGisZhkh()).unwrap();
      if (observer && isIntersecting && Page.Index === Page.Count) {
        observer.disconnect();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  return (
    <BaseModal isOpen={isOpen} minWidth="800px" maxWidth="800px">
      <BaseModalHeader
        iconBgColor="#CFF3E1"
        icon={successIcon}
        title="Получен доступ в ГИС ЖКХ"
        subtitle="У вас появилась возможность отправить итоги завершенных собраний в ГИС ЖКХ. Просто выберите собрания в списке ниже и нажмите на кнопку «Отправить в ГИС ЖКХ». Итоги новых собраний будут отправляться в ГИС ЖКХ автоматически, при наличии доступа. Вы можете отслеживать данный процесс в разделе «Мои собрания»"
        onClose={onClose}
      />

      <BaseModalContent sx={{ padding: "0" }}>
        <BaseContainer>
          <BaseTable>
            <BaseTableHead>
              <BaseTableRow>
                <BaseTableHeadCell>
                  <Checkbox all checked={isAllSelected} onChange={selectAll} />
                </BaseTableHeadCell>
                <BaseTableHeadCell>№ собрания</BaseTableHeadCell>
                <BaseTableHeadCell>Адрес</BaseTableHeadCell>
                <BaseTableHeadCell>Дата начала</BaseTableHeadCell>
                <BaseTableHeadCell>Дата окончания</BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            <BaseTableBody defaultDivider>
              {list.map((it) => (
                <BaseTableRow key={it.Id}>
                  <BaseTableCell>
                    <Checkbox
                      checked={selectedToSync.has(it.Id)}
                      onChange={(checked) =>
                        selectToSync((prevState) => {
                          if (checked) {
                            prevState.add(it.Id);
                          } else {
                            prevState.delete(it.Id);
                          }
                          return new Set([...Array.from(prevState)]);
                        })
                      }
                    />
                  </BaseTableCell>
                  <BaseTableCell>{it.Number}</BaseTableCell>
                  <BaseTableCell>{it.Address}</BaseTableCell>
                  <BaseTableCell>{it.StartDate}</BaseTableCell>
                  <BaseTableCell>{it.EndDate}</BaseTableCell>
                </BaseTableRow>
              ))}
            </BaseTableBody>
            {hasMore && (
              <tbody ref={measureRef}>
                <tr>
                  <td colSpan={7}>
                    <div className="flex center">
                      <Spinner size={36} />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </BaseTable>
        </BaseContainer>
      </BaseModalContent>

      <BaseModalActions variant="bordered">
        <BaseButton variant="secondary" onClick={onClose}>
          Отменить
        </BaseButton>
        <BaseButton isLoading={isSubmitLoading} disabled={selectedToSync.size === 0} onClick={onSubmit}>
          Отправить в ГИС ЖКХ
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default CanSyncOccListModal;
