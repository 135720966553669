import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import { Dropdown16Icon } from "@/consts/icons-v2";
import { RootState, useAppDispatch } from "@/app/store";
import { House } from "@/types";
import { activeAddress, setActiveAddress } from "@/app/store/slices/user";
import BaseLabel from "@/components/BaseLabel";
import BaseQuerySearch from "@/components/base-query-search";
import AddressItem from "./AddressItem";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface ISelectAddressDropdownProps {
  formfield?: boolean;
  disabled?: boolean;
  tooltipMessage?: string;
  custom?: {
    value: House.Base | null;
    onSelect: (value: House.Base) => void;
    addresses?: House.Base[];
    isActive?: (value: House.Base) => boolean;
  };
  color?: CSSProperties["color"];
  chevronColor?: CSSProperties["color"];
  label?: string;
}

const SelectAddressDropdown: React.FC<ISelectAddressDropdownProps> = ({
  formfield,
  disabled,
  tooltipMessage,
  custom,
  label,
  color,
  chevronColor,
}) => {
  const dispatch = useAppDispatch();

  const company = useSelector((state: RootState) => state.user.company);
  const _activeAddress = useSelector(activeAddress);

  const ref = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState<string>("");
  const [isOpen, setOpen] = useState<boolean>(false);
  const [position, setPostion] = useState<{ left: string; right: string; top: string; width: string }>({
    left: "0",
    right: "0",
    top: "0",
    width: "auto",
  });

  const address: House.Base | null = useMemo(() => {
    return custom ? custom.value : _activeAddress;
  }, [custom, _activeAddress]);

  const addresses: House.Base[] = useMemo(() => {
    const list = custom?.addresses
      ? custom.addresses
      : company?.Addresses && company?.Addresses?.length > 0
      ? company.Addresses
      : [];
    return list.filter((it) => it.Address.toLowerCase().includes(query.toLowerCase()));
  }, [company?.Addresses, custom?.addresses, query]);

  function openDropdown() {
    if (isOpen) {
      setOpen(false);
      return;
    }

    const rect = ref.current?.getBoundingClientRect();
    const scrolltop = window?.scrollY ?? 0;
    const top = scrolltop + (rect?.top ?? 0) + (rect?.height ?? 0);
    const windowWidth = window.innerWidth;
    let left = "auto";
    let right = "auto";
    if ((rect?.left ?? 0) + 420 > windowWidth) {
      right = `${windowWidth - (rect?.right ?? 0)}px`;
    } else {
      left = `${rect?.left ?? 0}px`;
    }
    setPostion({
      top: `${top}px`,
      left: left,
      right: right,
      width: `${rect?.width}px`,
    });
    setOpen(true);
  }

  const handleClickOutside = (event: any) => {
    if (
      isOpen &&
      ref.current &&
      !ref.current.contains(event.target) &&
      bodyRef.current &&
      !bodyRef.current.contains(event.target) &&
      !event.defaultPrevented
    ) {
      setOpen(false);
    }
  };

  function handleAddressChange(value: House.Base) {
    if (custom?.isActive ? custom.isActive(value) : value.FiasId === address?.FiasId) {
      return;
    }

    if (custom) {
      custom.onSelect(value);
    } else {
      dispatch(setActiveAddress(value as House.Item));
    }

    setOpen(false);
    setQuery("");
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, bodyRef, isOpen]);

  return (
    <div className="flex">
      <div ref={ref} className={cx({ display: true, formfield, disabled })}>
        {label &&
          (formfield ? (
            <BaseLabel tooltipMessage={tooltipMessage}>{label}</BaseLabel>
          ) : (
            <span className="color--text-primary sf-text-regular">{label}</span>
          ))}
        <div className={styles.display_address} onClick={openDropdown}>
          <span className="sf-text-medium text--primary color--text-primary" style={{ color }}>
            {address?.Address}
          </span>
          <span className={styles.display_address_chevron}>{Dropdown16Icon(chevronColor ?? color)}</span>
        </div>
      </div>

      {isOpen &&
        createPortal(
          <div
            ref={bodyRef}
            className={styles.body}
            style={{
              left: position.left,
              right: position.right,
              top: position.top,
              width: position.width,
            }}
          >
            <div className={styles.search}>
              <BaseQuerySearch value={query} onChange={setQuery} placeholder="Поиск адреса" />
            </div>
            <div className={styles.list}>
              {addresses.map((item) => (
                <AddressItem
                  key={item.Id}
                  address={item}
                  isActive={custom?.isActive ? custom.isActive(item) : item.FiasId === address?.FiasId}
                  onSelect={() => handleAddressChange(item)}
                />
              ))}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default SelectAddressDropdown;
