import { paths } from "@/consts/routes";
import { Profile } from "@/types";
import {
  CalendarCheckIcon,
  FavoriteBookIcon,
  PhoneIcon,
  DigitalGardenIcon,
  MapIcon,
  KeyIcon,
  CheckListIcon,
  PollsIcon,
  ShoppingCars24Icon,
} from "@/consts/icons-v2";

export type NavLinkType = {
  text: string;
  icon?: JSX.Element;
  path?: string;
  role: Profile.UserRole;
  type: Profile.CompanyType | null;
  children?: NavLinkType[];
};

export const sidebar: NavLinkType[] = [
  // Institution
  {
    text: "Садоводства РТ",
    icon: DigitalGardenIcon("var(--icons-primary)"),
    path: paths.institution().fullPath().sntsList,
    role: Profile.UserRole.Institution,
    type: null,
  },
  {
    text: "Карта РТ",
    icon: MapIcon("var(--icons-primary)"),
    path: paths.institution().map,
    role: Profile.UserRole.Institution,
    type: null,
  },

  // UK
  {
    text: "Реестр собственников",
    icon: CheckListIcon("var(--icons-primary)"),
    path: paths.uk().fullPath().registry.subroot,
    role: Profile.UserRole.Company,
    type: Profile.CompanyType.UK,
  },
  {
    text: "Мои дома",
    icon: KeyIcon("var(--icons-primary)"),
    path: paths.uk().fullPath().houses,
    role: Profile.UserRole.Company,
    type: Profile.CompanyType.UK,
  },

  // SNT
  {
    text: "Реестр участков",
    icon: CheckListIcon("var(--icons-primary)"),
    path: "/snt/registry",
    role: Profile.UserRole.Company,
    type: Profile.CompanyType.SNT,
  },

  // Company
  {
    text: "Cобрания",
    icon: CalendarCheckIcon("var(--icons-primary)"),
    role: Profile.UserRole.Company,
    type: null,
    children: [
      {
        text: "Создать собрание",
        path: "/snt/create-occ",
        role: Profile.UserRole.Company,
        type: Profile.CompanyType.SNT,
      },
      {
        text: "Создать собрание",
        path: "/uk/create-occ",
        role: Profile.UserRole.Company,
        type: Profile.CompanyType.UK,
      },
      {
        text: "Мои собрания",
        path: paths.myOcc,
        role: Profile.UserRole.Company,
        type: null,
      },
    ],
  },
  {
    text: "Полезное",
    icon: FavoriteBookIcon("var(--icons-primary)"),
    role: Profile.UserRole.Company,
    type: null,
    children: [
      {
        text: "Контакты",
        path: paths.contacts,
        role: Profile.UserRole.Company,
        type: null,
      },
      {
        text: "Документы",
        path: paths.documents,
        role: Profile.UserRole.Company,
        type: null,
      },
    ],
  },
  {
    text: "Пользователи Локоло",
    icon: PhoneIcon("var(--icons-primary)"),
    path: paths.users,
    role: Profile.UserRole.Company,
    type: null,
  },
  // {
  //   text: "Платные услуги",
  //   icon: ShoppingCars24Icon("var(--icons-primary)"),
  //   path: paths.paidServices.fullPath().root,
  //   role: Profile.UserRole.Company,
  //   type: null,
  // },
  {
    text: "Обращения",
    icon: PollsIcon("var(--icons-primary)"),
    path: paths.appeals.fullPath().list,
    role: Profile.UserRole.Company,
    type: null,
  },
];
