import { Chats, ResponseAbstract, ResponseAbstractWithPagination } from "@/types";
import client from "./config";

export namespace ChatsService {
  export function sendMessage(chatId: Chats.Chat["Id"], Text: string) {
    return client.post<ResponseAbstract<Chats.Message>>(`/api/chats/${chatId}/messages`, { Text });
  }

  export function getChatsCount(Read?: boolean) {
    return client.get<ResponseAbstract<number>>("/api/chats/count", { params: { Read } });
  }

  export function getChatMessages(chatId: Chats.Chat["Id"], Page?: number) {
    return client.get<ResponseAbstractWithPagination<Chats.Message[]>>(`/api/chats/${chatId}/messages`, {
      params: { Page, Limit: 20 },
    });
  }

  export function getChats({ Read, LastName, Page }: { Read?: boolean; LastName?: string; Page?: number }) {
    return client.get<ResponseAbstractWithPagination<Chats.Chat[]>>("/api/chats", {
      params: { Read, LastName, Page, Limit: 20 },
    });
  }

  export function getChatUser(userId: Chats.Chat["User"]["Id"]) {
    return client.get<ResponseAbstract<Chats.ChatUserDetails>>(`/api/chats/user/${userId}`);
  }
}
