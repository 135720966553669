import { SntRegistry } from "@/types";
import { SntPassportMap } from "./map";

export * from "./enums";
export * from "./board";
export * from "./board/forms";
export * from "./agriculture-ministry";
export * from "./agriculture-ministry/forms";
export * from "./analytics";
export * from "./map";

export namespace SntPassport {
  export enum QueryColumn {
    Inn = "inn",
    Name = "name",
    MunicipalDistrict = "municipal_district",
  }

  export type InstitutionSntItem = {
    Id: SntRegistry.SntDetails["Id"];
    MunicipalDistrict: string;
    Inn: string;
    Name: string;
    LokoloUserExists: boolean;
    Geopoint?: SntPassportMap.Geopoint | null;
    FilledPassportSnt: boolean;
  };

  export type InstitutionSntItemSummary = {
    SectionsCount: number;
    OwnersCount: number;
    MembersCount: number;
    LokoloUsersCount: number;
  };
}
