import client, { formDataClient } from "./config";
import { objectToFormData } from "@/helpers/common";
import {
  ResponseAbstract,
  OCC,
  House,
  Dictionary,
  ResponseAbstractWithPagination,
  OCCV2Order,
  OCCV2Author,
  OCCV2,
} from "@/types";

export namespace OCCService {
  export function getOCCList({
    StatusId,
    FiasId,
    Number,
    IsActual,
    page,
    limit = 50,
  }: {
    StatusId?: Dictionary.DictionaryItem<Dictionary.OCCStatus>["id"];
    FiasId?: House.Item["FiasId"];
    Number?: string;
    IsActual?: boolean;
    page?: number;
    limit?: number;
  }) {
    return client.get<ResponseAbstractWithPagination<OCCV2Order.Item[]>>("/api/occ/get-all", {
      params: { StatusId, FiasId, Number, IsActual, page, limit },
    });
  }

  export function createOCC(body: OCCV2Order.CreateBody) {
    return client.post<ResponseAbstract<OCCV2Order.Base["Id"]>>("/api/occ/create", body);
  }

  export function updateOCC(body: OCC.UpdateRequestBody) {
    return client.post<ResponseAbstract<OCCV2Order.Base["Id"]>>("/api/occ/update", body);
  }

  export function getOCCFullDetails(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<OCCV2.Details>>("/api/occ/get", { params: { OccId } });
  }

  export function acceptOCC(OccId: OCCV2Order.Base["Id"]) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/start", { OccId });
  }

  export function getOCCOrder(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<OCCV2Order.ItemFull>>("/api/occ/get-order", { params: { OccId } });
  }

  export function deleteOcc(OccId: OCCV2Order.Base["Id"]) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/delete", { OccId });
  }

  export function getOCCFiles(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<{ Documents: OCC.OCCDocument[] }>>("/api/occ/get-documents", {
      params: { OccId },
    });
  }

  export function getOwnerReestr(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<{ Owners: OCC.RegistryOwner[] }>>("/api/occ/get-owner-reestr", {
      params: { OccId },
    });
  }

  export function getOwnerReestrById(OccId: OCCV2Order.Base["Id"], OwnerReestrId: OCC.RegistryOwner["Id"]) {
    return client.get<ResponseAbstract<OCC.RegistryOwner>>("/api/occ/get-owner-reestr-by-id", {
      params: { OccId, OwnerReestrId },
    });
  }

  export function getOwnerResult(OccId: OCCV2Order.Base["Id"], OwnerId: OCC.RegistryOwner["Id"]) {
    return client.get<ResponseAbstract<OCC.OwnerOCCResults>>("/api/occ/get-owner-result", {
      params: { OccId, OwnerId },
    });
  }

  export function saveOwnerResult(body: OCC.SetOwnerResultRequestBody) {
    const formdata = objectToFormData(body);

    return formDataClient.post<ResponseAbstract<boolean>>("/api/occ/set-owner-result", formdata);
  }

  export function getProgress(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<OCC.Progress>>("/api/occ/get-progress", { params: { OccId } });
  }

  export function getQuestionsResult(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<{ Questions: Array<OCC.QuestionResult> }>>("/api/occ/get-questions-result", {
      params: { OccId },
    });
  }

  export function getOwnersQuestionsResults(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<{ OccOwnersQuestionsResults: Array<OCC.OwnersQuestionsResultsResponse> }>>(
      "/api/occ/get-owners-questions-results",
      { params: { OccId } }
    );
  }

  // Author
  export function getOCCAuthor(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<OCCV2Author.Person>>("/api/occ/get-author", { params: { OccId } });
  }

  export function setOCCAuthor(body: OCCV2Author.SetAuthorBody) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/set-author", body);
  }

  // Administrator
  export function getOCCAdministrator(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<OCCV2Author.Person>>("/api/occ/get-administrator", { params: { OccId } });
  }

  export function setOCCAdministrator(body: OCCV2Author.SetAdministratorBody) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/set-administrator", body);
  }

  // QUESTIONS
  export function getOCCQuestions(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<OCC.Question[]>>("/api/occ/get-questions", { params: { OccId } });
  }

  export function createQuestion(body: OCC.CreateQuestionBody) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/set-questions", body);
  }

  export function deleteQuestion(OccQuestionId: OCCV2Order.Base["Id"]) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/delete-question", { OccQuestionId });
  }

  // GisZhkh
  export function getOccListToSyncWithgGisZhkh({
    StatusId,
    GisZhkhSentStatus,
    FiasId,
    Number,
    IsActual,
    page,
    limit = 50,
  }: {
    StatusId?: Dictionary.DictionaryItem<Dictionary.OCCStatus>["id"];
    GisZhkhSentStatus?: OCCV2Order.GisZhkhStatus;
    FiasId?: House.Item["FiasId"];
    Number?: string;
    IsActual?: boolean;
    page?: number;
    limit?: number;
  }) {
    return client.get<ResponseAbstractWithPagination<OCCV2Order.Item[]>>("/api/occ/get-all/can-send-to-gis-zhkh", {
      params: { StatusId, GisZhkhSentStatus, FiasId, Number, IsActual, page, limit },
    });
  }

  export function syncOccWithgGisZhkh(OccIds: Array<OCCV2Order.Base["Id"]>) {
    return client.post<ResponseAbstract<number>>("/api/occ/send-occ-to-gis-zhkh", { OccIds });
  }
}
