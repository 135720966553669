import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IBaseModalHeaderProps {
  title: string;
  subtitle?: string | JSX.Element;
  icon?: JSX.Element;
  iconBgColor?: string;
  onClose: (event?: React.SyntheticEvent) => void;
  variant?: "common" | "bordered" | "info-iconed";
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

const BaseModalHeader: React.FC<IBaseModalHeaderProps> = ({
  title,
  subtitle,
  icon,
  iconBgColor,
  onClose,
  variant = "common",
  children,
  className,
}) => {
  const handleClose = (event: React.SyntheticEvent) => {
    onClose(event);
  };

  return (
    <div className={cx(className, { header: true, [`variant--${variant}`]: true })}>
      <div className={styles.header_box}>
        <div className="flex">
          {icon && (
            <span
              className={cx({ icon_handler: true, backgrounded: iconBgColor })}
              style={{ backgroundColor: iconBgColor ? iconBgColor : "transparent" }}
            >
              {icon}
            </span>
          )}
          <div>
            <h2 className="headline-h3 sf-bold color--text-primary">{title}</h2>
            {subtitle && (
              <div className={styles.subtitle}>
                <p className="sf-text-regular color--text-secondary text--primary">{subtitle}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.header_actions}>
        {children}
        <svg
          className={styles.close_btn}
          onClick={handleClose}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          role="button"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4142 12L16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L12 10.5858L8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L10.5858 12L7.2929 15.2929C6.90238 15.6834 6.90238 16.3166 7.2929 16.7071C7.68343 17.0976 8.31659 17.0976 8.70712 16.7071L12 13.4142L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L13.4142 12Z"
            fill="#3A3B3D"
          />
        </svg>
      </div>
    </div>
  );
};

export default BaseModalHeader;
