import { KeyboardEvent } from "react";
import { SxProps } from "@mui/material";
import classNames from "classnames/bind";
import StyledTextArea from "./styled-textarea";
import ValueLengthCounter from "@/components/base-input/counter";
import BaseLabel from "@/components/BaseLabel";
import styles from "@/components/base-input/styles.module.scss";

const cx = classNames.bind(styles);

interface IBaseTextareaProps {
  value: string;
  onChange?: (value: string) => void;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  tooltipMessage?: string;
  placeholder?: string;
  errorMessage?: string;
  minRows?: number;
  maxRows?: number;
  maxlength?: number | null | undefined;
  counter?: boolean;
  sx?: SxProps;
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  className?: string;
}

const BaseTextarea = ({
  value,
  onChange,
  label,
  disabled,
  required,
  tooltipMessage,
  placeholder,
  errorMessage,
  minRows,
  maxRows,
  maxlength,
  counter = false,
  sx,
  onKeyDown,
  className,
}: IBaseTextareaProps) => {
  function handleChange(value: string) {
    if (onChange) {
      onChange(value);
    }
  }

  function handleOnKeyDown(e: KeyboardEvent<HTMLTextAreaElement>) {
    if (onKeyDown) {
      onKeyDown(e);
    }
  }

  return (
    <div data-error={errorMessage} className={cx(className, { container: true, has_error: !!errorMessage })}>
      {(label || (counter && maxlength)) && (
        <div className="flex between">
          <BaseLabel required={required} tooltipMessage={tooltipMessage}>
            {label}
          </BaseLabel>

          {counter && maxlength && <ValueLengthCounter value={value} maxlength={maxlength} />}
        </div>
      )}

      <StyledTextArea
        sx={{ ...sx, border: !!errorMessage ? "1px solid #FA4547" : "1px solid rgba(43, 57, 73, 0.16)" }}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        minRows={minRows}
        maxRows={maxRows}
        maxLength={maxlength ?? undefined}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={handleOnKeyDown}
      />
    </div>
  );
};

export default BaseTextarea;
