import { KeyboardEvent, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { setMessageValue } from "@/app/store/slices/chats";
import { ArrowUp24Icon } from "@/consts/icons-v2";
import BaseButton from "@/components/base-button";
import BaseTextarea from "@/components/base-textarea";
import styles from "./styles.module.scss";

interface IChatInputProps {
  onSend: (message: string) => Promise<any>;
}

export const ChatInput: React.FC<IChatInputProps> = ({ onSend }) => {
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState<boolean>(false);
  const { messageValue } = useSelector((state: RootState) => state.chats);

  function handleChange(value: string) {
    dispatch(setMessageValue(value));
  }

  async function handleSend() {
    if (!messageValue) {
      return;
    }

    setLoading(true);

    try {
      await onSend(messageValue);
      handleChange("");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function handleKeyDown(e: KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  }

  return (
    <div className={styles["b-chat-input"]}>
      <BaseTextarea
        className={styles["b-chat-input__field"]}
        minRows={1}
        value={messageValue ?? ""}
        onChange={handleChange}
        placeholder="Введите сообщение"
        sx={{ overflow: "auto!important" }}
        onKeyDown={handleKeyDown}
      />

      <BaseButton
        type="button"
        className={styles["b-chat-input__submit"]}
        disabled={!messageValue || messageValue?.length === 0}
        onClick={handleSend}
        variant="primary"
        isLoading={isLoading}
      >
        {ArrowUp24Icon("var(--controls-primary-foreground)")}
      </BaseButton>
    </div>
  );
};

export default ChatInput;
